import request from "src/services/api/request";

export const API = {
    getSEOMeta({ target }) {
        return request.get("seo/meta/" + target);
    },
    createSEOMeta(payload) {
        return request.post("seo/meta", payload);
    },
    updateSEOMeta(payload) {
        return request.put("seo/meta/" + payload["id"], payload);
    },
    deleteSEOMeta(payload) {
        return request.delete("seo/meta/" + payload["id"]);
    }
};