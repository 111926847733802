<script>
import { onMounted, reactive, ref } from "@vue/composition-api";
import MobilePayments from "./mobile/View.vue";
import PaymentTable from "./components/Table.vue";
import BalanceSummary from "./components/BalanceSummary.vue";

export default {
  components: {
    MobilePayments,
    PaymentTable,
    BalanceSummary
  },
  setup() {
    const controls = reactive({});

    onMounted(() => {

    });
    return {
      controls,
    };
  },
};
</script>
<template>
  <div v-if="$isDesktopView()" class="flex flex-col items-center justify-center w-full px-10 pb-20">
    <div class="flex justify-between items-center w-full mt-6 pb-5">
      <h4 class="text-xl font-bold">
        Payments
      </h4>
    </div>
    <balance-summary class="mb-1 w-full" />
    <a-divider>Payments History</a-divider>
    <div class="bg-white border shadow-sm rounded-lg overflow-hidden w-full">
      <payment-table />
    </div>
  </div>
  <mobile-payments v-else />
</template>
