<script>
import { nextTick, onMounted, ref, watch } from "@vue/composition-api";
import startCase from 'lodash/startCase';
export default {
  props: {
    controls: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const typingIndicator = ref(null);
    var idleTime = 400,
      idleTimer = null,
      inputValue,
      indicatorState = {
        active: "is-typing-active",
        init: "is-typing-init",
      };

    watch(
      () => props.controls,
      (val) => {
        updateView(val);
      },
      {
        deep: true,
      }
    );

    const showIndicator = () => {
      typingIndicator.value.classList.add(indicatorState.init);
      nextTick(() => {
        scrollIntoView();
      });
    };

    const activateIndicator = (inputVal) => {
      typingIndicator.value.classList.add(indicatorState.active);
      inputValue = inputVal;
      detectIdle(inputVal);
    };

    const removeIndicator = () => {
      typingIndicator.value.classList.remove(indicatorState.init, indicatorState.active);
    };

    const detectIdle = (inputVal) => {
      if (idleTimer) {
        clearInterval(idleTimer);
      }

      idleTimer = setTimeout(function () {
        if (getInputCurrentValue(inputVal) === inputValue) {
          typingIndicator.value.classList.remove(indicatorState.active);
        }
      }, idleTime);
    };

    const getInputCurrentValue = (inputVal) => {
      var currentValue = inputVal;
      return currentValue;
    };

    const updateView = (controls) => {
      if (controls.show) {
        showIndicator();
      } else {
        removeIndicator();
      }
      if (controls.activate) {
        activateIndicator(controls.value);
      }
    };

    const scrollIntoView = () => {
      typingIndicator.value.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    onMounted(() => {
      updateView(props.controls);
    });

    return {
      typingIndicator,
      startCase
    };
  },
};
</script>
<template>
  <div
    class="flex justify-center items-center"
    :class="{ 'h-12': controls.show }"
  >
    <span
      v-if="controls.show"
      class="text-base font-light text-gray-400"
    >{{ startCase(controls.name) }} is typing</span>
    <div
      ref="typingIndicator"
      class="typing"
    >
      <span class="typing__bullet" />
      <span class="typing__bullet" />
      <span class="typing__bullet" />
    </div>
  </div>
</template>
<style lang="css" scoped>
.typing {
  display: none;
  padding: 10px;
  font-size: 0;
  -webkit-animation: fadeInUp 200ms linear 1 both;
  animation: fadeInUp 200ms linear 1 both;
  /* States */
}
.typing__bullet {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 300ms linear;
}
.typing__bullet:not(:last-child) {
  margin-right: 3px;
}
.typing.is-typing-init {
  display: inline-block;
}
.typing.is-typing-active .typing__bullet {
  background-color: #000;
  -webkit-animation: bounce 600ms linear infinite both;
  animation: bounce 600ms linear infinite both;
}
.typing.is-typing-active .typing__bullet:nth-child(2) {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.typing.is-typing-active .typing__bullet:nth-child(3) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}
/**
 * Animations
 */
@-webkit-keyframes bounce {
  0%,
  80%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-100%);
  }
}
@keyframes bounce {
  0%,
  80%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-100%);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>