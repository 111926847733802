var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center shadow-sm bg-white border rounded-lg relative",style:({ paddingLeft: (_vm.leftPadding + "px"), paddingRight: _vm.rightPadding() })},[_c('smooth-scroll',{ref:"tabHeaderScroll",attrs:{"ops":{
    vuescroll: {},
    scrollPanel: {},
    rail: {},
    bar: {
      size: '6px',
      background: 'rgba(88, 80, 236, 1)',
      onlyShowBarOnScroll: false,
      opacity: 0.6
    }
  }},on:{"handle-scroll":_vm.handleScroll}},[_c('div',{staticClass:"flex items-center space-x-10 font-medium text-lg py-4 px-12"},[_vm._l((_vm.tabs),function(tab){return [(_vm.activeTab === tab.name && tab.action)?_c('button',{key:tab.name,staticClass:"flex items-center space-x-3 pl-4 pr-2 focus:outline-none whitespace-nowrap bg-parqay-primary text-white rounded-full cursor-pointer",attrs:{"id":tab.name},on:{"click":function($event){return _vm.changeTab(tab.name)}}},[_c('span',[_vm._v(_vm._s(tab.label))]),_c('a-icon',{staticStyle:{"font-size":"16px"},attrs:{"type":"reload","spin":tab.$loading},on:{"click":function($event){$event.stopPropagation();return _vm.onTabActionTrigger(tab)}}})],1):_c('button',{key:tab.name,staticClass:"px-4 focus:outline-none whitespace-nowrap",class:[
          _vm.activeTab === tab.name
            ? 'bg-parqay-primary text-white rounded-full cursor-pointer'
            : 'cursor-pointer hover:bg-gray-200 hover:rounded-full'
        ],attrs:{"id":tab.name},on:{"click":function($event){return _vm.changeTab(tab.name)}}},[_vm._v("\n          "+_vm._s(tab.label)+"\n        ")])]})],2)]),_c('div',{staticClass:"absolute right-4 top-2 bottom-2"},[_c('div',{ref:"rightActionsSlotRef",staticClass:"flex h-full items-center justify-center"},[_vm._t("right-actions")],2)]),(_vm.showToggles)?_c('div',{staticClass:"absolute top-1/2 transform -translate-y-1/2",staticStyle:{"left":"15px"}},[_c('a-button',{staticStyle:{"padding":"0px"},attrs:{"type":"link","disabled":_vm.scrollProcess == 0},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.tabHeaderScroll.scrollBy(
        {
          dx: -80
        },
        200,
        'easeInQuad'
      )}}},[_c('a-icon',{staticClass:"hover:text-parqay-primary",staticStyle:{"font-size":"25px"},attrs:{"type":"left-circle"}})],1)],1):_vm._e(),(_vm.showToggles)?_c('div',{staticClass:"absolute top-1/2 transform -translate-y-1/2",staticStyle:{"right":"15px"}},[_c('a-button',{staticStyle:{"padding":"0px"},attrs:{"type":"link","disabled":_vm.scrollProcess == 1},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.tabHeaderScroll.scrollBy(
        {
          dx: 80
        },
        200,
        'easeInQuad'
      )}}},[_c('a-icon',{staticClass:"hover:text-parqay-primary",staticStyle:{"font-size":"25px"},attrs:{"type":"right-circle"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }