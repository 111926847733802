<script>
import SlideBar from "src/views/components/components/SlideBar";
import { reactive, ref, watch, toRefs } from "@vue/composition-api";
import startCase from "lodash/startCase";

import { API } from "src/views/components/api";

export default {
  components: {
    SlideBar
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    role: {
      type: String,
      default: "host"
    }
  },
  setup(props, ctx) {
    const getUserUID = ctx.root.$getUserUID;

    const loading = ref(false);

    const show = ref(false);

    const pagination = reactive({
      currentPage: 1,
      perPage: 10,
      total: 1
    })

    watch(
      () => props.visible,
      async val => {
        show.value = val;
        if (val) {
          loading.value = true;
          readAllNotifications();
          setTimeout(() => {
            getAllNotifications();
            loading.value = false;
          }, 1000);
        }
      }
    );

    watch(
      () => ctx.root.$store.state.notificationsPagination,
      ({ page, per_page, total }) => {
        pagination.currentPage = page;
        pagination.perPage = per_page;
        pagination.total = total;
      }, {
      deep: true
    }
    );

    const close = () => {
      ctx.emit("close-notification-slide-card");
    };

    const navToItem = it => {
      if (it.type == "reservation") {
        if (props.role === "host") {
          ctx.root.$router.push(`/dashboard/reservations/${it.reservation_id}`);
        }
        if (props.role === "tenant") {
          ctx.root.$router.push(`/tenant/reservations/${it.reservation_id}`);
        }
        close();
        return;
      }
      if (it.type == "doc") {
        if (props.role === "host") {
          ctx.root.$router.push({
            name: "dashboard-documents",
            query: {
              focus: it.doc_id
            }
          });
        }
        if (props.role === "tenant") {
          ctx.root.$router.push({
            name: "tenant-documents",
            query: {
              focus: it.doc_id
            }
          });
        }
        close();
        return;
      }
    };

    const navToSubItem = (type, it) => {
      console.log("props.role, type -> ", props.role, type);
      switch (`${props.role}, ${type}`) {
        // Host
        case "host, reservation":
          ctx.root.$router.push({
            name: "ReservationsDetails",
            params: {
              id: it.reservation_id
            }
          });
          close();
          return;
        case "host, doc":
          if (it.type === "reservation") {
            // close();
            ctx.root.$openDocSlideCard('host', null, it.reservation_id);
            return;
          }
          if (it.type === "doc") {
            ctx.root.$router.push({
              name: "dashboard-documents",
              query: {
                focus: it.doc_id
              }
            });
            close();
            return;
          }
          return;
        case "host, tenant":
          ctx.root.$router.push({
            name: 'Tenants',
            query: {
              focus: ctx.root.$isAdmin() ? `${it.tenant_id},${it.user}` : it.tenant_id
            }
          });
          close();
          return;
        case "host, host": // Admin
          if (!ctx.root.$isAdmin()) {
            return;
          }
          ctx.root.$router.push({
            name: 'Tenants',
            query: {
              host: it.user
            }
          });
          close();
          return;
        case "host, listing":
          ctx.root.$router.push({
            name: "ListingsDetail",
            params: {
              id: it.listing_id
            }
          });
          close();
          return;
        // Tenant
        case "tenant, reservation":
          ctx.root.$router.push({
            name: "TenantReservationsDetails",
            params: {
              id: it.reservation_id
            }
          });
          close();
          return;
        case "tenant, doc":
          ctx.root.$router.push({
            name: "tenant-documents",
            query: {
              focus: it.doc_id
            }
          });
          close();
          return;
        case "tenant, listing":
          ctx.root.$openUrlInNewTab(process.env.VUE_APP_MARKETPLACE_FRONTEND_BASE_URL + "/listings/" + it.listing_id);
          close();
          return;
        default:
          break;
      }
    }

    const getAllNotifications = () => {
      ctx.root.$socket.emit("get-all-notifications", {
        user_uid: getUserUID(),
        filter: {
          role: props.role,
          page: pagination.currentPage
        }
      });
    };

    const readAllNotifications = async () => {
      try {
        await API.readAllNotifications(props.role);
      } catch (error) {
        console.log("readAllNotifications error -> ", error);
      }
    }

    const refresh = async (resetPagination = true) => {
      loading.value = true;
      if (resetPagination) {
        pagination.currentPage = 1;
      }
      ctx.root.$store.commit("clearNotifications");
      getAllNotifications();
      setTimeout(() => {
        loading.value = false;
      }, 1500);
    }

    const handlePageChange = (page) => {
      pagination.currentPage = page;
      refresh(false);
    }

    return {
      loading,
      show,
      ...toRefs(pagination),
      close,
      navToItem,
      navToSubItem,
      refresh,
      handlePageChange,
      startCase
    };
  }
};
</script>
<template>
  <slide-bar v-slot:default="slotProps" v-model="show" :width="400" backdrop close-by-mask @close-slide-bar="close">
    <div class="h-full flex flex-col">
      <div class="flex justify-between items-center border-b px-6 py-6">
        <div class="flex items-center space-x-3">
          <span class="font-bold text-lg text-gray-900 my-auto">
            Notifications
          </span>
          <a-icon v-if="loading" type="loading" class="text-parqay-primary" style="font-size: 20px;" />
          <a-icon v-else type="reload" class="text-parqay-primary" style="font-size: 18px;" @click.stop="refresh()" />
          <div v-if="$isAdmin()"
            class="flex items-center px-3 py-1 rounded-full space-x-2 bg-parqay-primary text-white text-xs font-medium">
            <span>Admin</span>
            <a-tooltip
              title="Note these are notifications over the platform, admin might not be able to mark unread notifications as read.">
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
        </div>
        <span @click="slotProps.closeSlideBar">
          <svg-icon icon-class="close" class="cursor-pointer w-6 h-6 text-blue-900" />
        </span>
      </div>
      <div class="flex flex-col px-6 py-4"
        :class="{ 'bg-gray-100': $store.state.notifications.filter(v => v.role === role).length > 0 }">
        <div v-if="$store.state.notifications.filter(v => v.role === role).length == 0"
          class="flex items-center justify-center" style="height: 70vh;">
          <span v-if="loading" class="text-base text-gray-400">
            Loading notifications...
          </span>
          <span v-else class="text-base text-gray-400">
            No {{ role }} notification yet
          </span>
        </div>
        <template v-else>
          <div v-for="(notification, i) in $store.state.notifications.filter(v => v.role === role)"
            :key="`notification-${i}`" class="flex flex-col">
            <div v-if="$isAdmin()" class="flex items-center justify-between pb-4">
              <div class="flex items-center space-x-2">
                <span class="text-sm font-medium">Host</span>
                <a-tag color="geekblue" class="cursor-pointer" @click.stop="navToSubItem('host', notification)">{{
                  notification.host_name || "N/A" }}</a-tag>
              </div>
              <div class="flex items-center space-x-2">
                <span class="text-sm font-medium">Tenant</span>
                <a-tag color="orange" class="cursor-pointer" @click.stop="navToSubItem('tenant', notification)">{{
                  notification.tenant_name || "N/A" }}</a-tag>
              </div>
            </div>
            <div class="relative flex">
              <div class="flex flex-col bg-white hover:bg-gray-50 rounded-lg shadow-md py-2 cursor-pointer"
                @click.stop="navToItem(notification)">
                <div class="flex">
                  <div class="w-4/5 border-white px-4">
                    <div class="flex items-center space-x-3">
                      <avatar v-if="role === 'host'" :user-id="notification.tenant_id" :admin-on-behalf-of="notification.user" popover-placement="left" />
                      <avatar v-if="role === 'tenant'" :user-id="notification.host_id" popover-placement="left" />
                      <span class="font-medium">{{ notification.message }}</span>
                    </div>
                  </div>
                  <div class="w-1/5 flex items-center justify-center">
                    <svg v-if="notification.type === 'reservation'" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-parqay-primary">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                    <svg v-if="notification.type === 'doc' && notification.doc_type === 'sign'"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5 text-parqay-primary">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>
                    <svg v-if="notification.type === 'doc' && notification.doc_type === 'upload'"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5 text-parqay-primary">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-3 gap-2 w-full pt-3 px-3">
                  <template v-if="notification.type === 'reservation'">
                    <a-button v-if="role === 'tenant'" size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('reservation', notification)">
                      <span>Reservation</span>
                      <a-icon type="calendar" />
                    </a-button>
                    <a-button size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('doc', notification)">
                      <span class="mr-1">Documents</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                      </svg>
                    </a-button>
                    <a-button v-if="role === 'host'" size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('listing', notification)">
                      <span>Listing</span>
                      <a-icon type="unordered-list" />
                    </a-button>
                    <a-button v-if="role === 'tenant'" size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('listing', notification)">
                      <span>Listing</span>
                      <a-icon type="link" />
                    </a-button>
                    <a-button v-if="role === 'host'" size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('tenant', notification)">
                      <span class="mr-1">Tenant</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </a-button>
                  </template>
                  <template v-if="notification.type === 'doc'">
                    <a-button size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('doc', notification)">
                      <span class="mr-2">{{ startCase(notification.doc_type) }}</span>
                      <svg v-if="notification.doc_type === 'sign'" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                      </svg>
                      <svg v-if="notification.doc_type === 'upload'" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>
                    </a-button>
                    <a-button size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('reservation', notification)">
                      <span>Reservation</span>
                      <a-icon type="calendar" />
                    </a-button>
                    <a-button v-if="role !== 'tenant'" size="small"
                      style="font-size: 0.75rem; font-weight: 500; display: flex; align-items: center; justify-content: center;"
                      @click.stop="navToSubItem('tenant', notification)">
                      <span class="mr-1">Tenant</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </a-button>
                  </template>
                </div>
              </div>

              <div class="absolute" style="left:-1rem; top: 0.25rem;">
                <a-badge :count="notification.is_read ? 0 : 'New'">
                  <div class="w-6 h-6" />
                </a-badge>
              </div>

            </div>
            <a-divider>
              <span class="text-xs text-gray-500">
                {{
                  $formatServerUTCDateToLocal(notification.created_at.$date)
                }}
              </span>
            </a-divider>
          </div>
        </template>
        <div class="flex items-center justify-center pt-2 pb-4">
          <a-pagination :current="currentPage" :total="total" :page-size="perPage" size="small"
            @change="handlePageChange" />
        </div>
      </div>
    </div>
  </slide-bar>
</template>
