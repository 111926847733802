<script>
import DocumentTemplates from "src/views/documents/components/Templates.vue";

export default {
    components: {
        DocumentTemplates
    },
    setup(props, ctx) {
        return {

        }
    }
}

</script>
<template>
    <div class="absolute inset-0">
        <div class="relative">
            <div class="flex flex-col px-10 py-2 space-y-4">
                <document-templates />
            </div>
        </div>
    </div>
</template>