<script>
import { ref, onMounted, onUnmounted } from '@vue/composition-api';
import jsQR from 'jsqr';
import { notification } from 'ant-design-vue';

export default {
    setup(props, { emit }) {
        const video = ref(null);
        const canvasRef = ref(null);
        const scanning = ref(false);
        const scanProgress = ref(false);

        const isModalVisible = ref(false); // State to control modal visibility
        const qrCodeContent = ref(''); // State to store the QR code content

        const startScan = async () => {
            scanning.value = true;

            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                video.value.srcObject = stream;
                video.value.setAttribute('playsinline', true); // Prevents fullscreen on iOS Safari
                video.value.play().catch(e => {
                    console.error('Error playing the video stream', e);
                    notification.error({
                        message: 'Playback Error',
                        description: 'There was an error playing the video stream.',
                        duration: null
                    });
                });
                scanProgress.value = true;
                requestAnimationFrame(scanQRCode);
            } catch (error) {
                console.error('Error accessing the camera', error);
                scanning.value = false;
                scanProgress.value = false;
                // Display an error notification to the user
                notification.error({
                    message: 'Camera Error',
                    description: `Unable to access the camera. Please ensure camera permissions are granted and try again. ${error}`,
                    duration: null // Duration can be specified or set to null for the notification to remain until closed manually
                });
            }
        };

        const scanQRCode = () => {
            const canvas = canvasRef.value.getContext('2d');
            canvas.drawImage(video.value, 0, 0, canvasRef.value.width, canvasRef.value.height);
            const imageData = canvas.getImageData(0, 0, canvasRef.value.width, canvasRef.value.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: 'dontInvert',
            });

            // Modify the if (code) block as follows:
            if (code) {
                qrCodeContent.value = code.data; // Store the QR code content
                emit("detected", code.data);
                // showModal(); // Show the modal with QR code content
                stopScanning(); // Stop the scanning process
            } else {
                requestAnimationFrame(scanQRCode);
            }
        };

        const enableScanner = () => {
            if (video.value) {
                scanProgress.value = true;
            }
        };

        const stopScanning = () => {
            if (video.value && video.value.srcObject) {
                video.value.srcObject.getTracks().forEach((track) => track.stop());
            }
            scanning.value = false;
            scanProgress.value = false;
        };

        // Method to handle file selection
        const handleFileChange = async (event) => {
            const files = event.target.files;
            if (files.length === 0) {
                return;
            }
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        qrCodeContent.value = code.data;
                        emit("detected", code.data);
                        showModal();
                    } else {
                        // Handle case where no QR code is found
                        notification.error({
                            message: 'No QR Code Found',
                            description: 'Could not find a QR code in the selected image.',
                            duration: null
                        });
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        };


        // Method to show the modal
        const showModal = () => {
            isModalVisible.value = true;
        };

        // Method to handle the OK button in the modal
        const handleOk = () => {
            isModalVisible.value = false;
        };

        // Method to handle the Cancel button in the modal
        const handleCancel = () => {
            isModalVisible.value = false;
        };

        onUnmounted(() => {
            if (video.value && video.value.srcObject) {
                video.value.srcObject.getTracks().forEach((track) => track.stop());
            }
        });

        return {
            video,
            canvasRef,
            scanning,
            scanProgress,
            isModalVisible,
            qrCodeContent,
            startScan,
            enableScanner,
            handleFileChange,
            showModal,
            handleOk,
            handleCancel
        };
    },
};
</script>

<template>
    <div class="flex flex-col items-center justify-center p-4 space-y-2 w-full h-full">
        <div v-if="scanning" class="qr-scanner">
            <video ref="video" class="w-full h-auto" @canplay="enableScanner"></video>
            <canvas ref="canvasRef" class="hidden" width="200" height="200"></canvas>
            <!-- <p v-if="scanProgress">Scanning...</p> -->
        </div>
        <template v-else>
            <button class="bg-blue-500 text-white font-bold py-2 px-4 rounded" @click="startScan">
                Scan QR Code
            </button>
            <a-button type="link" @click="() => $refs.fileInput.click()">Select from Library</a-button>
        </template>
        <input type="file" ref="fileInput" class="hidden" @change="handleFileChange" accept="image/*">
        <!-- Ant Design Vue Modal -->
        <a-modal v-model="isModalVisible" title="QR Code Content" @ok="handleOk" @cancel="handleCancel">
            <p>{{ qrCodeContent }}</p>
        </a-modal>
    </div>
</template>