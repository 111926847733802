<script>
import { reactive, watch, onMounted } from "@vue/composition-api";
import VueQr from 'vue-qr';
import QrScanner from "src/views/listings/detail/qr-code/Scanner.vue";
import { notification } from 'ant-design-vue';

import { API } from "src/views/team/api";
import asyncTimeout from "await-timeout";
import startCase from "lodash/startCase";

export default {
  components: { VueQr, QrScanner },
  setup(props, ctx) {
    const controls = reactive({
      loading: {
        reservation: false,
        login: false,
        checkIn: false
      },
      visible: {
        login: true,
        manualLogin: false,
        checkIn: false
      },
      checkin_status: null,
      checkin_reservation: {},
      checkin_result: null,
      login_result: null,
      drawers: {
        reservation: {
          visible: false,
          form: null,
        },
      },
      filter: {
        tab: "upcoming"
      }
    });

    const attendant = reactive({
      id: ctx.root.$route.query.id,
      token: ctx.root.$route.query.token,
      email: null,
      data: {}
    })

    if (attendant.id && attendant.token) {
      controls.visible.login = false;
    }

    const table = reactive({
      headers: [
        {
          title: "Host Name",
          align: "center",
          dataIndex: "host_name",
          scopedSlots: { customRender: "host_name" },
        },
        {
          title: "Booking Intervals",
          align: "center",
          dataIndex: "durations",
          scopedSlots: { customRender: "durations" },
        },
        {
          title: "Type",
          align: "center",
          dataIndex: "listing.type",
          scopedSlots: { customRender: "reservation-type" },
        },
        {
          title: "Status",
          align: "center",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Confirmation#",
          align: "center",
          dataIndex: "confirmation_id",
          scopedSlots: { customRender: "confirmation" },
        },
        {
          title: "Price",
          align: "center",
          dataIndex: "tenant_total",
          scopedSlots: { customRender: "tenant_total" },
        },
        {
          title: "Location",
          align: "center",
          dataIndex: "listing.name",
          scopedSlots: { customRender: "location" },
        }
      ],
      items: [],
    });

    const getReservations = async () => {
      controls.loading.reservation = true;
      try {
        let resp = await API.listReservations(attendant.id, controls.filter);
        console.log("reservation api result:", resp);
        table.items = resp.data;
      } catch (error) {
        console.log("getReservations > error: ", error);
      }
      controls.loading.reservation = false;
    };

    watch(() => controls.filter.tab, val => {
      getReservations();
    })

    const filterReservations = () => {
      return table.items;
    }

    const extractParams = (urlString) => {
      const url = new URL(urlString);
      const searchParams = new URLSearchParams(url.search);
      const id = searchParams.get('id');
      const token = searchParams.get('token');
      attendant.id = id;
      attendant.token = token;
    };

    const parseQRScannerResult = async result => {
      console.log("parseQRScannerResult: result -> ", result);
      controls.loading.login = true;
      controls.login_result = result;
      await asyncTimeout.set(1500);
      extractParams(result);
      if (attendant.id && attendant.token) {
        await login();
        if (attendant.data.id) {
          getReservations();
          return;
        }
      }
      controls.loading.login = false;
      controls.visible.login = true;
    };

    const parseCheckInResult = async result => {
      console.log("parseCheckInResult: result -> ", result);
      controls.loading.checkIn = true;
      controls.checkin_result = result;
      await asyncTimeout.set(1500);
      try {
        const { data } = await API.checkIn(attendant.id, result);
        controls.checkin_status = "success";
        controls.checkin_reservation = data;
        getReservations();
        controls.loading.checkIn = false;
      } catch (error) {
        controls.loading.checkIn = false;
        notification.error({
          message: 'Check-In Error',
          description: error.message,
          duration: null
        });
      }
    };

    const login = async () => {
      try {
        controls.loading.login = true;
        const { data } = await API.login(attendant.id, attendant.token);
        attendant.data = data;
        controls.loading.login = false;
        controls.visible.login = false;
      } catch (error) {
        controls.loading.login = false;
        controls.visible.login = true;
        notification.error({
          message: 'Login Error',
          description: error.message,
          duration: null
        });
      }
    }

    const manualLogin = async () => {
      try {
        controls.loading.login = true;
        const { data } = await API.manualLogin(attendant.email, attendant.token);
        attendant.data = data;
        attendant.id = data.id;
        getReservations();
        controls.loading.login = false;
        controls.visible.login = false;
      } catch (error) {
        controls.loading.login = false;
        controls.visible.login = true;
        notification.error({
          message: 'Login Error',
          description: error.message,
          duration: null
        });
      }
    }

    onMounted(async () => {
      if (attendant.id && attendant.token) {
        await login();
        if (attendant.data.id) {
          getReservations();
        }
      }
    });

    return {
      controls,
      table,
      attendant,
      getReservations,
      filterReservations,
      parseQRScannerResult,
      parseCheckInResult,
      login,
      manualLogin,
      startCase,
      window,
      console
    };
  },
};
</script>
<template>
  <div class="tenant-reservations absolute inset-0 border-t bg-gray-200" style="top: 80px;">
    <!-- Note: h-full is important to activate flex grow! -->
    <div class="flex flex-col h-full">
      <div class="flex-shrink-0 flex flex-col bg-white border-b">
        <div class="relative flex justify-center items-center px-10 py-4 border-b">
          <div class="flex items-center space-x-2 text-black font-medium text-base">
            <a-icon type="calendar" theme="twoTone" />
            <span>Reservations Check-In</span>
          </div>
          <div class="absolute inset-y-0 flex items-center justify-center" :style="{ right: '16px' }">
            <a-button type="link" :icon="controls.loading.reservation ? 'loading' : 'reload'"
              @click="getReservations()"></a-button>
          </div>
        </div>
        <div v-if="false" class="relative flex justify-center items-center px-10 py-4 border-b">
          <div class="flex items-center space-x-2 font-medium text-base">
            <a-icon type="tag" theme="twoTone" />
            <span>Listing X</span>
          </div>
          <div class="absolute inset-y-0 flex items-center justify-center" :style="{ left: '16px' }">
            <a-button type="link" icon="left"></a-button>
          </div>
          <div class="absolute inset-y-0 flex items-center justify-center" :style="{ right: '16px' }">
            <a-button type="link" icon="right"></a-button>
          </div>
        </div>
        <div class="flex justify-center py-2 text-base text-gray-600 font-normal space-x-6">
          <span class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.filter.tab === 'upcoming' }"
            @click="() => (controls.filter.tab = 'upcoming')">
            Upcoming
          </span>
          <span class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.filter.tab === 'checked-in' }"
            @click="() => (controls.filter.tab = 'checked-in')">
            Checked-in
          </span>
          <span class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.filter.tab === 'all' }"
            @click="() => (controls.filter.tab = 'all')">
            All
          </span>
        </div>
      </div>
      <div class="flex-grow overflow-y-auto w-screen mx-auto">
        <div v-if="controls.loading.reservation" class="flex items-center justify-center"
          :style="{ height: `${window.innerHeight / 2}px` }">
          <a-button size="large" loading></a-button>
        </div>
        <template v-else>
          <div v-if="table.items.length == 0" class="flex flex-col items-center justify-center"
            :style="{ height: `${window.innerHeight / 2}px` }">
            <span class="text-lg text-gray-500">No reservation</span>
          </div>
          <div v-else class="px-6 pt-5 space-y-5">
            <template v-for="(reservation, i) in filterReservations()">
              <div :key="`reservation-item-${i}`" class="shadow-md overflow-hidden rounded-xl bg-white">
                <div class="flex items-start justify-between py-2 space-x-2 border-b">
                  <span class="text-base text-black font-medium pl-6 py-2">{{ reservation.title }}</span>
                  <span v-if="reservation.interval_mode === 'monthly'"
                    class="text-base text-parqay-primary font-medium pr-6 py-2">{{
                      $formatCurrency(reservation.tenant_total
                        / reservation.interval_count)
                    }}/month</span>
                  <span v-else class="text-base text-parqay-primary font-medium pr-6 py-2">{{
                    $formatCurrency(reservation.tenant_total)
                  }}</span>
                </div>
                <div class="flex flex-col items-center justify-center space-y-2">
                  <div class="flex items-center space-x-3 pt-4 pb-2">
                    <a-icon type="idcard" />
                    <span>Tenant Name</span>
                    <span class="text-black font-medium">{{ reservation.tenant_name }}</span>
                  </div>
                  <div v-if="!$deepGet(reservation, 'is_checked_in', false)" class="flex items-center pb-2">
                    <a-tag color="geekblue">
                      {{ startCase($deepGet(reservation, "listing.type", "")) }}
                    </a-tag>
                    <a-tag v-if="reservation.status === 'complete'" color="green">
                      Approved by host
                    </a-tag>
                    <a-tag v-if="reservation.status === 'pending'" color="orange">
                      Pending
                    </a-tag>
                    <a-tag v-if="reservation.status === 'canceled'" color="pink">
                      Canceled
                    </a-tag>
                    <a-tag v-if="reservation.status === 'request'" color="cyan">
                      Request sent
                    </a-tag>
                    <a-tag color="geekblue">
                      {{ startCase(reservation.source || "") }}
                    </a-tag>
                  </div>
                  <div v-else class="flex items-center justify-center px-4 py-2">
                    <div
                      class="flex items-center w-full space-x-4 border-2 border-parqay-primary text-xl font-bold text-parqay-primary px-4 py-2">
                      <span>Checked-In</span>
                      <a-icon type="check-circle" style="font-size: 35px;" />
                    </div>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex flex-col space-y-2 w-full px-5 pt-2 pb-3">
                    <div v-for="(duration, j) in reservation.durations" :key="`reservation-${i}-duration-${j}`"
                      class="flex space-x-2 items-center justify-between border rounded px-4 py-1">
                      <div class="text-center text-xs font-medium whitespace-nowrap">
                        {{ $parseBackendDate(duration.from, "MMM DD, h:mm a") }}
                      </div>
                      <svg-icon icon-class="arrow-circle-right" class="text-parqay-primary w-6 h-6" />
                      <div class="text-center text-xs font-medium whitespace-nowrap">
                        {{ $parseBackendDate(duration.to, "MMM DD, h:mm a") }}
                      </div>
                    </div>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex flex-col items-center space-y-2 w-full pt-1 pb-1">
                    <div class="flex justify-between w-full px-5">
                      <div class="flex space-x-4 items-center w-full">
                        <span>Listing</span>
                        <span class="px-4 py-2 text-sm rounded border"
                          style="max-width: 80%; color: #2f54eb; border-color: #adc6ff; background: #f0f5ff">
                          {{
                            $deepGet(reservation, "listing.name", "N/A")
                          }}
                        </span>
                      </div>
                    </div>
                    <template v-if="$deepGet(reservation, 'listing.$address')">
                      <a-divider>Address</a-divider>
                      <div class="rounded bg-white px-4 pb-1">
                        <p class="text-sm text-gray-700 font-medium">{{ $deepGet(reservation, "listing.$address") }}</p>
                      </div>
                    </template>
                  </div>
                  <a-divider v-if="reservation.event"></a-divider>
                  <div v-if="reservation.event" class="flex flex-col items-center space-y-2 w-full pt-1 pb-1">
                    <div class="flex justify-between w-full px-5">
                      <div class="flex space-x-4 items-center w-full">
                        <span>Event</span>
                        <span class="text-xs ant-tag-geekblue border rounded-md px-3 py-1">{{
                          reservation.event
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <template v-if="!$deepGet(reservation, 'is_checked_in', false)">
                    <a-divider></a-divider>
                    <div class="flex space-x-3 w-full px-5 pt-1 pb-4">
                      <a-button type="primary" size="large" block @click="() => controls.visible.checkIn = true">Check
                        In</a-button>
                    </div>
                  </template>
                  <div v-else class="h-2" />
                </div>
                <a-table v-if="false" :scroll="{ x: true, y: false }" :loading="controls.loading.reservation"
                  :columns="table.headers" :data-source="table.items" row-key="id" :custom-row="(record, index) => {
                    return {
                      on: {
                        click: (event) => {
                          console.log('row click', event.target.tagName.toLowerCase());
                          if (
                            event.target.tagName.toLowerCase() !== 'svg' &&
                            event.target.tagName.toLowerCase() !== 'use' &&
                            event.target.tagName.toLowerCase() !== 'a'
                          ) {
                            controls.drawers.reservation.visible = true;
                            controls.drawers.reservation.form = record;
                          }
                        },
                      },
                    };
                  }
                    " :pagination="false">
                  <template slot="host_name" slot-scope="host_name, record">
                    <div class="flex items-center justify-center py-2 space-x-4">
                      <img class="h-10 w-10 rounded-full object-cover ring-2 ring-indigo-500" :src="record.avator" />
                      <span class="font-medium text-xs text-gray-900">
                        {{ host_name }}
                      </span>
                    </div>
                  </template>

                  <template slot="durations" slot-scope="durations">
                    <div class="flex flex-col space-y-2">
                      <div v-for="(duration, i) in durations" :key="`reservation-duration-${i}`"
                        class="flex space-x-2 items-center">
                        <div
                          class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                          {{ $parseBackendDate(duration.from, "MMM DD, h:mm a") }}
                        </div>
                        <svg-icon icon-class="arrow-circle-right" class="text-parqay-primary w-6 h-6" />
                        <div
                          class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                          {{ $parseBackendDate(duration.to, "MMM DD, h:mm a") }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <template slot="reservation-type" slot-scope="type">
                    <a-tag color="geekblue">
                      {{ type }}
                    </a-tag>
                  </template>

                  <template slot="status" slot-scope="status">
                    <a-tag v-if="status === 'complete'" color="green">
                      Complete
                    </a-tag>
                    <a-tag v-if="status === 'pending'" color="orange">
                      {{ status }}
                    </a-tag>
                    <a-tag v-if="status === 'canceled'" color="pink">
                      {{ status }}
                    </a-tag>
                    <a-tag v-if="status === 'request'" color="cyan">
                      Request Sent
                    </a-tag>
                  </template>

                  <template slot="confirmation" slot-scope="confirmation_id, record">
                    <a-tooltip placement="top">
                      <template v-if="!record.showFullConfirmationNumber" slot="title">
                        <span>Click to copy</span>
                      </template>
                      <span
                        class="text-parqay-primary text-xs px-2 py-1 border border-indigo-600 rounded-full cursor-pointer hover:shadow-lg"
                        @click="() => {
                          if (!('showFullConfirmationNumber' in record)) {
                            $set(record, 'showFullConfirmationNumber', false);
                          }
                          if (!record.showFullConfirmationNumber) {
                            $copyToClipboard(confirmation_id);
                            $openNotification(
                              'Confirmation number copied to clipboard',
                              '',
                              5,
                              'info'
                            );
                          }
                          record.showFullConfirmationNumber = !record.showFullConfirmationNumber;
                        }
                          ">{{
    record.showFullConfirmationNumber
    ? confirmation_id
    : confirmation_id.substring(0, 6)
  }}</span>
                    </a-tooltip>
                  </template>

                  <template slot="tenant_total" slot-scope="tenant_total">
                    <span class="text-gray-900">{{ tenant_total }}</span>
                  </template>

                  <template slot="location" slot-scope="location, record">
                    <a class="text-parqay-primary font-medium text-xs" @click="() => { }">
                      {{ location }}
                    </a>
                  </template>

                  <!-- <template slot="contact" slot-scope="contact_id, record">
                <div @click="$openContactSlideCard(record)">
                  <svg-icon icon-class="phone"
                    class="mr-1 h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
                  <svg-icon icon-class="chat" class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
                </div>
              </template>

              <template slot="doc" slot-scope="docs">
                <div class="pointer-events-auto" @click="$openDocSlideCard('tenant')">
                  <svg-icon icon-class="document" class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
                </div>
              </template> -->
                </a-table>
              </div>
              <a-divider :key="`reservation-divider-${i}`">Reservation made on: {{
                $parseBackendDate(reservation.created_at, "MMM DD, h:mm a") }}
              </a-divider>
            </template>
          </div>
        </template>
      </div>
    </div>
    <a-modal v-model="controls.visible.checkIn" width="90vw" :body-style="{ padding: 0 }"
      wrap-class-name="attendant-modal" :closable="false" :footer="null" @cancel="() => {
        controls.checkin_result = null;
        controls.checkin_status = null;
        controls.checkin_reservation = {};
        controls.visible.checkIn = false;
      }">
      <div class="flex flex-col rounded-lg overflow-hidden">
        <div class="flex flex-col items-center pt-4 bg-parqay-primary">
          <div class="rounded-full bg-white h-4 w-20" />
          <span class="text-base font-semibold text-white text-center py-4">Check In Tenant</span>
        </div>
        <div class="bg-white flex flex-col space-y-8 items-center justify-center px-6 pt-8 pb-4">
          <div class="rounded-md bg-gray-100 overflow-hidden" style="width: 200px; height: 200px;">
            <vue-qr v-if="controls.checkin_result" color-dark="#1c55e4" background-color="#c5d4fa"
              logo-src="/static/img/dashboard/parqay-logo.png" :text="controls.checkin_result" :dot-scale="0.8"
              :logo-scale="0.2" :size="300" class="cursor-pointer hover:ring-8 ring-parqay-primary" />
            <qr-scanner v-else @detected="parseCheckInResult" />
          </div>
          <a-icon v-if="controls.loading.checkIn" type="loading" style="font-size: 35px; color: blue;" />
          <div v-if="controls.checkin_status === 'success'"
            class="flex flex-col items-center bg-green-100 text-green-400 rounded-lg mx-8 px-4 py-6 space-y-4 w-full">
            <a-icon type="check-circle" style="font-size: 60px;" />
            <span class="text-black text-base font-semibold">
              Check-In {{ controls.checkin_reservation.tenant_name }} Successfully.
            </span>
          </div>
          <div v-else class="flex flex-col items-center bg-blue-100 rounded-lg mx-8 px-4 py-6 space-y-1 w-full">
            <span class="text-black text-sm font-semibold underline">
              HOW TO USE
            </span>
            <span class="text-sm text-center text-black">
              Active the scanner above to scan the QR code on tenant's reservation.
            </span>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="controls.visible.login" width="90vw" :body-style="{ padding: 0 }" wrap-class-name="attendant-modal"
      :closable="false" :footer="null" @close="() => controls.visible.login = false">
      <div ref="attendant-id-card" class="flex flex-col rounded-lg overflow-hidden">
        <div class="flex flex-col items-center pt-4 bg-parqay-primary">
          <div class="rounded-full bg-white h-4 w-20" />
          <span class="text-base font-semibold text-white text-center py-4">Attendant Login</span>
        </div>
        <div class="bg-white flex flex-col space-y-8 items-center justify-center px-6 pt-8 pb-4">
          <div class="rounded-md bg-gray-100 overflow-hidden" style="width: 200px; height: 200px;">
            <vue-qr v-if="controls.login_result" color-dark="#1c55e4" background-color="#c5d4fa"
              logo-src="/static/img/dashboard/parqay-logo.png" :text="controls.login_result" :dot-scale="0.8"
              :logo-scale="0.2" :size="300" class="cursor-pointer hover:ring-8 ring-parqay-primary" />
            <qr-scanner v-else @detected="parseQRScannerResult" />
          </div>
          <a-icon v-if="controls.loading.login" type="loading" style="font-size: 35px; color: blue;" />
          <div v-else class="flex flex-col items-center bg-blue-100 rounded-lg mx-8 px-4 py-6 space-y-1 w-full">
            <span class="text-black text-sm font-semibold underline">
              HOW TO USE
            </span>
            <span class="text-sm text-center text-black">
              Active the scanner above to scan the QR code on your attendant ID card.
            </span>
            <a-divider>OR</a-divider>
            <span class="text-sm font-light text-center pt-1 text-gray-500">
              Login with token provided by your supervisor or enter the code after the dash on your Attendant Identity
              Card.
            </span>
            <span class="text-parqay-primary font-semibold" @click="() => controls.visible.manualLogin = true">Manual
              Login</span>
          </div>
          <div v-if="controls.visible.manualLogin" class="flex flex-col space-y-2 w-full">
            <label-item label="Email">
              <a-input v-model="attendant.email" allow-clear size="large" />
            </label-item>
            <label-item label="Token" desc="Assigned by property supervisor.">
              <a-input-password v-model="attendant.token" allow-clear size="large" />
            </label-item>
            <div class="w-full py-2">
              <a-button :loading="controls.loading.login" type="primary" block size="large"
                @click="manualLogin()">Login</a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <div class="bg-white border-t fixed left-0 bottom-0 right-0 px-4 py-2">
      <div v-if="attendant.data.id" class="flex flex-col pt-1 pb-4 space-y-2">
        <span class="text-base font-semibold text-black">Hello, {{ attendant.data.name }}</span>
        <a-button check-in type="primary" icon="qrcode" size="large" block
          @click="() => controls.visible.checkIn = true">Check-In Tenants</a-button>
      </div>
      <a-button v-else type="primary" size="large" @click="() => controls.visible.login = true">Attendant Login</a-button>
    </div>
  </div>
  <!-- <reservation-drawer-tenant :visible="controls.drawers.reservation.visible" :form="controls.drawers.reservation.form"
      @close="() => (controls.drawers.reservation.visible = false)" /> -->
</template>

<style lang="scss" scoped>
.tenant-reservations::v-deep {

  .ant-btn[check-in] {
    height: 45px;
    @apply flex items-center justify-center py-4 space-x-2 font-semibold;
  }

  .ant-divider-inner-text {
    @apply text-xs text-gray-600 font-light;
  }
}
</style>

<style lang="scss">
.attendant-modal {
  .ant-modal-content {
    @apply rounded-lg overflow-hidden;
  }
}
</style>
