<script>
import { ref, computed, watch, onMounted, nextTick } from "@vue/composition-api";
// APIs

import { API } from "src/views/auth/api";
import isInteger from 'lodash/isInteger';
import toNumber from 'lodash/toNumber';

export default {
  name: "SignupStep2Next",
  props: {},
  setup(props, ctx) {
    const isLoading = ref(true);
    const message = ref("Sending verification code...");
    const messageColorClass = ref("text-parqay-primary");
    const email = ref(
      computed(() => {
        return ctx.root.$store.state.signUpForm.email;
      })
    );
    const verifyCode = ref([]);
    const deepGet = ctx.root.$deepGet;

    const handleVerifyCode = (event) => {
      console.log(event.key);
      if (isInteger(toNumber(event.key))) {
        console.log("number pressed");
        if (verifyCode.value.length < 4) {
          verifyCode.value.push(toNumber(event.key));
        }
      }
      if (event.key == "Backspace") {
        verifyCode.value.splice(-1, 1);
      }
    };

    const getVerifyCode = (i) => {
      return deepGet(verifyCode.value, i);
    };

    const shouldHighlightBorderAtIndex = (i) => {
      const result = (
        i == verifyCode.value.length - 1 ||
        (i == 0 && verifyCode.value.length == 0) ||
        verifyCode.value.length == 4
      )
      return result;
    };

    watch(
      () => verifyCode.value,
      (val) => {
        console.log("verify code: " + val);
        if (verifyCode.value.length == 4) {
          validateVerifyCode();
        } else {
          isLoading.value = false;
        }
      }
    );

    const sendVerifyCode = () => {
      isLoading.value = true;
      API.initVerifyEmail(email.value)
        .then((res) => {
          isLoading.value = false;
          message.value = res.message;
          messageColorClass.value = "text-parqay-primary";
        })
        .catch((error) => {
          isLoading.value = false;
          message.value = error.message;
          messageColorClass.value = "text-red-500";
        });
    };

    const validateVerifyCode = () => {
      isLoading.value = true;
      message.value = "Verifying...";
      messageColorClass.value = "text-parqay-primary";
      API.verifyEmail(email.value, verifyCode.value.join(""))
        .then((res) => {
          isLoading.value = false;
          message.value = res.message;
          messageColorClass.value = "text-parqay-primary";
          ctx.emit("verify-complete");
        })
        .catch((error) => {
          isLoading.value = false;
          message.value = error.message;
          messageColorClass.value = "text-red-500";
        });
    };

    const focusOnInput = () => {
      const keyboardTriggerInput = document.querySelector(".first-input");
      if (keyboardTriggerInput) {
        keyboardTriggerInput.focus();
      }
    }

    onMounted(() => {
      window.addEventListener("keyup", handleVerifyCode);
      sendVerifyCode();
    });

    nextTick(() => {
      focusOnInput()
    })
    return {
      isLoading,
      message,
      messageColorClass,
      email,
      getVerifyCode,
      shouldHighlightBorderAtIndex,
      focusOnInput
    };
  },
};
</script>
<template>
  <div>
    <span class="text-base font-medium text-center">Now, time for verification, enter the 4 digits code sent to your
      email</span>
    <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer" style="min-height: 321px">
      <div class="flex flex-col justify-center py-4">
        <div class="flex justify-center">
          <svg-icon v-if="!isLoading" icon-class="email-verify" class="w-1/5 h-16" />
          <div v-else class="px-2 py-1 border-2 rounded-lg">
            <svg-icon icon-class="circular-loader" class="animate-spin h-8 w-8" />
          </div>
        </div>
        <div class="flex h-8 divide-x-2 divide-gray-400 divide-dashed">
          <div class="w-1/2" />
          <div class="w-1/2" />
        </div>
        <div class="flex justify-center px-6">
          <div class="flex border rounded-lg border-parqay-primary bg-white w-full h-10">
            <div class="flex w-3/4 items-center">
              <span class="text-center text-parqay-primary hover:text-blue-800 font-bold w-full">{{ email }}</span>
            </div>
            <div class="flex w-1/4 items-center">
              <button class="rounded bg-parqay-primary hover:bg-blue-500 text-white text-xs px-4 py-1"
                @click="$emit('prev')">
                Edit
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center mt-2 px-6 space-y-2">
          <span class="font-medium text-center py-2">Enter the 4 digits you received below</span>
          <div class="grid grid-cols-4 gap-8 h-16">
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(0),
              'border border-gray-500': !shouldHighlightBorderAtIndex(0),
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(0)
              }}</span>
            </div>
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(1),
              'border border-gray-500': !shouldHighlightBorderAtIndex(1),
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(1)
              }}</span>
            </div>
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(2),
              'border border-gray-500': !shouldHighlightBorderAtIndex(2),
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(2)
              }}</span>
            </div>
            <div class="h-5/6 flex items-center justify-center rounded-lg" :class="{
              'border-2 border-indigo-500': shouldHighlightBorderAtIndex(3),
              'border border-gray-500': !shouldHighlightBorderAtIndex(3),
            }" @click="focusOnInput()">
              <span class="text-black text-3xl font-bold">{{
                  getVerifyCode(3)
              }}</span>
            </div>
          </div>
          <p v-if="true" class="text-sm text-center my-0" :class="messageColorClass">
            <!-- Wrong code, re-enter or resend the code -->
            {{ message }}
          </p>
          <input type="text" pattern="\d*" class="first-input w-0 h-0 opacity-0">
        </div>
      </div>
    </div>
  </div>
</template>