<script>
import { ref, watch, toRefs, computed, onMounted, onBeforeUnmount, nextTick, reactive } from "@vue/composition-api";
import SlideBar from "src/views/components/components/SlideBar.vue";
import MessagingInboundBubble from "./components/MessagingInboundBubble.vue";
import MessagingOutboundBubble from "./components/MessagingOutboundBubble.vue";
import MessagingTypingIndicator from "./components/MessagingTypingIndicator.vue";
import Uploader from "./components/SMMSUploader.vue";
import AttachmentPreview from "./components/AttachmentPreviewModal.vue";
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import startCase from 'lodash/startCase';

import { API } from "src/views/components/api";
import { API as MessagingAPI } from "src/views/messaging/api.js";

export default {
  components: {
    SlideBar,
    MessagingInboundBubble,
    MessagingOutboundBubble,
    MessagingTypingIndicator,
    "SMMS-media-uploader": Uploader,
    AttachmentPreview
  },
  sockets: {
    connect: () => {
      console.log("socket connected");
    }
  },
  setup(props, ctx) {
    const deepGet = ctx.root.$deepGet;
    const serverNow = ctx.root.$serverNow;
    const getUserUID = ctx.root.$getUserUID;
    const shortUID = ctx.root.$shortUID;
    const copyToClipboard = ctx.root.$copyToClipboard;
    const show = ref(false);
    const loading = reactive({
      roles: true,
    });
    /**
     * Handle operator side typing indicator
     */
    var typingIndicator = {
      show: false,
      activate: false,
      value: null,
    };
    const messagesDefaultAreaHeight = () => window.innerHeight / 2;
    const controls = reactive({
      messagesAreaHeight: `${messagesDefaultAreaHeight()}px`,
      messagesAreaExpanded: false,
      userStatus: "offline",
      authenticated: true,
      hoverOperator: false,
      hoverNumberDisplay: false,
      clickClipboard: false,
      editOperatorName: false,
      showPremiumPrompt: JSON.parse(localStorage.getItem("messagingPremiumPromptDisabled") || false) ? false : true,
      segmented: "phoneNumber",
      quotedMessage: null,
      /** Handle ONLY customer side typing indicator! */
      typingIndicator: {
        show: false,
        activate: false,
        name: "user",
        value: null,
      },
      messagesScrollDirection: null,
      messagesScrollYPercent: 1.0,
      scrollEventLock: false
    });

    const conversationId = computed(() => ctx.root.$deepGet(state, `${state.type}.id`));

    const senderId = computed(() => {
      if (getRole() === "host") {
        if (state.type === "reservation") {
          return ctx.root.$deepGet(state, `${state.type}.user`)
        }
        if (state.type === "direct") {
          return ctx.root.$deepGet(state, `${state.type}.host`)
        }
      }
      if (getRole() === "tenant") {
        if (state.type === "reservation") {
          return ctx.root.$deepGet(state, `${state.type}.customer`)
        }
        if (state.type === "direct") {
          return ctx.root.$deepGet(state, `${state.type}.tenant`)
        }
      }
    })
    const senderName = computed(() => {
      if (getRole() === "host") {
        return ctx.root.$deepGet(state, `${state.type}.host_name`)
      }
      if (getRole() === "tenant") {
        return ctx.root.$deepGet(state, `${state.type}.tenant_name`)
      }
    })

    const receiverId = computed(() => {
      if (getRole() === "host") {
        if (state.type === "reservation") {
          return ctx.root.$deepGet(state, `${state.type}.customer`)
        }
        if (state.type === "direct") {
          return ctx.root.$deepGet(state, `${state.type}.tenant`)
        }
      }
      if (getRole() === "tenant") {
        if (state.type === "reservation") {
          return ctx.root.$deepGet(state, `${state.type}.user`)
        }
        if (state.type === "direct") {
          return ctx.root.$deepGet(state, `${state.type}.host`)
        }
      }
    })
    const receiverName = computed(() => {
      if (getRole() === "host") {
        return ctx.root.$deepGet(state, `${state.type}.tenant_name`)
      }
      if (getRole() === "tenant") {
        return ctx.root.$deepGet(state, `${state.type}.host_name`)
      }
    })

    const state = reactive({
      type: null,
      reservation: {},
      direct: {},
      useSMSForwarding: true,
      attachMedia: false,
      mediaUrls: []
    })
    const handles = {
      getReceiverStatus: null,
    };
    const defaults = {
      receiver: {
        name: "Loading...",
        alias: null,
        role: "Guest",
        phone_number: "Loading...",
        email: "Loading...",
      },
    };

    const updateMediaUrls = (urls) => {
      console.log("updateMediaUrls -> ", urls)
      state.mediaUrls = urls;
    }

    watch(() => controls.showPremiumPrompt, val => {
      if (!val) {
        localStorage.setItem("messagingPremiumPromptDisabled", true);
      }
    })

    const toggleDrawerVisibility = (onOff) => {
      loading.roles = true;
      updateView(onOff);
      if (onOff) {
        // Visibility-Change API: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
        document.addEventListener("visibilitychange", windowVisibilityChange);
      } else {
        document.removeEventListener("visibilitychange", windowVisibilityChange);
      }
      show.value = onOff;
      ctx.root.$toggleIntercomPosition(onOff);
      setTimeout(() => {
        loading.roles = false;
      }, 2000);
    }

    const receiver = ref(defaults.receiver);

    const operator = reactive({
      alias: {
        final: null,
        editing: null,
      },
      name: null,
      role: null,
    });

    const roles = ref([
      'operator',
      'manager',
      'representitive',
      'agent',
      'house owner'
    ]);

    const messagesScroll = ref(null);

    const messagesArea = ref(null);

    const messageToSend = ref(null);

    const copyPhoneNumber = (phoneNumber) => {
      controls.clickClipboard = true;
      copyToClipboard(phoneNumber);
      setTimeout(() => {
        controls.clickClipboard = false;
      }, 1000);
    };

    const copyEmail = (email) => {
      controls.clickClipboard = true;
      copyToClipboard(email);
      setTimeout(() => {
        controls.clickClipboard = false;
      }, 1000);
    };

    const joinConversation = (room_id, user_uid) => {
      console.log("entering conversation: " + room_id);
      ctx.root.$socket.emit("join-conversation", { room_id, user_uid });
      setTimeout(() => {
        getReceiverStatus(room_id, receiverId.value);
        getSenderData(room_id);
        getLatestMessages(room_id);
      }, 500);
      handles.getReceiverStatus = setInterval(() => {
        getReceiverStatus(room_id, receiverId.value);
      }, 20000);
    };
    const leaveConversation = (room_id, user_uid) => {
      console.log("leaving conversation: " + room_id);
      ctx.root.$socket.emit("leave-conversation", { room_id, user_uid });
      forOwn(handles, (v, k) => {
        clearInterval(v);
      });
      controls.userStatus = "offline";
      receiver.value = defaults.receiver;
    };

    const getReceiverInitial = () => {
      return deepGet(receiverName.value, "0", "").toUpperCase();
    };

    const getSenderInitial = () => {
      return deepGet(senderName.value, "0", "").toUpperCase();
    };

    const getRole = (reverse = false) => {
      if (reverse) {
        return ctx.root.$route.path.startsWith('/dashboard') ? "tenant" : "host";
      } else {
        return ctx.root.$route.path.startsWith('/dashboard') ? "host" : "tenant";
      }
    }

    const getMessages = () => {
      const messages = ctx.root.$store.state.messages[conversationId.value] || [];
      console.log("getMessages -> ", messages);
      console.log("reservation -> ", conversationId.value);
      return messages;
    }

    const editMessage = (it, editedMessage) => {
      it.message = editedMessage;
    };

    const deleteMessage = (id) => {
      ctx.root.$store.commit("remove-message", { id, room_id: conversationId.value });
    };

    const scrollToMessagesBottom = () => {
      if (messagesScroll.value && messagesArea.value) {
        controls.scrollEventLock = true;
        messagesScroll.value.scrollTo(
          {
            y: messagesArea.value.clientHeight,
          },
          500,
          "easeInQuad"
        );
        setTimeout(() => {
          controls.scrollEventLock = false;
        }, 800);
      }
    };

    const scrollToQuote = () => {
      const quotedMessage = document.querySelector(".quoted-message");
      quotedMessage.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const scrollToOriginalMessage = (id) => {
      const originalMessage = document.querySelector(`#${id}`);
      if (originalMessage) {
        originalMessage.scrollIntoView({ behavior: "smooth", block: "center" });
        nextTick(() => {
          originalMessage.classList.add("bg-gray-100", "ring-2", "ring-indigo-500", "mx-4", "my-2");
          setTimeout(() => {
            originalMessage.classList.remove("bg-gray-100", "ring-2", "ring-indigo-500", "mx-4", "my-2");
          }, 1000);
        });
      }
    };

    const handleKeyPress = (event) => {
      // Use Enter to send message
      // console.log(event.key);
      if (event.key == "Enter" && event.metaKey) {
        console.log("dashboard keypress: ⌘ + Enter");
        sendMessage();
      }
    };

    const resetMessageControlState = () => {
      messageToSend.value = null;
      controls.quotedMessage = null;
      state.mediaUrls = [];
      state.attachMedia = false;
    }

    const close = () => {
      resetMessageControlState();
      toggleDrawerVisibility(false);
    };

    const getLatestMessages = (room_id) => {
      ctx.root.$socket.emit("get-latest-messages", { room_id });
    };

    const getReceiverStatus = (room_id, user_uid) => {
      ctx.root.$socket.emit("get-user-status", { room_id, user_uid });
    };

    const getReceiverData = (room_id) => {
      ctx.root.$socket.emit("get-receiver-data", { room_id, user_uid: receiverId.value });
    };

    const getSenderData = (room_id) => {
      ctx.root.$socket.emit("get-sender-data", { room_id, user_uid: senderId.value });
    };

    const windowVisibilityChange = () => {
      console.log("windowVisibilityChange: ", document.visibilityState);
      if (document.visibilityState === "visible") {
        getReceiverStatus(conversationId.value, receiverId.value);
        getReceiverData(conversationId.value);
      }
    };

    const updateView = (visible) => {
      const userUID = getUserUID();
      if (!userUID) {
        controls.authenticated = false;
      }
      if (visible) {
        joinConversation(conversationId.value, userUID);
      } else {
        leaveConversation(conversationId.value, userUID);
      }
      getReceiverData(conversationId.value);
      nextTick(() => {
        setTimeout(() => {
          scrollToMessagesBottom();
        }, 200);
      });
    };

    const updateTypingIndicator = (room_id) => {
      ctx.root.$socket.emit("update-typing-indicator", { room_id, ...typingIndicator, user: senderName.value });
    };

    const messageTextAreaChanged = (update) => {
      typingIndicator = { ...typingIndicator, ...update };
      console.log("messageTextAreaChanged: ", update);
      console.log("typingIndicator: ", typingIndicator);
      updateTypingIndicator(conversationId.value);
    };

    const getConversation = async (room_id) => {
      try {
        const { data } = await MessagingAPI.getConversation(room_id);
        state.type = data.type;
        state[data.type] = data[data.type];
      } catch (error) {
        console.log("getConversation error: ", error);
        return;
      }
    }

    const handleScroll = (vertical) => {
      if (!controls.scrollEventLock) {
        if (vertical.process < controls.messagesScrollYPercent) {
          controls.messagesScrollDirection = "up";
          controls.segmented = null;
        } else {
          controls.messagesScrollDirection = "down";
          controls.segmented = "phoneNumber";
        }
        controls.messagesScrollYPercent = vertical.process;
      }
    }

    const handleOpenEvent = async ({ room_id, reservation }) => {
      console.log("handleOpenEvent");
      console.log("room_id -> ", room_id);
      console.log("reservation -> ", reservation);
      state.type = null;
      state.reservation = {};
      state.direct = {};
      if (room_id) {
        show.value = true;
        await getConversation(room_id);
      }
      else if (reservation) {
        // state.reservation = reservation;
        show.value = true;
        await getConversation(reservation.id);
      }
      toggleDrawerVisibility(true);
      readAllMessages();
    }

    const handleCloseEvent = () => {
      console.log("handleCloseEvent");
      close();
    };

    const readAllMessages = async () => {
      if (!conversationId.value) {
        return;
      }
      try {
        await API.readAllMessages(conversationId.value);
      } catch (error) {
        console.log("readAllMessages error -> ", error);
      }
    }

    const handleUpdateContactDrawerMode = (mode) => {
      if (mode === "phone-number") {
        controls.segmented = "phoneNumber";
      }
      if (mode === "email") {
        controls.segmented = "email";
      }
    }

    const sendSMS = (messageSenderRef) => {
      state.useSMSForwarding = true;
      ctx.root.$scrollToHashSection('#contact-message-sender');
      setTimeout(() => {
        messageSenderRef.focus();
      }, 500);
    }

    const sendMessage = () => {
      if (messageToSend.value) {
        const message = messageToSend.value;
        let SMMSMessage = message;
        const inlineUrls = state.mediaUrls.filter(v => v.use_short_url);
        if (inlineUrls.length > 0) {
          const bulletPoints = inlineUrls.map(item => `• ${item.type} doc: ${item.url}`).join("\n");
          SMMSMessage = `${message}\nDocument links:\n${bulletPoints}`;
        }
        const payload = {
          id: shortUID(),
          room_id: conversationId.value,
          sender_id: senderId.value,
          sender_name: senderName.value,
          message,
          receiver_id: receiverId.value,
          receiver_name: receiverName.value,
          dt: serverNow(),
          quote: controls.quotedMessage,
          media: state.mediaUrls,
          source: "direct"
        };
        if (getRole() === "host") {
          payload.source = state.useSMSForwarding ? "smms" : "direct";
        }
        if (ctx.root.$isAdmin()) {
          payload.admin_uid = getUserUID();
        }
        const SMMSPayload = Object.assign({}, payload);
        SMMSPayload.message = SMMSMessage;
        delete SMMSPayload.media;
        SMMSPayload.media_urls = state.mediaUrls.filter(v => !v.use_short_url).map(v => v.url);
        if (state.useSMSForwarding) {
          try {
            API.forwardSMMS(conversationId.value, state.type, SMMSPayload);
          } catch (error) {
            ctx.root.$openNotification(
              "SMS/MMS Forwarding Error",
              error.message,
              10,
              "error"
            );
          }
        }
        // Socket
        ctx.root.$socket.emit("conversation-exchange", payload);
        // Cache sent message in store
        ctx.root.$store.commit("cacheSentMessage", payload);
        resetMessageControlState();
        // Scroll to the bottom of the messages window
        scrollToMessagesBottom();
      }
    };

    onMounted(() => {
      ctx.root.$bus.$on("open-contact-drawer", handleOpenEvent);
      ctx.root.$bus.$on("close-contact-drawer", handleCloseEvent);
      ctx.root.$bus.$on("update-contact-drawer-mode", handleUpdateContactDrawerMode);
      window.addEventListener("keydown", handleKeyPress);
      ctx.root.sockets.subscribe("user-status-update", (d) => {
        controls.userStatus = d.is_active ? "active" : "offline";
      });
      ctx.root.sockets.subscribe("sender-data-update", (d) => {
        operator.alias.final = d.alias;
        operator.name = d.name;
        operator.role = d.role;
      });
      ctx.root.sockets.subscribe("receiver-data-update", (d) => {
        setTimeout(() => {
          receiver.value = d;
          if (!d.phone_number) {
            state.useSMSForwarding = false;
          }
          controls.typingIndicator.name = receiverName.value;
        }, 300);
      });
      ctx.root.sockets.subscribe("typing-indicator-update", (d) => {
        controls.typingIndicator = d;
      });
      ctx.root.sockets.subscribe("conversation-exchange", (d) => {
        scrollToMessagesBottom();
      });
    });

    onBeforeUnmount(() => {
      ctx.root.$bus.$off("open-contact-drawer", handleOpenEvent);
      ctx.root.$bus.$off("close-contact-drawer", handleCloseEvent);
      ctx.root.$bus.$off("update-contact-drawer-mode", handleUpdateContactDrawerMode);
      ctx.root.sockets.unsubscribe("user-status-update");
    });

    return {
      show,
      loading,
      controls,
      messagesDefaultAreaHeight,
      ...toRefs(state),
      receiver,
      operator,
      roles,
      messagesScroll,
      messagesArea,
      conversationId,
      senderId,
      receiverId,
      receiverName,
      getRole,
      getMessages,
      getReceiverInitial,
      getSenderInitial,
      copyPhoneNumber,
      copyEmail,
      messageToSend,
      sendSMS,
      messageTextAreaChanged,
      sendMessage,
      editMessage,
      deleteMessage,
      handleScroll,
      scrollToQuote,
      scrollToOriginalMessage,
      updateMediaUrls,
      close,
      isEmpty,
      startCase
    };
  },
};
</script>
<template>
  <slide-bar v-slot:default="slotProps" v-model="show" :width="400" backdrop close-by-mask @close-slide-bar="close()">
    <div class="contact-slide-card h-full flex-col justify-between">
      <div class="flex justify-between items-center border-b px-6 py-6">
        <div class="flex items-center space-x-3">
          <span class="font-bold text-lg text-gray-900 my-auto">
            Contact
          </span>
          <a-tag v-if="type === 'direct'" color="green">Direct Chat</a-tag>
          <div v-if="$isAdmin()"
            class="flex items-center px-3 py-1 rounded-full space-x-2 bg-parqay-primary text-white text-xs font-medium">
            <span>Admin</span>
            <a-tooltip title="Note as admin, you will be replying on behalf of the host.">
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
        </div>
        <span @click="slotProps.closeSlideBar">
          <svg-icon icon-class="close" class="cursor-pointer w-6 h-6 text-blue-900" />
        </span>
      </div>
      <div class="flex flex-col px-6 pt-4">

        <div v-if="!$controls.drawers.reservation.visible.secondary && type === 'reservation'"
          class="flex mb-2 items-center justify-between space-x-3 px-4 py-2 rounded-md border hover:bg-blue-100 cursor-pointer"
          @click.stop="$router.push({
            name: 'ReservationsDetails',
            params: {
              id: reservation.id
            }
          })">
          <div class="flex items-center space-x-3">
            <div class="flex-shrink-0 flex items-center justify-center w-8 h-8 rounded border bg-gray-50">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-5 h-5 text-parqay-primary">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
            </div>
            <span class="text-xs font-medium">
              {{ reservation.title }}
            </span>
          </div>
          <a-button v-if="$route.name !== 'ReservationsDetails'" type="primary" size="small" style="font-size: 0.75rem;"
            @click.stop="$router.push({
              name: 'ReservationsDetails',
              params: {
                id: reservation.id
              }
            })">Reservation</a-button>
        </div>

        <a-divider v-if="$isAdmin()">
          <div class="flex items-center space-x-2">
            <span class="text-sm">{{ operator.name }}'s</span>
            <a-tag color="orange">Tenant</a-tag>
          </div>
        </a-divider>

        <div class="flex mb-2 relative">
          <div class="flex items-center space-x-4 w-full">
            <div class="flex">
              <div class="text-center bg-parqay-primary w-12 h-12 rounded-full text-white mr-6 my-auto">
                <!-- <avatar :user-id="$deepGet(model, 'customer')" /> -->
                <span class="text-3xl font-medium leading-12">{{ getReceiverInitial() }}</span>
              </div>
            </div>
            <div class="flex flex-col space-y-1 h-full w-full">
              <div class="flex items-center justify-start pt-1 pb-1">
                <span class="ml-8 text-xl font-semibold text-center">{{ receiverName }}</span>
              </div>
              <div class="flex items-center pl-8 space-x-2">
                <div class="border-parqay-primary rounded-full px-2 py-0.5 border bg-white">
                  <p class="text-center font-medium text-parqay-primary text-xs h-full my-auto">
                    {{ startCase(receiver.role) }}
                  </p>
                </div>
                <span class="flex items-center px-2 text-xs font-medium"
                  :class="controls.userStatus == 'active' ? 'text-green-400' : 'text-gray-400'">
                  <svg class="mr-1.5 h-2 w-2"
                    :class="controls.userStatus == 'active' ? 'text-green-400' : 'text-gray-400'" fill="currentColor"
                    viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  {{ startCase(controls.userStatus) }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="controls.messagesAreaExpanded" class="absolute right-4 top-0 bottom-0">
            <div class="flex h-full items-center">
              <div
                class="flex w-10 h-10 shadow-xl rounded-full bg-parqay-primary hover:bg-blue-500 items-center justify-center cursor-pointer"
                @click="() => {
                  controls.messagesAreaExpanded = false;
                  controls.messagesAreaHeight = `${messagesDefaultAreaHeight()}px`;
                }
                  ">
                <svg-icon icon-class="shrink" class="text-white w-6 h-6" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!controls.messagesAreaExpanded" class="flex flex-col py-2"
          :class="controls.showPremiumPrompt ? 'mb-2' : 'border-b'">
          <!-- <div class="text-center w-full">
            <span class="text-base font-medium text-gray-600">Phone Number</span>
          </div> -->
          <div class="flex items-center justify-center z-20 py-2 bg-white">
            <div class="flex flex-grow items-center border shadow-sm divide-x rounded overflow-hidden bg-white">
              <button class="h-full w-1/2 px-4 py-2 bg-white text-gray-600 font-medium focus:outline-none"
                :class="controls.segmented == 'phoneNumber' ? 'shadow-inner' : ''"
                @click="() => (controls.segmented = 'phoneNumber')">
                Phone Number
              </button>
              <button class="h-full w-1/2 px-4 py-2 text-gray-600 font-medium focus:outline-none bg-white"
                :class="controls.segmented == 'email' ? 'shadow-inner' : ''"
                @click="() => (controls.segmented = 'email')">
                Email
              </button>
            </div>
          </div>
          <transition name="slide-up-down">
            <div v-if="['phoneNumber', 'email'].includes(controls.segmented)"
              class="w-full bg-gray-200 rounded-xl py-4 my-2 relative"
              :class="controls.hoverNumberDisplay ? 'pl-4' : 'text-center'" style="z-index: 0;"
              @mouseover="() => (controls.hoverNumberDisplay = true)"
              @mouseout="() => (controls.hoverNumberDisplay = false)">
              <span class="text-xl font-medium text-gray-900" :class="controls.hoverNumberDisplay ? 'text-left' : ''">{{
                (controls.segmented == "phoneNumber" && (receiver.phone_number ? receiver.phone_number : "N/A")) ||
                (controls.segmented == "email" && (receiver.email ? receiver.email : "N/A"))
              }}</span>
              <div class="absolute right-4 top-0 bottom-0" :class="controls.hoverNumberDisplay ? '' : 'hidden'">
                <div class="flex items-center my-auto h-full space-x-2">
                  <a-tooltip v-show="controls.segmented == 'phoneNumber'" trigger="click"
                    :visible="controls.clickClipboard" placement="top">
                    <template slot="title">
                      <span>Copied!</span>
                    </template>
                    <button class="focus:outline-none" @click="copyPhoneNumber(receiver.phone_number)">
                      <svg-icon icon-class="clipboard" class="w-5 h-5"
                        :class="controls.clickClipboard ? 'text-parqay-primary' : 'text-gray-500 hover:text-gray-700'" />
                    </button>
                  </a-tooltip>
                  <a-tooltip v-show="controls.segmented == 'email'" trigger="click" :visible="controls.clickClipboard"
                    placement="top">
                    <template slot="title">
                      <span>Copied!</span>
                    </template>
                    <button class="focus:outline-none" @click="copyEmail(receiver.email)">
                      <svg-icon icon-class="clipboard" class="w-5 h-5"
                        :class="controls.clickClipboard ? 'text-parqay-primary' : 'text-gray-500 hover:text-gray-700'" />
                    </button>
                  </a-tooltip>
                  <a v-show="controls.segmented == 'phoneNumber' && getRole() === 'host' && receiver.phone_number" class="
                    bg-white
                    border border-parqay-primary
                    rounded-full
                    text-parqay-primary
                    hover:text-white
                    hover:bg-parqay-primary
                    px-2
                    py-1
                    text-xs
                    font-medium
                    focus:outline-none
                  " @click.stop="sendSMS($refs.messageSender)">
                    Send SMS/MMS
                  </a>
                  <a v-show="controls.segmented == 'email' && getRole() === 'host'" class="
                    bg-white
                    border border-parqay-primary
                    rounded-full
                    text-parqay-primary
                    hover:text-white
                    hover:bg-parqay-primary
                    px-2
                    py-1
                    text-xs
                    font-medium
                    focus:outline-none
                  " @click.stop="$bus.$emit('toggle-email-editor', { tenant_id: receiverId })">
                    Send Email
                  </a>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- Messaging scroll area -->
        <div class="overflow-hidden border-b relative" :class="{ 'border-t': controls.messagesAreaExpanded }"
          :style="{ height: controls.messagesAreaHeight }">
          <smooth-scroll ref="messagesScroll" @handle-scroll="handleScroll" class="h-full overflow-y-scroll">
            <keep-alive>
              <div ref="messagesArea" class="flex flex-col pt-4">
                <div v-for="(it, i) in getMessages()" :key="`message-bubble-${i}`">
                  <messaging-outbound-bubble v-if="it.sender_id === senderId" :sender-initial="getSenderInitial()"
                    :message="it.message" :media="it.media" :dt="it.dt || it.created_at.$date" :quote="it.quote"
                    :source="it.source" @edit="(editedMessage) => editMessage(it, editedMessage)" @quote="() => {
                      controls.quotedMessage = it;
                      scrollToQuote();
                    }
                      " @delete="() => deleteMessage(it.id)"
                    @scroll-to-original-message="(messageId) => scrollToOriginalMessage(messageId)" />
                  <messaging-inbound-bubble v-else-if="it.sender_id === receiverId" :sender-initial="getReceiverInitial()"
                    :message="it.message" :media="it.media" :meta="it.metadata" :dt="it.dt || it.created_at.$date"
                    :quote="it.quote" :source="it.source" @quote="() => {
                      controls.quotedMessage = it;
                      scrollToQuote();
                    }
                      " @delete="() => deleteMessage(it.id)"
                    @scroll-to-original-message="(messageId) => scrollToOriginalMessage(messageId)" />
                </div>
                <messaging-typing-indicator :controls="controls.typingIndicator" />
              </div>
            </keep-alive>
          </smooth-scroll>
          <div class="absolute right-2 bottom-6">
            <div v-if="!controls.messagesAreaExpanded"
              class="flex w-10 h-10 shadow-xl rounded-full bg-white border hover:border-none hover:bg-blue-500 items-center justify-center cursor-pointer"
              @click="() => {
                controls.messagesAreaExpanded = true;
                controls.messagesAreaHeight = '600px';
              }
                ">
              <svg-icon icon-class="expand" class="text-parqay-primary hover:text-white w-6 h-6" />
            </div>
          </div>
          <div v-if="!controls.authenticated" class="absolute left-0 right-0 bottom-0 bg-parqay-primary opacity-80">
            <div class="flex h-full items-center px-4 py-2">
              <span class="text-white text-base">Your login credential is expired, please login again</span>
              <button class="bg-white text-parqay-primary px-2 py-1 rounded hover:bg-gray-100 focus:outline-none">
                Login
              </button>
            </div>
          </div>
          <div v-if="controls.showPremiumPrompt && !$isAdmin()" class="absolute left-0 right-0 top-0 bg-white border">
            <div class="flex h-full items-center px-2 py-2 space-x-2">
              <div class="cursor-pointer" @click="() => (controls.showPremiumPrompt = false)">
                <svg-icon icon-class="close" class="h-4 w-4 text-blue-400 hover:text-parqay-primary" />
              </div>
              <span class="text-parqay-primary text-xs">Only last 50 messages will be saved. To have unlimited access,
                please upgrade</span>
              <button
                class="text-parqay-primary px-2 py-1 rounded-full font-medium bg-blue-100 hover:bg-parqay-primary hover:text-white focus:outline-none">
                Upgrade
              </button>
            </div>
          </div>
        </div>

        <!-- Operator Area -->

        <div class="flex mt-6 space-x-4">
          <div class="text-left w-1/2 my-auto">
            <a-select v-model="operator.role" :default-value="startCase(roles[0])" style="width: 100%"
              :loading="loading.roles" @change="(val) => {
                $socket.emit('update-sender-role', { room_id: conversationId, user_uid: senderId, role: val });
              }
                ">
              <a-select-option v-for="(role, i) in roles" :key="`role-${i}`" :value="role">
                {{ startCase(role) }}
              </a-select-option>
            </a-select>
          </div>
          <div
            class="flex justify-center items-center w-1/2 border border-parqay-primary py-1 my-2 cursor-pointer relative"
            @mouseover="() => (controls.hoverOperator = true)" @mouseout="() => (controls.hoverOperator = false)">
            <span v-if="!controls.editOperatorName" class="text-base font-light text-parqay-primary">{{
              operator.alias.final || operator.name }}</span>
            <a-input v-else v-model="operator.alias.editing" class="text-base font-light text-parqay-primary" />
            <div class="absolute right-2 my-auto text-xs" :class="controls.hoverOperator ? 'opacity-100' : 'opacity-0'">
              <a v-if="!controls.editOperatorName" class="text-right font-medium text-gray-500" @click="() => {
                controls.editOperatorName = true;
                operator.alias.editing = operator.alias.final;
              }
                ">Edit</a>
              <a v-else class="text-right font-medium text-gray-500" @click="() => {
                controls.editOperatorName = false;
                if (isEmpty(operator.alias.editing)) {
                  return;
                }
                $socket.emit('update-sender-alias', { room_id: conversationId, user_uid: senderId, alias: operator.alias.editing });
                operator.alias.final = operator.alias.editing;
              }
                ">OK</a>
            </div>
          </div>
        </div>

        <!-- Message Control Area -->

        <div class="flex flex-col space-y-2 mt-3 mb-3 quoted-message">
          <div class="flex items-center">
            <span class="text-sm font-medium text-gray-600 pb-1">Enter your message</span>
          </div>
          <a-divider v-if="$isAdmin()">
            <div class="flex items-center space-x-2">
              <span class="text-sm">On behalf of host:</span>
              <a-tag color="geekblue">{{ operator.name }}</a-tag>
            </div>
          </a-divider>
          <template v-if="controls.quotedMessage">
            <messaging-outbound-bubble v-if="controls.quotedMessage.sender_id === senderId"
              :sender-initial="getSenderInitial()" :message="controls.quotedMessage.message"
              :dt="controls.quotedMessage.dt || controls.quotedMessage.created_at.$date" is-quoted source="direct"
              @remove-quote="() => (controls.quotedMessage = null)" />
            <messaging-inbound-bubble v-else-if="controls.quotedMessage.sender_id === receiverId"
              :sender-initial="getReceiverInitial()" :message="controls.quotedMessage.message"
              :dt="controls.quotedMessage.dt || controls.quotedMessage.created_at.$date" is-quoted source="direct"
              @remove-quote="() => (controls.quotedMessage = null)" />
          </template>
          <SMMS-media-uploader v-if="attachMedia && conversationId" :room-id="conversationId" :message="messageToSend"
            @update-media-urls="updateMediaUrls">
            <a-textarea ref="messageSender" v-model="messageToSend"
              :placeholder="`Enter message to send to ${$isAdmin() ? receiverName : getRole(true)}`"
              :auto-size="{ minRows: 3, maxRows: 8 }" allow-clear @focus="() => messageTextAreaChanged({ show: true })"
              @blur="() => messageTextAreaChanged({ show: false })"
              @change="(e) => messageTextAreaChanged({ activate: true, value: e.target.value })" />
          </SMMS-media-uploader>
          <div v-else class="pt-2">
            <a-textarea ref="messageSender" v-model="messageToSend"
              :placeholder="`Enter message to send to ${$isAdmin() ? receiverName : getRole(true)}`"
              :auto-size="{ minRows: 3, maxRows: 8 }" allow-clear @dragover.prevent="() => {
                attachMedia = true;
              }" @focus="() => messageTextAreaChanged({ show: true })"
              @blur="() => messageTextAreaChanged({ show: false })"
              @change="(e) => messageTextAreaChanged({ activate: true, value: e.target.value })" />
          </div>
          <div v-if="getRole() === 'host'" class="flex items-center justify-between py-2">
            <div class="flex items-center space-x-2">
              <span class="text-sm font-medium text-parqay-primary">Forward same message as SMS/MMS</span>
              <a-tooltip
                title="The SMS/MMS will be sent out by our masked phone number to tenant and replies will present in the chatroom here.">
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <a-switch v-model="useSMSForwarding" :disabled="!receiver.phone_number" />
          </div>
          <div class="flex items-center justify-between py-2">
            <div class="flex items-center space-x-2">
              <span class="text-sm font-medium text-parqay-primary">Attach Media</span>
              <a-tooltip
                title="Accepted media formats include JPEG, PNG, and GIF images, MP4, 3GPP, and Quicktime videos, and PDF, PowerPoint, Excel, and Word documents. You can also attach various audio formats such as AAC, AMR, and WAV files to your message.">
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <a-switch v-model="attachMedia" />
          </div>
        </div>

        <div id="contact-message-sender" class="flex justify-between my-6 relative">
          <button class="
              w-full
              flex
              justify-center
              py-3
              px-4
              border border-transparent
              rounded-md
              shadow-sm
              text-sm
              font-medium
              text-white
              bg-parqay-primary
              hover:bg-blue-500
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              disabled:cursor-not-allowed
              disabled:bg-gray-200 
              disabled:text-gray-500 
              disabled:ring-1 
              disabled:ring-gray-300
            " :disabled="!messageToSend || (attachMedia && mediaUrls.length == 0)" @click="sendMessage">
            Send
          </button>
          <div v-if="(messageToSend && (!attachMedia || mediaUrls.length > 0))"
            class="absolute inset-y-0 right-2 flex items-center space-x-2">
            <button class="w-6 h-6 rounded bg-white border border-gray-300 text-parqay-primary text-center text-sm">
              ⌘
            </button>
            <button class="w-12 h-6 rounded bg-white border border-gray-300 text-parqay-primary text-center text-sm">
              enter
            </button>
          </div>
        </div>

        <div class="h-8" />
      </div>
    </div>
    <attachment-preview />
  </slide-bar>
</template>
<style lang="scss">
.contact-slide-card {
  .slide-up-down-enter-active {
    transition: transform 0.3s ease, opacity 0.3s ease;
    will-change: transform, opacity;
  }

  .slide-up-down-leave-active {
    transition: transform 0.3s ease, opacity 0.3s ease;
    will-change: transform, opacity;
  }

  .slide-up-down-enter,
  .slide-up-down-leave-to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    will-change: transform, opacity;
  }
}
</style>