<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-100">
        <div class="mx-4 md:max-w-lg w-full flex flex-col items-center px-9 py-5 space-y-4 bg-white rounded-lg shadow-md">

            <div class="w-full flex items-center justify-center pt-2">
                <img src="https://parqay-assets.s3.us-west-2.amazonaws.com/dashboard/email/main-logo.png"
                    class="object-contain md:object-cover px-8 h-20">
            </div>

            <template v-if="!isCompleted">
                <h2 class="text-lg font-semibold text-black pb-2">Setup New Password</h2>

                <div class="w-full flex items-center justify-between">
                    <span>For user</span>
                    <a-tag color="cyan">{{ $route.query.user }}</a-tag>
                </div>

                <a-input-password v-model="password" placeholder="New Password" allow-clear size="large" />

                <a-input-password v-model="confirmPassword" placeholder="Confirm Password" allow-clear size="large" />

                <div class="pt-4">
                    <a-button type="primary" :loading="loading" size="large"
                        :disabled="!password || !confirmPassword || password !== confirmPassword"
                        @click.stop="updatePassword">Update Password</a-button>
                </div>
            </template>
            <template v-else>
                <a-icon type="check-circle" theme="twoTone" style="font-size: 50px;" />
                <h2 class="text-lg font-semibold text-black">Password Reset Completed</h2>
                <p class="text-gray-600">Your password has been successfully reset.</p>
                <a-button type="primary" @click.stop="redirect">Continue</a-button>
            </template>
        </div>
    </div>
</template>
  
<script>
import { ref } from '@vue/composition-api';
import { API } from "./api";

export default {
    setup(props, ctx) {
        const loading = ref(false);
        const password = ref(null);
        const confirmPassword = ref(null);
        const isCompleted = ref(false);

        const updatePassword = async () => {
            const token = ctx.root.$route.params.token;
            if (token) {
                try {
                    loading.value = true;
                    await API.updateResetPassword(token, password.value);
                    setTimeout(() => {
                        loading.value = false;
                        isCompleted.value = true;
                    }, 1500);
                } catch (error) {
                    ctx.root.$openNotification("Password Reset Error", error.message);
                }
            }
        };

        const redirect = () => {
            const target = ctx.root.$route.query.redirect;
            if (target === "marketplace") {
                location.href = "https://parqay.com/login";
                return;
            }
            if (target === "dashboard") {
                ctx.root.$router.push("/login");
            }
        }

        return {
            loading,
            password,
            confirmPassword,
            isCompleted,
            updatePassword,
            redirect
        };
    },
};
</script>
  