<script>
import { reactive } from "@vue/composition-api";

import AttachmentList from "./SMMSMediaAttachmentList.vue";

export default {
  name: "MessagingInboundBubble",
  components: { AttachmentList },
  props: {
    senderInitial: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    media: {
      type: Array,
      default: () => []
    },
    meta: {
      type: Object,
      default: null
    },
    dt: {
      type: String,
      required: true,
    },
    isQuoted: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
    },
    source: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const controls = reactive({
      click: false,
      hover: false,
      expand: {
        profile: false
      },
      doubleCheckDelete: false,
    });
    const clickBubble = () => {
      controls.click = true;
      ctx.root.$copyToClipboard(props.message);
      setTimeout(() => {
        controls.click = false;
      }, 1000);
    }
    return {
      controls,
      clickBubble
    };
  },
};
</script>
<template>
  <div class="grid grid-cols-12 relative hover:bg-gray-100 cursor-pointer"
    :class="{ 'bg-gray-100 hover:bg-gray-200 shadow-md rounded-md px-1 py-2': isQuoted }"
    @mouseover="() => (controls.hover = true)" @mouseleave="() => {
      controls.hover = false;
      controls.doubleCheckDelete = false;
    }
      ">
    <div class="flex flex-col space-y-2 px-3 pb-1 rounded-lg"
      :class="isQuoted ? 'col-start-1 col-end-13 py-1' : 'col-start-1 col-end-13 pt-2'">
      <div v-if="isQuoted" class="flex items-center justify-between">
        <span class="text-base font-medium text-gray-600 italic">You Quote:</span>
        <button class="focus:outline-none" @click="$emit('remove-quote')">
          <svg-icon icon-class="close" class="w-5 h-5 text-parqay-primary hover:text-parqay-primary" />
        </button>
      </div>
      <div v-else-if="meta && controls.expand.profile" class="flex flex-col items-center justify-center pb-4">
        <a-divider style="margin: 8px 0;"><span class="text-xs text-center">SMS / MMS Info</span></a-divider>
        <div class="w-full grid grid-cols-2 gap-2 bg-blue-100 rounded-md px-4 py-4"
          @click.stop="() => controls.expand.profile = false">
          <div class="flex items-center space-x-3 mb-1">
            <div class="text-xs font-medium text-gray-600">From:</div>
            <div class="text-xs font-medium text-parqay-primary">{{ meta.from }}</div>
          </div>
          <div class="flex items-center space-x-3 mb-1">
            <div class="text-xs font-medium text-gray-600">City:</div>
            <div class="text-xs font-medium text-parqay-primary">{{ meta.from_city }}</div>
          </div>
          <div class="flex items-center space-x-3 mb-1">
            <div class="text-xs font-medium text-gray-600">State:</div>
            <div class="text-xs font-medium text-parqay-primary">{{ meta.from_state }}</div>
          </div>
          <div class="flex items-center space-x-3 mb-1">
            <div class="text-xs font-medium text-gray-600">Country:</div>
            <div class="text-xs font-medium text-parqay-primary">{{ meta.from_country }}</div>
          </div>
          <div class="flex items-center space-x-3">
            <div class="text-xs font-medium text-gray-600">Zipcode:</div>
            <div class="text-xs font-medium text-parqay-primary">{{ meta.from_zip }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <div
          class="flex-none items-center justify-center h-10 w-10 rounded-full bg-blue-500 hover:bg-blue-600 flex-shrink-0 cursor-pointer relative"
          @click.stop="() => controls.expand.profile = !controls.expand.profile">
          <span class="text-center text-lg text-white absolute left-0 right-0 top-0 bottom-0 m-auto h-1/2"
            style="line-height: initial">{{
              senderInitial
            }}</span>
        </div>
        <div class="relative ml-3 text-sm bg-white hover:bg-gray-50 py-2 px-4 shadow rounded-xl"
          :class="{ 'w-full': isQuoted }">
          <a-tooltip :visible="controls.click" title="Copied!" @click.stop="clickBubble()">
            <p style="margin:0px;">
              <a-tag v-if="source === 'smms'" color="orange" style="font-size:0.65rem; font-weight: 600;"
                @click.stop="() => controls.expand.profile = true">SMS /
                MMS</a-tag>
              <span v-if="message" v-html="$formatLongTextIfNeeded(message.replace(/\n/g, '<br>'))"></span>
              <span v-else class="text-xs text-gray-400 font-medium">[ No text, attachments ({{ media.length }}). ]</span>
            </p>
          </a-tooltip>
        </div>
      </div>
      <div v-if="quote"
        class="rounded-lg bg-gray-200 hover:bg-blue-200 text-gray-500 hover:text-blue-500 font-medium px-4 py-2 cursor-pointer"
        @click="$emit('scroll-to-original-message', quote.id)">
        <span>{{ quote.sender_name }}:<br><span v-html="$formatLongTextIfNeeded(quote.message)"></span></span>
      </div>
      <div v-else-if="media.length > 0">
        <a-divider>
          <span class="text-xs text-center">
            Attachments ({{ media.length }})
          </span>
        </a-divider>
        <attachment-list :media="media" :isSMMS="source === 'smms'" />
      </div>
    </div>
    <div class="col-span-12 px-3 pb-2">
      <div class="flex items-center justify-between w-full">
        <span class="w-1/3 text-left text-gray-500 text-xs font-bold pt-2 pl-1 whitespace-nowrap">{{
          $humanizeDate($loadBackendDate(dt))[1] }}</span>
        <span class="w-1/3 text-center text-gray-500 text-xs font-light pt-2 pl-1 whitespace-nowrap">{{
          $humanizeDate($loadBackendDate(dt))[0] }}</span>
        <div class="w-1/3" />
      </div>
    </div>
    <div v-if="controls.hover && !isQuoted" class="absolute right-2" :style="{ top: '-0.5rem' }">
      <div class="flex divide-x border rounded bg-white h-6">
        <template v-if="!controls.doubleCheckDelete">
          <button
            class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => $emit('quote')">
            Quote
          </button>
          <button
            class="text-xs text-pink-500 hover:text-pink-600 hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => (controls.doubleCheckDelete = true)">
            Delete
          </button>
        </template>
        <template v-else>
          <button
            class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => (controls.doubleCheckDelete = false)">
            Cancel
          </button>
          <button
            class="text-xs text-pink-500 hover:text-pink-600 hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => $emit('delete')">
            Delete
          </button>
        </template>
      </div>
    </div>
  </div>
</template>