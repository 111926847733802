import loadScript from "simple-load-script";
import googleOneTap from "google-one-tap";
import { loginCallback } from "src/services/authentication/oauth";
// import { rollbar } from "src/plugins/rollbar";

const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;

const initAuth2Client = () => {
  return new Promise((resolve, reject) => {
    if (!window.gapi.auth2.getAuthInstance()) {
      console.log("3.1");
      window.gapi.auth2
        .init({
          client_id: googleClientId
        })
        .then(
          function success() {
            console.log("gapi.auth2.GoogleAuth ready: ");
            resolve(window.gapi.auth2.getAuthInstance());
          },
          function error(error) {
            // That's where the IFrame initialization error should be handled.
            // If you don't catch the rejection of the promise here, the error will be thrown.
            reject(error);
          }
        );
    } else {
      console.log("3.2");
      resolve(window.gapi.auth2.getAuthInstance());
    }
  });
};

export const initGoogleSdk = () => {
  return new Promise((resolve, reject) => {
    window.onGoogleScriptLoaded = () => {
      console.log("Start onloaded callback >> Google");
      if (!window.gapi.auth2) {
        window.gapi.load(
          "auth2",
          () => {
            console.log("gapi.auth2 >> sdk loaded [1]");
            initAuth2Client()
              .then(gAuth => {
                console.log("initGoogleSdk ready: ", gAuth);
                window.gAuth = gAuth;
                resolve(gAuth);
              })
              .catch(error => {
                reject(error);
              });
          },
          error => {
            reject(error);
          }
        );
      } else {
        console.log("gapi.auth2 >> sdk loaded [3]");
        initAuth2Client()
          .then(gAuth => {
            console.log("initGoogleSdk ready: ", gAuth);
            window.gAuth = gAuth;
            resolve(gAuth);
          })
          .catch(error => {
            reject(error);
          });
      }
    };

    loadScript({
      url: "https://apis.google.com/js/platform.js",
      attrs: { async: "", defer: "", crossorigin: "anonymous" }
    })
      .then(scriptRef => {
        console.log("Google platform script load ready", scriptRef);
        if (window.gapi) {
          window.onGoogleScriptLoaded();
        } else {
          reject("Cannot load Google platform script");
        }
      })
      .catch(error => {
        console.log("Google platform script load error", error);
        reject(error);
      });
  });
};

const oneTapOptions = {
  client_id: googleClientId, // required
  auto_select: false, // optional
  cancel_on_tap_outside: false, // optional
  context: "signin" // optional
};

export const initOneTapLogin = (source = "dashboard") => {
  googleOneTap(oneTapOptions, response => {
    // Send response to server
    console.log("One Tap SignIn>> response", response);
    const id_token = response.credential;
    loginCallback("google", id_token, source);
  });
};

const onHostSignInSuccess = googleUser => {
  console.log("Logged in as: ", googleUser.getBasicProfile());
  const id_token = googleUser.getAuthResponse().id_token;
  loginCallback("google", id_token);
};

const onTenantSignInSuccess = googleUser => {
  console.log("Logged in as: ", googleUser.getBasicProfile());
  const id_token = googleUser.getAuthResponse().id_token;
  loginCallback("google", id_token, "tenant");
};

const onSignInFailure = error => {
  console.log(error);
  if (error.error !== "popup_closed_by_user") {
    // rollbar.error(error);
  }
};

export const renderGoogleSignInButton = (
  btnId,
  source = "dashboard",
  onGoogleSignInFailure = onSignInFailure
) => {
  let onGoogleSignInSuccess;
  if (source == "dashboard") {
    onGoogleSignInSuccess = onHostSignInSuccess;
  }
  if (source == "tenant") {
    onGoogleSignInSuccess = onTenantSignInSuccess;
  }
  window.gapi.signin2.render(btnId, {
    scope: "profile email",
    width: 240,
    height: 50,
    longtitle: true,
    theme: "light",
    onsuccess: onGoogleSignInSuccess,
    onfailure: onGoogleSignInFailure
  });
};

// Options refer to => https://developers.google.com/identity/sign-in/web/reference#gapiauth2signinoptions
const signInOptions = {
  scope: "profile email"
};

export const login = async (
  source = "dashboard",
  onGoogleSignInFailure = onSignInFailure
) => {
  var googleUser;
  try {
    googleUser = await window.gAuth.signIn(signInOptions);
  } catch (error) {
    onGoogleSignInFailure(error);
    return;
  }
  if (source == "dashboard") {
    onHostSignInSuccess(googleUser);
  }
  if (source == "tenant") {
    onTenantSignInSuccess(googleUser);
  }
};
