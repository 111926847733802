import { API } from "src/views/auth/api";
import { saveUserData, authCallback, serverNow } from "src/plugins/prototype";
// import { rollbar } from "src/plugins/rollbar";
import router from "../routing/router";

export const loginCallback = async (
  provider,
  user_data,
  source = "dashboard"
) => {
  var resp, payload;
  switch (provider) {
    case "facebook":
    case "apple":
      payload = { user_data, source, agreement_sign_at: serverNow() };
      break;
    case "google":
      payload = {
        user_token: user_data,
        source,
        agreement_sign_at: serverNow()
      };
      break;
    default:
      break;
  }
  try {
    resp = await API.loginWithOauth(provider, payload);
  } catch (error) {
    console.log("login callback >> error", error);
    // rollbar.error(error);
    return;
  }
  console.log("login callback >> success", resp);
  saveUserData(resp.data);
  authCallback();
  if (provider === "facebook") {
    const response = await window.FB.logout();
    // Person is now logged out
    console.log("Facebook button >> logout: ", response);
  }
  if (provider === "google" && window.gAuth.isSignedIn.get()) {
    window.gAuth.signOut();
    console.log("Google button >> logout");
  }
  const previousRoute = router.currentRoute.query.next;
  if (resp.data.extra.length > 0) {
    router.push({
      name: "fullfill-profile",
      params: previousRoute || resp.data.next_route
    });
  } else {
    if (previousRoute) {
      router.push(previousRoute);
    } else {
      router.push("/next/" + resp.data.next_route);
    }
  }
};
