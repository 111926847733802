import request from "src/services/api/request";

export const API = {
  balanceSummary() {
    return request.get("payments/balance-summary/tenant");
  },
  listTransactions({ role }) {
    return request.get("payments/transactions/" + role);
  },
  getInvoiceLink({ source }) {
    return request.get("payments/get-invoice-link/" + source);
  },
  getReservation({ source }) {
    return request.get("payments/get-reservation/" + source);
  },
  initRefund({ source }) {
    return request.post("payments/refund/" + source);
  }
};