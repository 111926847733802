<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import First from "./Step2First";
import Next from "./Step2Next";

export default {
  name: "SignupStep2",
  components: {
    First,
    Next,
  },
  props: {
    // Use in account settings - change phone number modal
    modalMode: {
      type: Boolean,
      default: false,
    },
    hashRoute: {
      type: String,
      default: "step-two",
    },
  },
  setup(props, ctx) {
    const currentStep = ref(0);
    const email = ref(null);
    const enableFirst = ref(false);
    const enableNext = ref(false);

    const showPrevious = ref(
      computed(() => {
        if (props.modalMode) {
          if (currentStep.value == 0) {
            return false;
          }
        }
        return true;
      })
    );

    const prevAction = () => {
      if (currentStep.value == 0) {
        ctx.emit("prev");
        return;
      }
      if (currentStep.value == 1) {
        currentStep.value -= 1;
        return;
      }
    };

    const nextAction = () => {
      if (currentStep.value == 0) {
        currentStep.value += 1;
        return;
      }
      if (currentStep.value == 1) {
        ctx.emit("next");
        return;
      }
    };

    const route = ctx.root.$route;
    const router = ctx.root.$router;

    const getEmail = (v) => {
      ctx.emit("payload-update", "email", v);
      email.value = v;
    };

    const getSubscribe = (sub, val) => {
      ctx.emit("payload-update", "subscribe", [sub, val]);
    };

    const nextButtonStates = () => {
      if (currentStep.value == 0) {
        return {
          title: "Verify",
          enabled: email.value && enableFirst.value,
          colorClass:
            email.value && enableFirst.value
              ? "bg-parqay-primary hover:bg-blue-500"
              : "bg-gray-300",
        };
      }
      if (currentStep.value == 1) {
        return {
          title: "Next",
          enabled: enableNext.value,
          colorClass: enableNext.value
            ? "bg-parqay-primary hover:bg-blue-500"
            : "bg-gray-300",
        };
      }
    };

    onMounted(() => {
      if (!props.modalMode) {
        router.push({ name: route.name, hash: `#${props.hashRoute}` });
      }
    });

    return {
      enableFirst,
      enableNext,
      getEmail,
      getSubscribe,
      currentStep,
      showPrevious,
      prevAction,
      nextAction,
      nextButtonStates,
    };
  },
};
</script>
<template>
  <div
    class="w-full overflow-hidden"
    style="min-height: 460px"
  >
    <div class="flex flex-col overflow-auto space-y-4">
      <!-- Subcomponents -->
      <first
        v-if="currentStep == 0"
        modal-mode
        @email-ready="getEmail"
        @toggle-subscribe="getSubscribe"
        @email-duplicated="enableFirst = false"
        @email-unique="enableFirst = true"
      />
      <next
        v-if="currentStep == 1"
        @prev="prevAction"
        @verify-complete="enableNext = true"
      />
      <div class="flex space-x-4">
        <button
          v-if="showPrevious"
          class="text-base bg-parqay-primary hover:bg-blue-500 rounded-lg text-white px-4 py-2 w-1/2"
          @click="prevAction"
        >
          Previous
        </button>
        <div
          v-else
          class="w-1/2"
        />
        <button
          :disabled="!nextButtonStates().enabled"
          class="text-base rounded-lg text-white px-4 py-2 w-1/2"
          :class="nextButtonStates().colorClass"
          @click="nextAction"
        >
          {{ nextButtonStates().title }}
        </button>
      </div>
    </div>
  </div>
</template>