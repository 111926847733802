<script>
import {
    reactive,
    toRefs,
    onMounted,
    onBeforeUnmount
} from "@vue/composition-api";

export default {
    setup(props, ctx) {
        const controls = reactive({
            visible: {
                previewer: false
            },
            previewerFile: null
        });

        const previewMedia = f => {
            console.log("previewMedia -> ", f);
            controls.visible.previewer = true;
            controls.previewerFile = f;
        };

        const getPreviewerUrl = f => {
            if (["word", "excel", "ppt"].includes(f.type)) {
                return `https://view.officeapps.live.com/op/embed.aspx?src=${f.url}`
            }
            return f.url || f.file_url;
        }

        const detectFileType = (f) => {
            return f.type || "unknown";
        };

        onMounted(() => {
            ctx.root.$bus.$on("preview-attachment-media", previewMedia);
        })

        onBeforeUnmount(() => {
            ctx.root.$bus.$off("preview-attachment-media", previewMedia);
        })

        return {
            ...toRefs(controls),
            getPreviewerUrl,
            detectFileType
        };
    }
};
</script>
<template>
    <a-modal v-model="visible.previewer"
        :title="`Media Preview${previewerFile ? (' - ' + detectFileType(previewerFile).toUpperCase()) : ''}`" :width="800"
        :bodyStyle="{ padding: 0 }" :footer="null" @cancel="() => {
            visible.previewer = false;
            previewerFile = null;
        }">
        <iframe v-if="previewerFile" :src="getPreviewerUrl(previewerFile)"
            style="width: 100%; height: 90vh; border: none;" />
    </a-modal>
</template>
