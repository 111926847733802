<script>
import { ref, onMounted } from "@vue/composition-api";
import { shortUID } from "src/plugins/prototype";
import { uploadFileToS3 } from "src/views/components/util";
import TabHeader from "src/views/components/TabHeader.vue";
import { API } from "src/views/admin/api";

import startCase from "lodash/startCase";

export default {
    components: {
        TabHeader
    },
    setup(props, ctx) {
        const route = ctx.root.$route;
        const router = ctx.root.$router;
        const loading = ref(false);
        const editorVisible = ref(false);
        const editingMeta = ref(null);
        const editingMetaImagePreview = ref(null);
        const editingMetaImagePreviewVisible = ref(false);
        const SEOMeta = ref([]);
        const activeTab = ref("marketplace");
        const baseURLs = {
            marketplace: process.env.VUE_APP_MARKETPLACE_FRONTEND_BASE_URL,
            dashboard: process.env.VUE_APP_FRONTEND_BASE_URL
        }
        const getNewMeta = (type) => ({
            type,
            title: null,
            title_template: null,
            description: null,
            disable_app_banner: false,
            image: "https://parqay.com/cdn/img/social.png",
            pathname: null,
            $loading: false
        })
        const setupHashRoute = hashRoute => {
            router.push({ name: route.name, hash: `#${hashRoute}` });
        };
        const changeTab = async name => {
            activeTab.value = name;
            setupHashRoute(name);
            getSEOMeta();
        };

        const getSEOMeta = async () => {
            try {
                loading.value = true;
                const { data } = await API.getSEOMeta({ target: activeTab.value })
                SEOMeta.value = data.map(meta => ({
                    ...meta,
                    $loading: false
                }));
                loading.value = false;
            } catch (error) {
                loading.value = false;
                console.log(error);
            }
        }

        const handleFileChange = async ({ file }, meta) => {
            console.log("handleFileChange > file: ", file)
            if (file.status === "removed") {
                meta.image = null;
                return
            }

            meta.$loading = true;

            const s3_path = "SEO/" + shortUID() + "/" + file.name;
            const file_url = await uploadFileToS3({
                path: s3_path,
                isPublicAccess: true,
                fileBlob: file.originFileObj
            });
            console.log("uploadFileToS3 >> resp: ", file_url);

            meta.image = file_url;

            meta.$loading = false;
        }

        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => resolve(reader.result);

                reader.onerror = error => reject(error);
            });
        }

        const handlePreview = async (file) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }

            editingMetaImagePreview.value = file.url || file.preview;
            editingMetaImagePreviewVisible.value = true;
        };

        const createSEOMeta = async (meta) => {
            meta.$loading = true;
            try {
                await API.createSEOMeta(meta);
                ctx.root.$openNotification(
                    "SEO Index Submitted Successfully"
                )
                meta.$loading = false;
                editorVisible.value = false;
                getSEOMeta();
            } catch (error) {
                meta.$loading = false;
                ctx.root.$openNotification(
                    "SEO Index Submission Error",
                    error.message
                )
            }
        };

        const updateSEOMeta = async (meta) => {
            meta.$loading = true;
            try {
                await API.updateSEOMeta(meta);
                ctx.root.$openNotification(
                    "SEO Index Updated Successfully"
                )
                meta.$loading = false;
                editorVisible.value = false;
                getSEOMeta();
            } catch (error) {
                meta.$loading = false;
                ctx.root.$openNotification(
                    "SEO Index Update Error",
                    error.message
                )
            }
        }

        const deleteSEOMeta = async (meta) => {
            meta.$loading = true;
            try {
                await API.deleteSEOMeta(meta);
                ctx.root.$openNotification(
                    "SEO Index Removed Successfully"
                )
                meta.$loading = false;
                editorVisible.value = false;
                getSEOMeta();
            } catch (error) {
                meta.$loading = false;
                ctx.root.$openNotification(
                    "SEO Index Deletion Error",
                    error.message
                )
            }
        }

        onMounted(() => {
            getSEOMeta();
        })

        return {
            loading,
            editorVisible,
            editingMeta,
            editingMetaImagePreview,
            editingMetaImagePreviewVisible,
            SEOMeta,
            activeTab,
            baseURLs,
            changeTab,
            getSEOMeta,
            handleFileChange,
            handlePreview,
            getNewMeta,
            createSEOMeta,
            updateSEOMeta,
            deleteSEOMeta,
            startCase,
            console
        };
    }
};
</script>
<template>
    <div class="absolute inset-0">
        <div class="pt-10 h-full flex flex-col px-10">
            <div class="mb-6">
                <tab-header v-model="activeTab" :tabs="[
                    { name: 'marketplace', label: 'Marketplace', action: getSEOMeta },
                    { name: 'dashboard', label: 'Dashboard', action: getSEOMeta }
                ]" @change="changeTab">
                    <template #right-actions>
                        <a-button type="primary" @click="() => {
                            editingMeta = getNewMeta(activeTab);
                            editorVisible = true;
                        }">+ New index for {{ activeTab }}</a-button>
                    </template>
                </tab-header>
            </div>
            <div class="container mx-auto p-4 grid grid-cols-2 gap-4">
                <div v-if="loading" class="col-span-2 flex items-center justify-center h-64">
                    <a-icon type="loading" style="font-size: 35px;" />
                </div>
                <template v-else>
                    <div v-for="(meta, i) in SEOMeta" :key="`meta-${i}`">
                        <div class="mb-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
                            <div class="p-6">
                                <div class="flex items-center space-x-2">
                                    <span class="text-base italic pb-1">Path:</span>
                                    <a-tag color="cyan">{{ meta.pathname }}</a-tag>
                                </div>
                                <div class="flex flex-col space-y-1 mb-4">
                                    <h2 class="text-xl font-semibold text-gray-900">{{ meta.title }}</h2>
                                    <span class="text-base italic">{{ meta.description }}</span>
                                </div>
                                <div class="flex items-start justify-between space-x-4">
                                    <div class="flex flex-col space-y-3">
                                        <div class="flex items-center space-x-2">
                                            <strong class="mr-2">Title template:</strong>
                                            <span class="text-parqay-primary">{{ meta.title_template }}</span>
                                        </div>
                                        <div class="flex items-center space-x-2">
                                            <strong class="mr-2">Display app banner:</strong>
                                            <a-tag color="geekblue">Mobile</a-tag>
                                            <a-checkbox :checked="!meta.disable_app_banner" />
                                        </div>
                                        <div v-if="$deepGet(meta, 'created_at.$date')"
                                            class="flex items-center space-x-2 italic">
                                            <span>
                                                Created At:
                                            </span>
                                            <span>
                                                {{ $formatDate(meta.created_at.$date, "MM/DD/YY, h:mm a") }}
                                            </span>
                                        </div>
                                        <div v-if="$deepGet(meta, 'updated_at.$date')"
                                            class="flex items-center space-x-2 italic">
                                            <span>
                                                Updated At:
                                            </span>
                                            <span>
                                                {{ $formatDate(meta.updated_at.$date, "MM/DD/YY, h:mm a") }}
                                            </span>
                                        </div>
                                    </div>
                                    <img v-if="meta.image" :src="meta.image"
                                        class="flex-shrink-0 object-cover w-24 h-20 bg-gray-100">
                                    <a-tag v-else color="pink">No SEO Image</a-tag>
                                </div>
                            </div>
                            <div class="cursor-pointer flex items-center justify-center py-3 space-x-2 border-t hover:bg-gray-50"
                                @click="() => {
                                    editingMeta = meta;
                                    editorVisible = true;
                                }">
                                <a-icon type="edit" />
                                <span class="text-parqay-primary hover:opacity-75 text-sm font-medium">Edit</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <a-modal v-model="editorVisible" title="SEO Editor" width="600px" :footer="null" destroy-on-close>
            <div v-if="editingMeta"
                class="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden">
                <div class="space-y-2">
                    <label-item label="Path">
                        <a-input v-model="editingMeta.pathname" :addon-before="baseURLs[activeTab]" size="large" />
                    </label-item>
                    <label-item label="Title" desc="SEO title that shows in search result">
                        <a-input v-model="editingMeta.title" size="large" />
                    </label-item>
                    <label-item label="Description" desc="SEO description that shows in search result">
                        <a-input v-model="editingMeta.description" size="large" />
                    </label-item>
                    <label-item label="Title Template"
                        desc="Title template to generate full title, use %s to represent title content above. e.g.: '%s | Parqay Dashboard'">
                        <a-input v-model="editingMeta.title_template" size="large" />
                    </label-item>
                    <label-item label="Image" desc="SEO image that shows in search result">
                        <a-upload :file-list="editingMeta.image ? [
                            {
                                uid: 'seo-image',
                                name: 'seo-image',
                                status: 'done',
                                url: editingMeta.image
                            }
                        ] : []" list-type="picture-card" :custom-request="() => { }" @preview="handlePreview"
                            @change="(info) => handleFileChange(info, editingMeta)">
                            <div v-if="editingMeta.image">
                                <a-icon :type="editingMeta.$loading ? 'loading' : 'edit'"
                                    :theme="editingMeta.$loading ? 'outlined' : 'twoTone'" />
                                <div style="margin-top: 8px">Update</div>
                            </div>
                            <div v-else>
                                <a-icon :type="editingMeta.$loading ? 'loading' : 'plus-circle'"
                                    :theme="editingMeta.$loading ? 'outlined' : 'twoTone'" />
                                <div style="margin-top: 8px">Upload</div>
                            </div>
                        </a-upload>
                    </label-item>
                    <label-item label="App Banner" desc="The app banner on top edge of mobile browser">
                        <div class="flex items-center space-x-2">
                            <a-checkbox :default-checked="!editingMeta.disable_app_banner" @change="({ target: { checked } }) => {
                                editingMeta.disable_app_banner = !checked;
                            }">
                                Display app banner
                            </a-checkbox>
                        </div>
                    </label-item>
                </div>
            </div>
            <template v-if="editingMeta">
                <div v-if="editingMeta.id" class="flex items-center pt-5 space-x-4">
                    <a-button type="primary" :loading="editingMeta.$loading" size="large" block
                        @click="updateSEOMeta(editingMeta)">Update</a-button>
                    <a-popconfirm
                        title="Do you confirm to delete this index? This action will also remove corresponding Google Index from search results."
                        @confirm="() => {
                            deleteSEOMeta(editingMeta);
                        }">
                        <a-button type="danger" :loading="editingMeta.$loading" size="large">Delete</a-button>
                    </a-popconfirm>
                </div>
                <div v-else class="flex items-center pt-5 space-x-4">
                    <a-button type="primary" :loading="editingMeta.$loading" size="large" block
                        @click="createSEOMeta(editingMeta)">Create Index</a-button>
                </div>
            </template>
        </a-modal>
        <a-modal :visible="editingMetaImagePreviewVisible" :footer="null" @cancel="() => {
            editingMetaImagePreviewVisible = false;
        }">
            <img style="width: 100%" :src="editingMetaImagePreview" />
        </a-modal>
    </div>
</template>
