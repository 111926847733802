<script>
import { onMounted, reactive } from "@vue/composition-api";
import PendingTransactionsTable from "../../common/PendingTransactionsTable.vue";

export default {
    components: { PendingTransactionsTable },
    setup(props, ctx) {
        const controls = reactive({
            loading: false,
            visible: {
                pending: false
            },
            balance: 0,
            transactions: []
        });
        const updateBalance = (data) => {
            controls.balance = data.pending + data.balance;
            controls.transactions = data.transactions;
        };
        onMounted(() => {
            if (ctx.root.$route.hash === "#pay-now") {
                controls.visible.pending = true;
            }
        });
        return {
            controls,
            updateBalance
        };
    }
}
</script>
<template>
    <div class="flex flex-col bg-white border-b lg:border rounded-lg px-6 py-6 space-y-2 cursor-pointer"
        @click="() => (controls.visible.pending = true)">
        <div class="flex justify-between">
            <div class="flex flex-col space-y-2">
                <span class="font-semibold text-black text-base">Balance Due</span>
                <span class="font-bold text-blue-600 text-3xl">{{ controls.loading ? "-" :
                $formatCurrency(controls.balance) }}</span>
            </div>
            <div class="flex items-center space-x-2">
                <a-button v-if="$deepGet(controls, 'transactions', []).length > 0 && !controls.visible.pending"
                    type="primary" @click.stop="() => (controls.visible.pending = true)">Pay Now ({{$deepGet(controls,
                    'transactions', []).length}})</a-button>
                <a-button v-else type="primary" @click.stop="() => (controls.visible.pending = false)">Hide</a-button>
                <a-button @click.stop="$router.push('/tenant/settings#payment-methods')">Payment Methods</a-button>
            </div>
        </div>
        <div v-show="controls.visible.pending" class="flex flex-col overflow-y-auto">
            <a-divider>Pending Payments</a-divider>
            <pending-transactions-table @loading-start="() => controls.loading = true"
                @loading-stop="() => controls.loading = false" @update-balance="updateBalance" />
        </div>
    </div>
</template>