<script>
import { reactive } from "@vue/composition-api";

import AttachmentList from "./SMMSMediaAttachmentList.vue";

export default {
  name: "MessagingOutboundBubble",
  components: { AttachmentList },
  props: {
    senderInitial: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    media: {
      type: Array,
      default: () => []
    },
    dt: {
      type: String,
      required: true,
    },
    isQuoted: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
    },
    source: {
      type: String,
      required: true
    }
  },
  setup(props, ctx) {
    const controls = reactive({
      click: false,
      hover: false,
      editingMessage: null,
      doubleCheckDelete: false,
    });
    const clickBubble = () => {
      controls.click = true;
      ctx.root.$copyToClipboard(props.message);
      setTimeout(() => {
        controls.click = false;
      }, 1000);
    }
    return {
      controls,
      clickBubble
    };
  },
};
</script>
<template>
  <div class="grid grid-cols-12 relative hover:bg-gray-100 cursor-pointer"
    :class="{ 'bg-white hover:bg-blue-50 shadow-md border rounded-md px-1 py-2': isQuoted }"
    @mouseover="() => (controls.hover = true)" @mouseleave="() => {
      controls.hover = false;
      controls.doubleCheckDelete = false;
    }
      ">
    <div class="flex flex-col space-y-2 px-3 pb-1 rounded-lg"
      :class="isQuoted ? 'col-start-1 col-end-13 py-1' : 'col-start-1 col-end-13 pt-2'">
      <div v-if="isQuoted" class="flex items-center justify-between">
        <span class="text-base font-medium text-gray-600 italic">You Quote:</span>
        <button class="focus:outline-none" @click="$emit('remove-quote')">
          <svg-icon icon-class="close" class="w-5 h-5 text-parqay-primary hover:text-parqay-primary" />
        </button>
      </div>
      <div class="flex items-center justify-start flex-row-reverse">
        <div class="flex-none items-center justify-center h-10 w-10 rounded-full bg-blue-500 flex-shrink-0 relative">
          <span class="text-center text-lg text-white absolute left-0 right-0 top-0 bottom-0 m-auto h-1/2"
            style="line-height: initial">{{
              senderInitial
            }}</span>
        </div>
        <div class="flex mr-3 text-sm py-2 px-4 shadow rounded-xl bg-parqay-primary text-white"
          :class="{ 'w-full': isQuoted }">
          <a-tooltip v-if="controls.editingMessage == null" :visible="controls.click" title="Copied!"
            @click.stop="clickBubble()">
            <p style="margin:0px;">
              <a-tag v-if="source === 'smms'" color="orange" style="font-size:0.65rem; font-weight: 600;">SMS /
                MMS</a-tag>
              <span v-html="$formatLongTextIfNeeded(message).replace(/\n/g, '<br>')"></span>
            </p>
          </a-tooltip>
          <a-textarea v-else v-model="controls.editingMessage" />
        </div>
      </div>
      <div v-if="quote"
        class="rounded-lg bg-gray-200 hover:bg-blue-200 text-gray-500 hover:text-blue-500 font-medium px-4 py-2 cursor-pointer"
        @click="$emit('scroll-to-original-message', quote.id)">
        <span>{{ quote.sender_name }}:<br><span v-html="$formatLongTextIfNeeded(quote.message)"></span></span>
      </div>
      <div v-else-if="media.length > 0">
        <a-divider>
          <span class="text-xs text-center">
            Attachments ({{ media.length }})
          </span>
        </a-divider>
        <attachment-list :media="media" :isSMMS="source === 'smms'" />
      </div>
    </div>
    <div class="col-span-12 px-3 pb-2">
      <div class="flex items-center justify-between w-full">
        <div class="w-1/3" />
        <span class="w-1/3 text-center text-gray-500 text-xs font-light pt-2 pl-1 whitespace-nowrap">{{
          $humanizeDate($loadBackendDate(dt))[0] }}</span>
        <span class="w-1/3 text-right text-gray-500 text-xs font-bold pt-2 pl-1 whitespace-nowrap">{{
          $humanizeDate($loadBackendDate(dt))[1] }}</span>
      </div>
    </div>
    <div v-if="controls.hover && !isQuoted" class="absolute left-2" :style="{ top: '-0.5rem' }">
      <div v-if="controls.editingMessage == null" class="flex divide-x border rounded bg-white h-6">
        <template v-if="!controls.doubleCheckDelete">
          <button v-if="source !== 'smms'"
            class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => (controls.editingMessage = message)">
            Edit
          </button>
          <button
            class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => $emit('quote')">
            Quote
          </button>
          <button
            class="text-xs text-pink-500 hover:text-pink-600 hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => (controls.doubleCheckDelete = true)">
            Delete
          </button>
        </template>
        <template v-else>
          <button
            class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => (controls.doubleCheckDelete = false)">
            Cancel
          </button>
          <button
            class="text-xs text-pink-500 hover:text-pink-600 hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
            @click="() => $emit('delete')">
            Delete
          </button>
        </template>
      </div>
      <div v-else class="flex divide-x border rounded bg-white h-6">
        <button
          class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
          @click="() => (controls.editingMessage = null)">
          Cancel
        </button>
        <button
          class="text-xs text-gray-500 hover:text-parqay-primary hover:bg-gray-50 font-medium px-2 py-1 focus:outline-none"
          @click="() => {
            $emit('edit', controls.editingMessage);
            controls.editingMessage = null;
          }
            ">
          Save
        </button>
      </div>
    </div>
  </div>
</template>