<script>
import { ref, computed, onMounted, watch } from "@vue/composition-api";
import { required, email } from "vuelidate/lib/validators";
import debounce from "lodash/debounce";
// APIs

import { API } from "src/views/auth/api";
export default {
  name: "SignupStep2First",
  props: {
    modalMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const email = ref(null);
    const emailValid = ref(false);
    const emailValidMessage = ref(null);
    const highlightError = ref(false);
    const checkboxes = ref({
      email_news_letter: false
    });
    const title = ref(
      computed(() => {
        if (props.modalMode) {
          return "Enter new email address to verify";
        } else {
          return "You should login using only one of the following ways";
        }
      })
    );
    watch(
      () => email.value,
      val => {
        ctx.emit("email-ready", val);
      }
    );
    watch(
      () => emailValid.value,
      val => {
        if (val) {
          ctx.emit("email-unique");
        } else {
          ctx.emit("email-duplicated");
        }
      }
    );
    watch(
      () => checkboxes.value.email_news_letter,
      val => {
        console.log("checkboxes.value.email_news_letter");
        ctx.emit("toggle-subscribe", "email_news_letter", val);
      }
    );
    const loadCachedPayloadFromStore = () => {
      const signUpForm = ctx.root.$store.state.signUpForm;
      if (signUpForm.email) {
        email.value = signUpForm.email;
      }
      if (signUpForm.subscribe) {
        if (signUpForm.subscribe.includes("email_news_letter")) {
          checkboxes.value.email_news_letter = true;
        }
      }
    };
    const checkEmailDuplication = debounce(async ($event, $v) => {
      if ($v.email.email) {
        API.checkEmailDuplication($event.target.value)
          .then(res => {
            console.log("res: " + res.message);
            emailValidMessage.value = res.message;
            emailValid.value = true;
          })
          .catch(error => {
            console.log("error: " + error.message);
            emailValidMessage.value = error.message;
            emailValid.value = false;
          });
      }
    }, 300);

    const checkShouldHighlightErrorState = $v => {
      highlightError.value = !$v.email.email || !$v.email.required;
    };

    const getErrorMessage = () => {
      if (!emailValid.value) {
        return emailValidMessage.value;
      }
      if (highlightError.value) {
        return "A valid email address is required";
      }
    };
    onMounted(() => {
      loadCachedPayloadFromStore();
    });
    return {
      title,
      email,
      emailValid,
      checkboxes,
      highlightError,
      emailValidMessage,
      checkShouldHighlightErrorState,
      checkEmailDuplication,
      getErrorMessage
    };
  },
  validations: {
    email: {
      required,
      email
    }
  }
};
</script>
<template>
  <div>
    <span class="text-base font-medium text-center">{{ title }}</span>
    <div class="rounded-2xl shadow-sm border bg-white hover:border-blue-300 cursor-pointer" style="min-height: 321px">
      <template v-if="!modalMode">
        <div class="flex flex-col py-6 pb-2 px-6 space-y-4">
          <div class="mt-1 grid grid-cols-3 gap-3">
            <div>
              <a href="#"
                class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with Facebook</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </div>

            <div>
              <a href="#"
                class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with Twitter</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path
                    d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>

            <div>
              <a href="#"
                class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with GitHub</span>
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>

          <span class="text-gray-400 text-center">You will be prompt to enter extra information after logged in with
            Oauth</span>
        </div>

        <div class="mt-2 relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-white text-medium font-medium text-parqay-primary">
              Or
            </span>
          </div>
        </div>
      </template>
      <div class="flex flex-col px-6 pt-2">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700" :class="{ 'mt-4': modalMode }">Email
            Address</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input id="email" v-model="email" type="text" name="email"
              class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md" :class="
                $v.email.$invalid
                  ? 'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 placeholder-red-300'
                  : 'border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-300'
              " placeholder="email@example.com" aria-invalid="true" aria-describedby="email-error"
              @input="checkEmailDuplication($event, $v)" @focus="() => (highlightError = null)"
              @blur="checkShouldHighlightErrorState($v)" />
            <div v-if="$v.email.$invalid || !emailValid"
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg-icon icon-class="solid-exclamation-circle" class="h-5 w-5 text-red-500" />
            </div>
            <div v-if="!$v.email.$invalid && emailValid"
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg-icon icon-class="solid-check" class="h-5 w-5 text-green-500" />
            </div>
          </div>
          <p v-if="$v.email.$invalid || !emailValid" id="email-error" class="mt-2 text-sm text-red-500 text-center">
            {{ getErrorMessage() }}
          </p>
          <p v-if="!$v.email.$invalid && emailValid" class="mt-2 text-sm text-gray-500 text-center">
            {{ emailValidMessage }}
          </p>
        </div>
        <div class="flex space-x-2 pb-4" :class="{ 'mt-4': !highlightError }">
          <div class="inline-flex">
            <a-checkbox v-model="checkboxes.email_news_letter">
              Subscribe to Parqay newsletter to receive tips, host stories,
              popular locations & events to increase your hosting income
            </a-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
