<script>
import { ref, watch, onMounted } from "@vue/composition-api";
import ClickOutside from "vue-click-outside";
import debounce from "lodash/debounce";

import { API } from "./api/account";

export default {
    directives: {
        ClickOutside
    },
    props: {
        role: {
            type: String,
            default: "host"
        }
    },
    setup(props, ctx) {
        const loading = ref(false);
        const keyword = ref('');
        const hosts = ref([]);
        const selectedHost = ref(null);
        const showDropdown = ref(false);

        watch(selectedHost, (newValue) => {
            if (newValue) {
                ctx.emit("select", newValue.id);
                ctx.emit("host-update", newValue);
                ctx.emit("error", null);
                keyword.value = "";
            } else {
                ctx.emit("select", null);
                ctx.emit("host-update", {});
                ctx.emit("error", "A host MUST be selected.");
            }
        })

        const handleInput = debounce(async (event) => {
            try {
                loading.value = true;
                if (event) {
                    keyword.value = event.target.value;
                }
                const { data } = await API.searchUser(props.role, keyword.value);
                hosts.value = data;
                loading.value = false;
            } catch (error) {
                loading.value = false;
            }
        }, 500, { trailing: true });

        const handleFocus = () => {
            showDropdown.value = true;
            ctx.emit("error", "A host MUST be selected.");
        };

        const handleBlur = () => {
            if (showDropdown.value) {
                showDropdown.value = false;
            }
        }

        onMounted(() => {
            handleInput();
        })

        return {
            loading,
            keyword,
            hosts,
            selectedHost,
            showDropdown,
            handleInput,
            handleFocus,
            handleBlur
        };
    }
};
</script>
<template>
    <div class="flex flex-col space-y-2 relative" v-click-outside="handleBlur">
        <div v-if="selectedHost">
            <a-tag host color="cyan">
                <span class="text-black font-medium">
                    {{
                        selectedHost.first_name || ""
                    }}
                </span>
                <span class="text-black font-medium">
                    {{
                        selectedHost.last_name || ""
                    }}
                </span>
                <div @click.stop="() => selectedHost = null">
                    <svg-icon icon-class="close" class="w-4 h-4 text-gray-400 hover:text-black" />
                </div>
            </a-tag>
        </div>
        <template v-else>
            <a-input-search v-model="keyword" :loading="loading" @input="handleInput" @focus="handleFocus"
                placeholder="Search hosts" size="large" allow-clear />
            <ul v-if="showDropdown"
                class="absolute z-30 top-full left-0 w-full bg-white rounded shadow-md border-l border-b border-r">
                <li v-if="hosts.length == 0"
                    class="flex items-center justify-center px-4 py-3 border-t hover:bg-gray-100 cursor-pointer">
                    No Result.
                </li>
                <li v-for="(host, i) in hosts" :key="`host-${i}`"
                    class="flex items-start justify-between px-4 py-3 border-t hover:bg-gray-100 cursor-pointer"
                    @click.stop="() => selectedHost = host">
                    <div class="flex items-center space-x-2">
                        <span class="text-black font-medium">
                            {{
                                host.first_name || ""
                            }}
                        </span>
                        <span class="text-black font-medium">
                            {{
                                host.last_name || ""
                            }}
                        </span>
                        <template v-for="(role, j) in host.roles">
                            <a-tag v-if="role === 'host'" :key="`role-${j}`" color="cyan">{{ role }}</a-tag>
                            <a-tag v-if="role === 'tenant'" :key="`role-${j}`" color="orange">{{ role }}</a-tag>
                        </template>
                    </div>
                    <div class="flex flex-col justify-center items-end space-y-2">
                        <span v-if="host.email" class="text-xs font-semibold text-gray-400">{{ host.email }}</span>
                        <span v-if="host.phone_number" class="text-xs font-semibold text-parqay-primary">{{
                            host.phone_number }}</span>
                    </div>
                </li>
            </ul>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.ant-tag[host] {
    @apply inline-flex items-center text-sm font-medium px-3 py-1.5 space-x-2 cursor-pointer;
}
</style>