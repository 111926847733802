<script>
import MobileReservations from "./mobile/View.vue";

import TabHeader from "src/views/components/TabHeader";
import { ref, reactive, onMounted } from "@vue/composition-api";
import StatusTag from "src/views/reservations/components/StatusTag.vue";
import ListingAvatar from "src/views/components/ListingImageAvatar.vue";

import { API } from "src/views/non-host/reservations/api";
import startCase from "lodash/startCase";

export default {
  name: "TenantReservations",
  components: {
    TabHeader,
    StatusTag,
    ListingAvatar,
    MobileReservations
  },
  setup() {

    const controls = reactive({
      loading: false
    });
    const pagination = reactive({
      currentPage: 1,
      perPage: 10,
      total: 0
    })
    const activedTab = ref("All");
    const slideBarVisible = ref(false);

    const tenantTable = reactive({
      headers: [
        {
          title: "Listing",
          align: "center",
          dataIndex: "title",
          scopedSlots: { customRender: "listing" },
        },
        {
          title: "Host",
          align: "center",
          dataIndex: "host_name",
          scopedSlots: { customRender: "host_name" },
        },
        {
          title: "Status",
          align: "center",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Price",
          align: "center",
          dataIndex: "tenant_total",
          scopedSlots: { customRender: "tenant_total" },
        },
        {
          title: "Type",
          align: "center",
          dataIndex: "listing.type",
          scopedSlots: { customRender: "reservation-type" },
        },
        {
          title: "Booking Intervals",
          align: "center",
          dataIndex: "durations",
          scopedSlots: { customRender: "durations" },
        },
        {
          title: "Location",
          align: "center",
          dataIndex: "listing.name",
          scopedSlots: { customRender: "location" },
        },
        {
          title: "Confirmation#",
          align: "center",
          dataIndex: "confirmation_id",
          scopedSlots: { customRender: "confirmation" },
        },
        {
          title: "Contact",
          align: "center",
          dataIndex: "contact_id",
          scopedSlots: { customRender: "contact" },
          fixed: "right",
        },
      ],
      items: [],
    });

    // const getReviewDetails = async (reservationId) => {
    //   ctx.root.$toggleLoadingIndicator(true);
    //   let resp;
    //   try {
    //     resp = await API.details(reservationId);
    //   } catch (error) {
    //     console.log("getReviewDetails >> error: ", error);
    //     ctx.root.$toggleLoadingIndicator(false);
    //     return;
    //   }
    //   ctx.root.$toggleLoadingIndicator(false);
    //   ctx.root.$openListingDetailsDrawer(resp.data.review);
    // };

    const getReservations = async () => {
      controls.loading = true;
      try {
        const { data, pagination: pag } = await API.list({
          tab: activedTab.value,
          page: pagination.currentPage
        });

        tenantTable.items = data;

        pagination.currentPage = pag.page;
        pagination.perPage = pag.per_page;
        pagination.total = pag.total;

      } catch (error) {
        console.log("getReservations > error: ", error);
      }
      controls.loading = false;
    };

    const filterReservations = () => {
      pagination.currentPage = 1;
      getReservations();
    };

    const handlePageChange = (page) => {
      pagination.currentPage = page;
      getReservations();
    }

    onMounted(() => {
      getReservations();
    });

    return {
      controls,
      pagination,
      tenantTable,
      activedTab,
      slideBarVisible,
      filterReservations,
      handlePageChange,
      startCase,
      console,
    };
  },
};
</script>
<template>
  <div class="absolute inset-0">
    <div v-if="$isDesktopView()" class="tenant-reservation py-6 flex flex-col">
      <div class="border-b flex justify-between items-center px-10 py-2">
        <h3 class="text-dark-purple font-medium text-2xl my-auto">
          Reservation
        </h3>
      </div>

      <div class="px-10 py-6 flex flex-col">
        <div>
          <tab-header v-model="activedTab" :tabs="[
      { name: 'All', label: 'All' },
      { name: 'Upcoming', label: 'Upcoming' },
      { name: 'Past', label: 'Past' },
    ]" disableHashDetect @change="filterReservations()" />
        </div>
        <div class="mt-6">
          <div class="shadow-sm border overflow-hidden rounded-lg max-w bg-white">
            <div class="flex items-center justify-between px-4 py-4 border-b">
              <span class="font-medium text-lg text-dark-purple">Current & Upcoming</span>
            </div>
            <a-table :scroll="{ x: true, y: false }" :loading="controls.loading" :columns="tenantTable.headers"
              :data-source="tenantTable.items" row-key="id" :custom-row="(record, index) => {
      return {
        on: {
          click: (event) => {
            $router.push(`/tenant/reservations/${record.id}`)
          },
        },
      };
    }
      " :pagination="false">

              <template slot="listing" slot-scope="title, record">
                <div class="flex items-center space-x-3">
                  <listing-avatar width="50" height="50" rounded="sm" :listing-id="record.listing.id" />
                  <div class="flex flex-col items-center justify-center space-y-2">
                    <a-tag>{{ title ? title : $deepGet(record, "listing.name") }}</a-tag>
                    <span v-if="$deepGet(record, 'event')" class="text-xs ant-tag-geekblue border rounded-md px-3 py-1"
                      style="max-width: 160px;">
                      {{
      record.event
    }}
                    </span>
                  </div>
                </div>
              </template>

              <template slot="host_name" slot-scope="host_name, record">
                <div class="flex items-center justify-center">
                  <avatar :user-id="record.user" />
                </div>
              </template>

              <template slot="durations" slot-scope="durations">
                <div class="flex flex-col space-y-2">
                  <div v-for="(duration, i) in durations" :key="`reservation-duration-${i}`"
                    class="flex space-x-2 items-center">
                    <div class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                      {{ $parseBackendDate(duration.from, "MMM DD, h:mm a") }}
                    </div>
                    <svg-icon icon-class="arrow-circle-right" class="text-parqay-primary w-6 h-6" />
                    <div class="text-center text-xs font-medium border rounded bg-gray-100 px-2 py-1 whitespace-nowrap">
                      {{ $parseBackendDate(duration.to, "MMM DD, h:mm a") }}
                    </div>
                  </div>
                </div>
              </template>

              <template slot="reservation-type" slot-scope="type, record">
                <div class="flex flex-col space-y-2">
                  <a-tag color="geekblue">
                    {{ startCase(type) }}
                  </a-tag>
                  <a-tag v-if="$deepGet(record, 'event')" color="geekblue">Event</a-tag>
                </div>
              </template>

              <template slot="status" slot-scope="status, record">
                <div class="flex items-center justify-center">
                  <status-tag :status="status">
                    <template v-if="record.request_message || record.cancel_reason || record.deny_message" #action>
                      <div class="py-1">
                        <a-button type="primary" size="small"
                          style="font-size:10px; padding: 4px; height: 18px; line-height: 9px;"
                          @click.stop="() => $set(record, '$showRequestMessage', true)">message</a-button>
                      </div>
                      <a-modal :visible="record.$showRequestMessage" title="Request Message" :footer="null"
                        @cancel="() => record.$showRequestMessage = false">
                        <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
                          <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
                            <avatar :user-id="record.customer" show-name relative-role="tenant" />
                          </div>
                          <div class="p-4 relative">
                            <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                              <span class="text-gray-900 leading-tight">{{ record.request_message }}</span>
                            </div>
                            <div
                              class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                            </div>
                          </div>
                        </div>
                        <template v-if="record.cancel_reason">
                          <a-divider style="color: orange;">Cancel Reason</a-divider>
                          <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
                            <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
                              <avatar :user-id="record.user" show-name />
                            </div>
                            <div class="p-4 relative">
                              <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                                <span class="text-gray-900 leading-tight">{{ record.cancel_reason }}</span>
                              </div>
                              <div
                                class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="record.deny_message">
                          <a-divider style="color: red;">Denied Reason</a-divider>
                          <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md mx-auto">
                            <div class="bg-gray-100 px-4 py-3 flex items-center space-x-2">
                              <avatar :user-id="record.user" show-name />
                            </div>
                            <div class="p-4 relative">
                              <div class="bg-gray-100 rounded-lg py-2 px-3 mb-1 inline-block">
                                <span class="text-gray-900 leading-tight">{{ record.deny_message }}</span>
                              </div>
                              <div
                                class="absolute right-0 bottom-0 border-t-8 border-gray-100 border-solid transform rotate-45 -mr-4">
                              </div>
                            </div>
                          </div>
                        </template>
                      </a-modal>
                    </template>
                  </status-tag>
                </div>
              </template>

              <template slot="confirmation" slot-scope="confirmation_id, record">
                <a-tooltip placement="top">
                  <template v-if="!record.showFullConfirmationNumber" slot="title">
                    <span>Click to copy</span>
                  </template>
                  <span
                    class="text-parqay-primary text-xs px-2 py-1 border border-parqay-primary rounded-full cursor-pointer hover:shadow-lg"
                    @click.stop="() => {
      if (!('showFullConfirmationNumber' in record)) {
        $set(record, 'showFullConfirmationNumber', false);
      }
      if (!record.showFullConfirmationNumber) {
        $copyToClipboard(confirmation_id);
        $openNotification(
          'Confirmation number copied to clipboard',
          '',
          5,
          'info'
        );
      }
      record.showFullConfirmationNumber = !record.showFullConfirmationNumber;
    }
      ">{{
      confirmation_id.substring(0, 6)
    }}</span>
                </a-tooltip>
              </template>

              <template slot="tenant_total" slot-scope="tenant_total">
                <span class="text-black text-xs font-medium">{{ $formatCurrency(tenant_total) }}</span>
              </template>

              <template slot="location" slot-scope="location, record">
                <div class="flex flex-col items-center justify-center space-y-2">
                  <a class="text-parqay-primary font-medium text-xs">
                    {{ record.listing.location ? $deepGet(record, "listing.location.address", location) : location }}
                  </a>
                </div>
              </template>

              <template slot="contact" slot-scope="contact_id, record">
                <div @click.stop="$openContactSlideCard(record)">
                  <a-badge :count="$store.getters.getUnreadMessagesCountForReservation(record)">
                    <svg-icon icon-class="chat"
                      class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
                  </a-badge>
                </div>
              </template>

              <template slot="doc" slot-scope="docs">
                <div class="pointer-events-auto" @click.stop="$openDocSlideCard('tenant')">
                  <svg-icon icon-class="document"
                    class="h-6 w-6 text-gray-600 cursor-pointer hover:text-parqay-primary" />
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center py-4">
        <a-pagination :current="pagination.currentPage" :total="pagination.total" :page-size="pagination.perPage"
          @change="handlePageChange" />
      </div>
    </div>
    <mobile-reservations v-else />
  </div>
</template>

<style lang="scss" scoped>
.tenant-reservation::v-deep {
  .ant-table-row {
    @apply cursor-pointer;
  }

  td {
    @apply py-2;
  }
}
</style>./mobile/View.vue
