<script>
import { ref, onMounted, watch } from "@vue/composition-api";

import AttendantHeader from "src/views/team/mobile/attendant/Header.vue";

export default {
    components: {
        AttendantHeader
    },
    setup(props, ctx) {
        const windowHeight = ref(window.innerHeight);
        const transitionName = ref("fade");

        watch(
            () => ctx.root.$route,
            (to, from) => {
                console.log("route changed: from: " + from.path + " to: " + to.path);
                transitionName.value = "fade";
            }
        );
        onMounted(() => { });
        return {
            windowHeight,
            transitionName,
        };
    },
};
</script>
<template>
    <div class="flex flex-col h-screen bg-white overflow-y-auto md:overflow-hidden">
        <attendant-header />
        <div class="flex flex-col mt-16">
            <transition :name="transitionName">
                <router-view />
            </transition>
        </div>
    </div>
</template>
