<script>
import { reactive, ref, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";
import PendingTransactionsTable from "../../common/PendingTransactionsTable.vue";
import PaymentTable from "../components/Table.vue";
import { API } from "../api";
import startCase from "lodash/startCase";

export default {
  name: "reservations-list",
  components: {
    PendingTransactionsTable,
    PaymentTable
  },
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      tab: "pending",
      balance: 0,
      transactions: [],
      key: 0
    });

    const reservations = ref([]);

    watch(() => controls.tab, tab => {
      updateBalance();
      fetchData();
    })

    const fetchData = async () => {
      controls.key += 1;
    };

    const updateBalance = async () => {
      const { data: { balance, pending, transactions, } } = await API.balanceSummary();
      controls.balance = pending + balance;
      controls.transactions = transactions;
    };

    onMounted(() => {
      updateBalance();
    });

    onBeforeUnmount(() => {
    })

    return {
      controls,
      reservations,
      fetchData,
      updateBalance,
      startCase,
      window,
      console
    };
  },
};
</script>
<template>
  <div class="tenant-reservations absolute inset-0 bg-gray-200">
    <!-- Note: h-full is important to activate flex grow! -->
    <div class="flex flex-col h-full">
      <div class="py-3 flex-shrink-0 flex flex-col bg-white border-b">
        <div class="relative flex justify-center items-center px-10 pb-3 border-b">
          <div class="flex items-center space-x-2 text-black font-medium text-lg">
            <a-icon type="retweet" theme="twoTone" />
            <span>Payments</span>
          </div>
          <a-button type="link" :icon="controls.loading ? 'loading' : 'reload'" class="absolute inset-y-0 pb-1"
            :style="{ position: 'absolute', right: '16px' }" @click="fetchData()"></a-button>
        </div>
        <div class="flex flex-col bg-white border-b lg:border rounded-lg px-6 py-4">
          <div class="flex items-center justify-between">
            <div class="flex flex-col items-start">
              <span class="text-black text-sm font-light">Balance Due</span>
              <span class="font-bold text-blue-600 text-3xl">{{ $formatCurrency(controls.balance) }}</span>
            </div>
            <a-button type="primary" @click="$router.push('/tenant/settings#payment-methods')">Payment
              Methods</a-button>
          </div>
        </div>
        <div class="flex justify-center text-base text-gray-600 font-normal pt-2 space-x-6">
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'pending' }"
            @click="() => (controls.tab = 'pending')">
            Pending
          </a>
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'past' }"
            @click="() => (controls.tab = 'past')">
            Past
          </a>
        </div>
      </div>
      <div class="flex-grow overflow-y-auto w-screen mx-auto">
        <!-- <div v-if="controls.loading" class="flex items-center justify-center"
          :style="{ height: `${window.innerHeight / 2}px` }">
          <a-button size="large" loading></a-button>
        </div> -->
        <pending-transactions-table v-if="controls.tab === 'pending'" :key="`pending-transactions-${controls.key}`"
          class="bg-white" @loading-start="() => controls.loading = true" @loading-stop="() => controls.loading = false" />
        <payment-table v-if="controls.tab === 'past'" :key="`past-payments-${controls.key}`"
          @loading-start="() => controls.loading = true" @loading-stop="() => controls.loading = false" />
        <!-- <template v-else>
          <div v-if="reservations.length == 0" class="flex flex-col items-center justify-center"
            :style="{ height: `${window.innerHeight / 2}px` }">
            <span class="text-lg text-gray-500">No reservation</span>
          </div>
          <div v-else class="px-6 pt-5 space-y-5">
            
          </div>
        </template> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tenant-reservations::v-deep {
  .ant-btn {
    @apply justify-center;
  }

  .ant-table-row {
    @apply cursor-pointer;
  }

  td {
    @apply py-2;
  }

  .ant-divider-inner-text {
    @apply text-xs text-gray-600 font-light;
  }
}
</style>
