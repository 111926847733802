<script>
import { onMounted, ref } from "@vue/composition-api";
export default {
  name: "org-login",
  setup(props, ctx) {
    const urlSlug = ref(null);
    onMounted(() => { });
    return {
      urlSlug
    };
  }
};
</script>
<template>
  <div class="w-screen h-screen flex flex-col items-center justify-center space-y-6 px-10 py-8 bg-gray-50">
    <div class="flex flex-col items-center space-y-2">
      <div class="flex space-x-4 justify-center items-center cursor-pointer" @click="$router.push('/login')">
        <svg-icon icon-class="PARQAY-logo" class="h-20 w-auto pr-6" />
      </div>
      <span class="font-semibold text-lg">For Organizations & Companies</span>
      <div class="py-4">
        <div class="flex flex-col space-y-4 items-center justify-center px-4 py-4 border rounded bg-white">
          <span class="text-base text-center font-normal text-gray-500">Enter Organization ID</span>
          <a-input placeholder="e.g. parqay" size="large" v-model="urlSlug">
            <a-button slot="suffix" type="primary" icon="right" rounded :disabled="!urlSlug"
              @click="$router.push(`/login/${urlSlug}`)"></a-button>
          </a-input>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .ant-input {
    @apply text-2xl pb-2.5 px-5 font-normal h-16;
  }
}
</style>
