<script>
import { onMounted, ref } from "@vue/composition-api";
export default {
  model: {
    prop: "activeTab",
    event: "change"
  },
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: null
    },
    padding: {
      type: Number,
      default: 15
    },
    showToggles: {
      type: Boolean,
      default: false
    },
    // If ON, tab header will be unaware of route hash change
    disableHashDetect: {
      type: Boolean,
      default: false
    },
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const tabHeaderScroll = ref(null);
    const rightActionsSlotRef = ref(null);
    const leftPadding = ref(props.padding);
    const scrollProcess = ref(0);
    const changeTab = name => {
      ctx.emit("change", name);
      if (props.showToggles && tabHeaderScroll.value) {
        tabHeaderScroll.value.scrollIntoView(
          `#${name}`,
          200
        )
      }
    };
    const rightPadding = () => {
      let padding = leftPadding.value;
      if (rightActionsSlotRef.value) {
        padding = rightActionsSlotRef.value.clientWidth + padding + leftPadding.value;
      }
      return `${padding}px`;
    };
    const handleScroll = (vertical, horizontal, nativeEvent) => {
      console.log("handleScroll")
      console.log(vertical, horizontal, nativeEvent)
      const { process } = horizontal;
      scrollProcess.value = process;
    }
    const onTabActionTrigger = async (tab) => {
      ctx.root.$set(tab, '$loading', true);
      if (tab.action instanceof Promise) {
        await tab.action();
        setTimeout(() => {
          ctx.root.$set(tab, '$loading', false);
        }, 800);
      } else {
        tab.action();
        setTimeout(() => {
          ctx.root.$set(tab, '$loading', false);
        }, 1500);
      }
    }
    onMounted(() => {
      if (props.disableHashDetect) {
        return
      }
      let hash = route.hash.replace("#", "");
      console.log("tab header: hash -> ", hash)
      if (!hash) {
        hash = props.tabs[0].name;
      }
      changeTab(hash);
    });
    return {
      tabHeaderScroll,
      rightActionsSlotRef,
      leftPadding,
      scrollProcess,
      handleScroll,
      changeTab,
      rightPadding,
      onTabActionTrigger
    };
  }
};
</script>
<template>
  <div class="flex items-center shadow-sm bg-white border rounded-lg relative"
    :style="{ paddingLeft: `${leftPadding}px`, paddingRight: rightPadding() }">
    <smooth-scroll ref="tabHeaderScroll" :ops="{
      vuescroll: {},
      scrollPanel: {},
      rail: {},
      bar: {
        size: '6px',
        background: 'rgba(88, 80, 236, 1)',
        onlyShowBarOnScroll: false,
        opacity: 0.6
      }
    }" @handle-scroll="handleScroll">
      <div class="flex items-center space-x-10 font-medium text-lg py-4 px-12">
        <template v-for="tab in tabs">
          <button v-if="activeTab === tab.name && tab.action" :key="tab.name" :id="tab.name"
            class="flex items-center space-x-3 pl-4 pr-2 focus:outline-none whitespace-nowrap bg-parqay-primary text-white rounded-full cursor-pointer"
            @click="changeTab(tab.name)">
            <span>{{ tab.label }}</span>
            <a-icon type="reload" :spin="tab.$loading" style="font-size: 16px;" @click.stop="onTabActionTrigger(tab)" />
          </button>
          <button v-else :key="tab.name" :id="tab.name" class="px-4 focus:outline-none whitespace-nowrap" :class="[
            activeTab === tab.name
              ? 'bg-parqay-primary text-white rounded-full cursor-pointer'
              : 'cursor-pointer hover:bg-gray-200 hover:rounded-full'
          ]" @click="changeTab(tab.name)">
            {{ tab.label }}
          </button>
        </template>
      </div>
    </smooth-scroll>
    <div class="absolute right-4 top-2 bottom-2">
      <div ref="rightActionsSlotRef" class="flex h-full items-center justify-center">
        <slot name="right-actions" />
      </div>
    </div>
    <div v-if="showToggles" class="absolute top-1/2 transform -translate-y-1/2" style="left: 15px;">
      <a-button type="link" :disabled="scrollProcess == 0" style="padding:0px;" @click.stop="
        $refs.tabHeaderScroll.scrollBy(
          {
            dx: -80
          },
          200,
          'easeInQuad'
        )
        ">
        <a-icon type="left-circle" class="hover:text-parqay-primary" style="font-size: 25px;" />
      </a-button>
    </div>
    <div v-if="showToggles" class="absolute top-1/2 transform -translate-y-1/2" style="right: 15px;">
      <a-button type="link" :disabled="scrollProcess == 1" style="padding:0px;" @click.stop="
        $refs.tabHeaderScroll.scrollBy(
          {
            dx: 80
          },
          200,
          'easeInQuad'
        )
        ">
        <a-icon type="right-circle" class="hover:text-parqay-primary" style="font-size: 25px;" />
      </a-button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
// Fix smooth scrollbar horizontal scroll
::v-deep .__panel {
  overflow-x: hidden !important;
}

::v-deep {
  .ant-btn-link {
    color: unset;

    &:hover {
      @apply text-parqay-primary;
    }

    &:disabled {
      @apply text-gray-400;
    }
  }
}
</style>
