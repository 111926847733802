<script>
import { nextTick, onMounted, ref } from "@vue/composition-api";
import Chart from "chart.js";
const Utils = require("./Utils");
export default {
  setup(props, ctx) {
    const canvas = ref(null);
    const chart = ref(null);
    const DATA_COUNT = 7;
    const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
    const labels = Utils.months({ count: 7 });
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Dataset 1",
          data: Utils.numbers(NUMBER_CFG),
          borderColor: Utils.CHART_COLORS.red,
          backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5)
        },
        {
          label: "Dataset 2",
          data: Utils.numbers(NUMBER_CFG),
          borderColor: Utils.CHART_COLORS.blue,
          backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5)
        }
      ]
    };
    onMounted(() => {
      if (canvas.value) {
        chart.value = new Chart(canvas.value.getContext("2d"), {
          type: "line",
          data: data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top"
              },
              title: {
                display: true,
                text: "Chart.js Line Chart"
              }
            }
          }
        });
      }
    });
    return {
      canvas
    };
  }
};
</script>
<template>
  <div class="flex flex-col space-y-2 justify-between py-4 px-4">
    <span class="text-base font-semibold text-center">
      API error distribution
    </span>
    <canvas ref="canvas" />
  </div>
</template>
