<script>
import { nextTick, onMounted, ref } from "@vue/composition-api";
import Chart from "chart.js";
const Utils = require("./Utils");
export default {
  setup(props, ctx) {
    const canvas = ref(null);
    const chart = ref(null);
    const DATA_COUNT = 5;
    const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

    const data = {
      labels: ["Red", "Orange", "Yellow", "Green", "Blue"],
      datasets: [
        {
          label: "Dataset 1",
          data: Utils.numbers(NUMBER_CFG),
          backgroundColor: Object.values(Utils.CHART_COLORS)
        }
      ]
    };
    onMounted(() => {
      if (canvas.value) {
        chart.value = new Chart(canvas.value.getContext("2d"), {
          type: "doughnut",
          data: data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top"
              },
              title: {
                display: true,
                text: "Chart.js Doughnut Chart"
              }
            }
          }
        });
      }
    });
    return {
      canvas
    };
  }
};
</script>
<template>
  <div class="flex flex-col space-y-2 justify-between py-4 px-4">
    <span class="text-base font-semibold text-center">API types</span>
    <canvas ref="canvas" />
  </div>
</template>
