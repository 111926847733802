<script>
import { onMounted, reactive } from "@vue/composition-api";

// Oauth Clients Import
import {
  initGoogleSdk,
  login as googleLogin,
  initOneTapLogin,
} from "src/services/authentication/google";
import {
  initAppleSignIn,
  login as appleLogin,
} from "src/services/authentication/apple";

export default {
  props: {
    loading: Object,
    tenant: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const handleLogin = async (provider) => {
      if (props.loading[provider]) {
        return;
      }
      props.loading.signin = true;
      switch (provider) {
        case "google":
          if (props.tenant) {
            googleLogin("tenant");
          } else {
            googleLogin();
          }

          break;
        case "apple":
          if (props.tenant) {
            appleLogin("tenant");
          } else {
            appleLogin();
          }
          break;
        default:
          break;
      }
      setTimeout(() => {
        props.loading.signin = false;
      }, 1500);
    };
    onMounted(() => {
      initOneTapLogin();
      initGoogleSdk()
        .then(() => {
          props.loading.google = false;
        })
        .catch((error) => {
          console.log("initGoogleSdk >> error", error);
          ctx.root.$rollbar.error(error);
        });
      initAppleSignIn()
        .then(() => {
          props.loading.apple = false;
          console.log("Apple SignIn scripts loaded");
        })
        .catch((error) => {
          console.log("Apple SignIn scripts launching error", error);
        });

      setTimeout(() => {
        props.loading.facebook = false;
        props.loading.google = false;
        props.loading.apple = false;
      }, 10000);
    });
    return {
      handleLogin,
    };
  },
};
</script>

<template>
  <div class="flex flex-col justify-center space-y-2 pt-6">
    <div v-if="!tenant" class="mb-6 relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center text-sm">
        <span class="px-2 bg-white text-gray-500">
          Social Login
        </span>
      </div>
    </div>
    <div class="mt-1 grid grid-cols-2 gap-3">
      <div>
        <div
          class="
                      w-full
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      bg-white
                      text-sm
                      font-medium
                      text-gray-500
                      hover:bg-gray-50
                      hover:text-parqay-primary
                      cursor-pointer
                    "
          @click="handleLogin('google')"
        >
          <span class="sr-only">Sign in with Google</span>
          <!-- <div id="pq-google-signin"></div> -->
          <svg-icon v-if="!loading.google" icon-class="google" class="w-5 h-5" />
          <svg-icon
            v-else
            icon-class="circular-loader"
            class="w-5 h-5 animate-spin"
          />
        </div>
      </div>

      <div>
        <div
          class="
                      w-full
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      bg-white
                      text-sm
                      font-medium
                      text-gray-500
                      hover:bg-gray-50
                      hover:text-parqay-primary
                      cursor-pointer
                    "
          @click="handleLogin('apple')"
        >
          <span class="sr-only">Sign in with Apple</span>
          <svg-icon v-if="!loading.apple" icon-class="apple" class="w-5 h-5" />
          <svg-icon
            v-else
            icon-class="circular-loader"
            class="w-5 h-5 animate-spin"
          />
        </div>
      </div>
    </div>
  </div>
</template>
