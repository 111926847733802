import request from "src/services/api/request";

export const API = {
  getAccountDetails() {
    return request.get("user/account", {});
  },
  updateAccountDetails(payload) {
    return request.put("user/account", payload);
  },
  createWhiteLabelDetails(payload) {
    return request.post("user/account/white-label", payload);
  },
  updateWhiteLabelDetails(payload) {
    return request.put("user/account/white-label", payload);
  },
  listAllPaymentMethods() {
    return request.get("payments/payment-methods", {});
  },
  createStripeConnectAccount(stripe_code) {
    return request.post("payments/create-stripe-connect-account", { stripe_code });
  },
  getStripeConnectAccountDashboardUrl() {
    return request.get("payments/stripe-connect-dashboard-url", {});
  },
  getListingSettings(role) {
    return request.get(`user/account/settings/${role}`);
  },
  updateHostListingAutofillSettings(payload) {
    return request.put("user/account/settings/host/listing/autofill", payload);
  }
};
