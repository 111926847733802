var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-2"},[(_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'image'); }).length > 0)?[_c('viewer',{attrs:{"images":_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'image'); })}},[_c('div',{staticClass:"grid grid-cols-4 gap-2 mb-3"},_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'image'); }
        )),function(f,i){return _c('div',{key:((f.url) + "-" + i)},[(!f.$error)?_c('img',{staticClass:"w-full object-cover bg-gray-50 hover:opacity-50 cursor-pointer border rounded overflow-hidden",staticStyle:{"height":"80px"},attrs:{"src":f.url,"alt":""},on:{"error":function (event) {
              _vm.$set(f, '$error', true);
            }}}):_c('a-tooltip',{attrs:{"title":"Image link might be broken or expired."}},[_c('div',{staticClass:"flex items-center justify-center bg-gray-200 cursor-pointer border border-gray-300 rounded",staticStyle:{"width":"80px","height":"80px"}},[_c('svg',{staticClass:"w-5 h-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z","clip-rule":"evenodd"}})])])])],1)}),0)])]:_vm._e(),(_vm.isSMMS)?_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'video'); }
    )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex flex-col space-y-2"},[_vm._m(0,true),_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.$downloadFileFromUrl(f)}}},[_vm._m(1,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n          "+_vm._s(((f.type) + "-attachment-" + (i + 1)))+"\n        ")])])])}):[(_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'video'); }).length > 0)?_c('div',{staticClass:"flex flex-col space-y-2 pb-3"},_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'video'); }
      )),function(f,i){return _c('div',{key:((f.url) + "-" + i)},[_c('video',{staticClass:"w-full object-cover hover:opacity-50 cursor-pointer border rounded overflow-hidden",attrs:{"controls":""}},[_c('source',{attrs:{"src":f.url,"type":f.mime_type}}),_vm._v("\n          Your browser does not support the video tag.\n        ")])])}),0):_vm._e()],_c('div',{staticClass:"flex flex-col space-y-2 pb-3"},[_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'audio'); }
    )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":f.url,"type":f.mime_type}}),_vm._v("\n        Your browser does not support the audio element.\n      ")])])}),_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'pdf'); }
    )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(2,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n          "+_vm._s(f.name || ((f.type) + "-attachment-" + (i + 1)))+"\n        ")])])])}),_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'word'); }
    )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(3,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n          "+_vm._s(f.name || ((f.type) + "-attachment-" + (i + 1)))+"\n        ")])])])}),_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'excel'); }
    )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(4,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n          "+_vm._s(f.name || ((f.type) + "-attachment-" + (i + 1)))+"\n        ")])])])}),_vm._l((_vm.media.filter(function (f) { return _vm.detectIsFileType(f, 'ppt'); }
    )),function(f,i){return _c('div',{key:((f.url) + "-" + i),staticClass:"flex relative"},[_c('a',{staticClass:"flex space-x-4 px-2 py-2 border rounded items-center hover:shadow-sm hover:bg-blue-100 w-full",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.previewMedia(f)}}},[_vm._m(5,true),_c('span',{staticClass:"font-semibold text-sm text-gray-600 pb-1 mr-10 overflow-ellipsis"},[_vm._v("\n          "+_vm._s(f.name || ((f.type) + "-attachment-" + (i + 1)))+"\n        ")])])])})],2)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs rounded-lg bg-gray-200 hover:bg-blue-200 text-gray-500 hover:text-blue-500 font-medium px-4 py-2 cursor-pointer"},[_c('span',[_vm._v("MMS videos may have poor quality due to carrier compression. Use direct messaging or email to send bigger\n          attachments.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-black"},[_vm._v("Video")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-pink-500"},[_vm._v("PDF")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-parqay-primary"},[_vm._v("Word")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-green-400"},[_vm._v("Excel")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center border rounded p-2 bg-gray-50"},[_c('span',{staticClass:"font-bold text-lg text-orange-400"},[_vm._v("PPT")])])}]

export { render, staticRenderFns }