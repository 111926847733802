<script>
import { ref, onMounted, reactive, watch } from "@vue/composition-api";
import TabHeader from "src/views/components/TabHeader.vue";

import APIRequestChart from "src/views/developers/charts/APIRequest";
import APITypeChart from "src/views/developers/charts/APIType";
import APIErrorChart from "src/views/developers/charts/APIError";
import WebhookRequestsChart from "src/views/developers/charts/WebhookRequest";
import { shortUID } from "../../plugins/prototype";

export default {
  name: "developers-portal",
  components: {
    TabHeader,
    "api-requests-chart": APIRequestChart,
    "api-types-chart": APITypeChart,
    "api-errors-chart": APIErrorChart,
    "webhook-requests-chart": WebhookRequestsChart,
  },
  setup(props, ctx) {
    const route = ctx.root.$route;
    const router = ctx.root.$router;
    const logs = ref({
      Yesterday: [
        {
          status: "200 OK",
          method: "PUT",
          endpoint: "/v1/reservations",
          created_at: "2021-11-13T15:00:00-07:00",
        },
        {
          status: "200 OK",
          method: "GET",
          endpoint: "/v1/reservations",
          created_at: "2021-11-13T13:00:00-07:00",
        },
        {
          status: "200 OK",
          method: "POST",
          endpoint: "/v1/reservations",
          created_at: "2021-11-13T09:00:00-07:00",
        },
      ],
      "NOV 10, 2021": [
        {
          status: "200 OK",
          method: "POST",
          endpoint: "/v1/timekit",
          created_at: "2021-11-12T15:00:00-07:00",
        },
        {
          status: "400 Error",
          method: "POST",
          endpoint: "/v1/timekit",
          created_at: "2021-11-12T13:00:00-07:00",
        },
      ],
    });

    const controls = reactive({
      testMode: false,
    });

    const table = reactive({
      headers: [
        {
          title: "NAME",
          align: "center",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "TOKEN",
          align: "center",
          dataIndex: "token",
          scopedSlots: { customRender: "token" },
        },
        {
          title: "LAST USED",
          align: "center",
          dataIndex: "last_used",
          scopedSlots: { customRender: "last_used" },
        },
        {
          title: "CREATED",
          align: "center",
          dataIndex: "created_at",
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "ACTIONS",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      tokens: [],
    });

    const tabs = ref([
      { name: "overview", label: "Overview" },
      { name: "apikeys", label: "API Keys" },
    ]);

    const activeTab = ref("overview");

    watch(
      () => controls.testMode,
      (val) => {
        getTokens();
      }
    );

    const getPublishableKey = () => {
      return `pq_${controls.testMode ? "pt_" : "pl_"}${shortUID()}`;
    };

    const getSecretKey = () => {
      return `pq_${controls.testMode ? "st_" : "sl_"}${shortUID()}`;
    };

    const getTokens = async () => {
      table.tokens = [
        {
          id: shortUID(),
          name: "Publishable key",
          token: getPublishableKey(),
          last_used: "2021-11-12T15:00:00-07:00",
          created_at: "2021-11-12T15:00:00-07:00",
        },
        {
          id: shortUID(),
          name: "Secret key",
          token: getSecretKey(),
          last_used: "2021-11-12T15:00:00-07:00",
          created_at: "2021-11-12T15:00:00-07:00",
        },
      ];
    };

    const setupHashRoute = (hashRoute) => {
      router.push({ name: route.name, hash: `#${hashRoute}` });
    };

    const changeTab = (name) => {
      activeTab.value = name;
      setupHashRoute(name);
    };

    onMounted(() => {
      getTokens();
    });
    return {
      logs,
      table,
      controls,
      tabs,
      activeTab,
      changeTab,
      window,
    };
  },
};
</script>

<template>
  <div class="absolute inset-0">
    <div class="relative">
      <div class="py-10 flex flex-col px-10 space-y-4">
        <tab-header v-model="activeTab" :tabs="tabs" @change="changeTab">
          <template #right-actions>
            <div class="flex items-center pr-4 space-x-2">
              <span class="text-sm font-medium" :class="controls.testMode ? 'text-yellow-300' : 'text-gray-600'">Test
                Mode</span>
              <a-switch size="small" v-model="controls.testMode" class="test-switch" />
            </div>
            <a-button @click="window.open('/docs', '_blank').focus()" icon="read">
              API Documentation
            </a-button>
          </template>
        </tab-header>
        <div v-if="activeTab === 'overview'" class="flex flex-col">
          <span class="text-xl text-black font-bold pb-2 border-b"
            :class="controls.testMode ? 'border-yellow-300' : ''">
            Your Integration
            <a-tag color="orange" class="ml-4">
              Available Soon
            </a-tag>
          </span>
          <div class="grid grid-cols-3 relative">
            <div v-if="controls.testMode"
              class="absolute text-xs text-white bg-yellow-300 px-2 transform left-1/2 -translate-x-1/2 top-0">
              TEST DATA
            </div>
            <div class="grid grid-cols-2 py-6 px-4 gap-4 col-span-2 border-r">
              <div class="h-64 border rounded-lg bg-white hover:border-none hover:shadow-lg cursor-pointer relative">
                <api-requests-chart class="absolute inset-0" />
              </div>
              <div class="h-64 border rounded-lg bg-white hover:border-none hover:shadow-lg cursor-pointer relative">
                <api-errors-chart class="absolute inset-0" />
              </div>
              <div class="h-64 border rounded-lg bg-white hover:border-none hover:shadow-lg cursor-pointer relative">
                <api-types-chart class="absolute inset-0" />
              </div>
              <div class="h-64 border rounded-lg bg-white hover:border-none hover:shadow-lg cursor-pointer relative">
                <webhook-requests-chart class="absolute inset-0" />
              </div>
            </div>
            <div class="flex flex-col border-r relative">
              <div class="border-b py-4 px-4 flex items-center justify-between">
                <span class="text-lg font-semibold">Logs</span>
                <div class="flex items-center space-x-2">
                  <a-checkbox>All</a-checkbox>
                  <a-checkbox>Succeeded</a-checkbox>
                  <a-checkbox>Failed</a-checkbox>
                </div>
              </div>
              <div v-for="(v, k, i) in logs" :key="`log-${i}`" class="flex flex-col justify-center items-center">
                <div class="flex justify-between items-center bg-white border-b w-full px-4 py-4 text-sm font-medium">
                  {{ k }}
                </div>
                <div v-for="(l, j) in v" :key="`log-${i}-${j}`"
                  class="flex justify-between items-center bg-white hover:bg-gray-50 border-b w-full px-4 py-2 text-xs cursor-pointer">
                  <div class="flex space-x-2 items-center">
                    <a-tag v-if="l.status === '200 OK'" color="geekblue">{{
                      l.status
                    }}</a-tag>
                    <a-tag v-if="l.status.includes('Error')" color="pink">{{
                      l.status
                    }}</a-tag>
                    <span class="font-bold text-black">{{ l.method }}</span>
                    <span class="font-medium text-parqay-primary">{{ l.endpoint }}</span>
                  </div>
                  <span class="font-medium text-gray-600">{{
                    $formatDate(l.created_at, "h:mm a")
                  }}</span>
                </div>
              </div>
              <div class="absolute inset-0 bg-gray-500 opacity-50">
                <div class="flex flex-col space-y-3 items-center justify-center h-full">
                  <span class="text-2xl font-bold text-white">Preview</span>
                  <span class="text-lg font-medium text-white border-t pt-3 border-white">Developer portal will launch
                    soon</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'apikeys'" class="flex flex-col">
          <div class="flex space-x-4 items-center pb-2 border-b" :class="controls.testMode ? 'border-yellow-300' : ''">
            <span class="text-xl text-black font-bold">
              API Keys
              <a-tag color="orange" class="ml-4">
                Available Soon
              </a-tag>
            </span>
          </div>
          <div class="flex flex-col relative">
            <div v-if="controls.testMode"
              class="absolute text-xs text-white bg-yellow-300 px-2 transform left-1/2 -translate-x-1/2 top-0">
              TEST DATA
            </div>
            <div class="mt-6 pt-2 pb-4 rounded-lg bg-white border">
              <div class="flex flex-col justify-center space-y-1 px-4 pb-2 border-b relative">
                <span class="text-lg font-bold">Standard keys</span>
                <span class="text-sm">
                  These keys will allow you to authenticate API requests.
                  <a class="text-parqay-primary hover:text-blue-800">Learn more</a>
                </span>
                <a-button icon="plus" class="absolute right-4 top-1/2 transform -translate-y-1/2">Create secret
                  key</a-button>
              </div>
              <a-table :loading="false" :columns="table.headers" :data-source="table.tokens" row-key="id"
                :pagination="false">
                <template slot="last_used" slot-scope="last_used">
                  <a-tag>{{
                    last_used? $formatDate(last_used, "MMM DD"): "N/A"
                  }}</a-tag>
                </template>
                <template slot="created_at" slot-scope="created_at">
                  <a-tag>{{
                    created_at? $formatDate(created_at, "MMM DD, YYYY"): "N/A"
                  }}</a-tag>
                </template>
                <template slot="actions" slot-scope="actions">
                  <div class="flex items-center justify-center">
                    <a-button type="danger" size="small">Revoke</a-button>
                  </div>
                </template>
              </a-table>
              <div class="absolute inset-0 bg-gray-500 opacity-50">
                <div class="flex flex-col space-y-3 items-center justify-center h-full">
                  <span class="text-2xl font-bold text-white">Preview</span>
                  <span class="text-lg font-medium text-white border-t pt-3 border-white">Developer portal will launch
                    soon</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  .test-switch.ant-switch-checked {
    @apply bg-yellow-300;
  }

  .ant-btn {
    @apply flex items-center;
  }

  .ant-btn-sm {
    @apply text-xs;
  }
}
</style>
