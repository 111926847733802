import loadScript from "simple-load-script";
import jwt_decode from "jwt-decode";
import { loginCallback } from "src/services/authentication/oauth";
// import { rollbar } from "src/plugins/rollbar";

const appleClientId = process.env.VUE_APP_APPLE_CLIENT_ID;

export const initAppleSignIn = () => {
  return new Promise((resolve, reject) => {
    window.onAppleScriptLoaded = () => {
      console.log("Start onloaded callback >> Apple");
      const redirectURI = location.href;
      window.AppleID.auth.init({
        clientId: appleClientId,
        scope: "name email",
        redirectURI,
        usePopup: true, //or false defaults to false
      });
      resolve();
    };

    loadScript({
      url:
        "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
      attrs: {},
    })
      .then((scriptRef) => {
        console.log("Apple SignIn script load ready", scriptRef);
        if (window.AppleID) {
          window.onAppleScriptLoaded();
        } else {
          reject("Cannot load Apple SignIn script");
        }
      })
      .catch((error) => {
        console.log("Apple SignIn script load error", error);
        reject(error);
      });
  });
};

export const login = async (source="dashboard") => {
  var payload, data;
  try {
    data = await window.AppleID.auth.signIn();
  } catch (error) {
    console.log("Apple Signin >> error", error);
    if (error.error !== "popup_closed_by_user") {
      // rollbar.error(error);
    }
    return 
  }
  console.log("Apple Signin >> success", data);
  const { id_token } = data.authorization;
  var decoded = jwt_decode(id_token);
  console.log("decoded id_token: ", decoded);
  var email, first_name, last_name, sub, user_name;
  // Stage 4(finished) proposal => MDN >> Destructuring_assignment
  ({ sub, email } = { ...decoded });
  payload = { sub, email };
  if (data.user) {
    ({ email, name: user_name } = { ...data.user });
    ({ firstName: first_name, lastName: last_name } = { ...user_name });
    console.log("email: ", email);
    console.log("first_name: ", first_name);
    console.log("last_name: ", last_name);
    payload = {
      first_name,
      last_name,
      ...payload,
    };
  }
  console.log("payload >>> ", payload);
  loginCallback("apple", payload, source);
};
