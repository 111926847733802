<script>
import { onMounted, ref } from "@vue/composition-api";
import { API } from "src/views/non-host/payments/api";

export default {
    setup(props, ctx) {
        const form = ref([])
        const fetchData = async () => {
            ctx.emit("loading-start");
            const { data } = await API.balanceSummary();
            form.value = data.transactions;
            ctx.emit("update-balance", data);
            ctx.emit("loading-stop");
        }
        onMounted(() => {
            fetchData();
        })
        return {
            form
        }
    }
}
</script>

<template>
    <div class="flex flex-col overflow-y-auto">
        <div v-for="(transaction, i) in form" :key="`pending-transactions-${i}`"
            class="border-b flex flex-col sm:flex-row sm:justify-between sm:align-start px-6 py-4 hover:bg-blue-100 cursor-pointer"
            @click="$router.push(`/tenant/reservations/${$deepGet(transaction, 'metadata.reservation_id')}#pay`)">

            <!-- Desktop / Tablet -->
            <div class="hidden sm:flex justify-between w-4/5 space-x-2">
                <div class="flex space-x-2">
                    <div>
                        <span class="text-sm block">{{ $deepGet(transaction, "metadata.title")
                            }}</span>
                        <span class="text-xs font-medium text-blue-500 block">{{ $deepGet(transaction,
            "metadata.description") }}<span
                                v-if="$deepGet(transaction, 'stripe_meta.subscription.default_payment_method.card')"> ,
                                <span class="capitalize">{{
            $deepGet(transaction,
                "stripe_meta.subscription.default_payment_method.card.brand") }}</span> ends in {{
            $deepGet(transaction,
                "stripe_meta.subscription.default_payment_method.card.last4") }}</span></span>
                    </div>
                    <div>
                        <a-tag v-if="$deepGet(transaction, 'stripe_meta.collection_method') === 'charge_automatically'"
                            color="green">
                            Autopay
                        </a-tag>
                        <a-tag v-else-if="$deepGet(transaction, 'stripe_meta.collection_method') === 'send_invoice'"
                            color="geekblue">
                            Invoice
                        </a-tag>
                        <a-tag v-else color="orange">
                            Manual
                        </a-tag>
                    </div>
                </div>
                <div>
                    <span class="text-sm text-pink-500 font-medium">{{ $formatCurrency(transaction.pending_amount)
                        }}</span>
                </div>
            </div>
            <div v-if="$deepGet(transaction, 'metadata.reservation_id')"
                class="hidden sm:flex flex-col items-end w-1/5 space-y-1.5">
                <a-button type="primary" size="small" class="text-xs"
                    @click.stop="$router.push(`/tenant/reservations/${$deepGet(transaction, 'metadata.reservation_id')}#pay`)">
                    Pay</a-button>
                <a-button v-if="$route.name === 'tenant-home'" size="small" class="text-xs"
                    @click.stop="$router.push('/tenant/payments#pay-now')">
                    View</a-button>
                <a-button v-else size="small" class="text-xs"
                    @click.stop="$router.push(`/tenant/reservations/${$deepGet(transaction, 'metadata.reservation_id')}`)">
                    View</a-button>
            </div>
            <div v-if="$deepGet(transaction, 'stripe_meta.collection_method') === 'charge_automatically'"
                class="hidden sm:flex flex-col items-end w-1/5 space-y-1.5">
                <!-- <a-button type="" size="small" class="text-xs" @click.stop="() => {}">
                    Pay
                </a-button> -->
                <a-button v-if="$deepGet(transaction, 'stripe_meta.subscription.metadata.reservation_id')" type=""
                    size="small" class="text-xs" @click.stop="() => { }"
                    @click="$router.push('/tenant/reservations/' + $deepGet(transaction, 'stripe_meta.subscription.metadata.reservation_id'))">
                    View
                </a-button>
            </div>

            <!-- Mobile -->
            <div class="flex sm:hidden flex-col space-y-2">
                <div class="flex items-start justify-between space-x-2">
                    <div>
                        <span class="text-sm block">{{ $deepGet(transaction, "metadata.title")
                            }}</span>
                        <span class="text-xs font-medium text-blue-500 block">{{ $deepGet(transaction,
            "metadata.description") }}<span
                                v-if="$deepGet(transaction, 'stripe_meta.subscription.default_payment_method.card')"> ,
                                <span class="capitalize">{{
            $deepGet(transaction,
                "stripe_meta.subscription.default_payment_method.card.brand") }}</span> ends in {{
            $deepGet(transaction,
                "stripe_meta.subscription.default_payment_method.card.last4") }}</span></span>
                    </div>
                    <span class="text-sm text-pink-500 font-medium">{{ $formatCurrency(transaction.pending_amount)
                        }}</span>
                </div>
                <div class="flex items-end justify-between space-x-2">
                    <a-tag v-if="$deepGet(transaction, 'stripe_meta.collection_method') === 'charge_automatically'"
                        color="green">
                        Autopay
                    </a-tag>
                    <a-tag v-else-if="$deepGet(transaction, 'stripe_meta.collection_method') === 'send_invoice'"
                        color="geekblue">
                        Invoice
                    </a-tag>
                    <a-tag v-else color="orange">
                        Manual
                    </a-tag>
                    <a-button type="primary" @click.stop="$router.push(`/tenant/reservations/${$deepGet(transaction, 'metadata.reservation_id')}#pay`)">
                    Pay</a-button>
                </div>
            </div>
        </div>
    </div>
</template>