<script>
import { reactive, ref, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";
import { API } from "../api";
import startCase from "lodash/startCase";

export default {
  name: "reservations-list",
  setup(props, ctx) {
    const controls = reactive({
      loading: false,
      drawers: {
        reservation: {
          visible: false,
          form: null,
        },
      },
      tab: "all"
    });

    const reservations = ref([]);

    watch(() => controls.tab, tab => {
      getReservations();
    })

    const getReservations = async () => {
      controls.loading = true;
      try {
        const { data } = await API.list({
          tab: controls.tab
        });
        reservations.value = data;
      } catch (error) {
        console.log("getReservations > error: ", error);
      }
      controls.loading = false;
    };

    const viewListingAddress = async (reservation) => {
      ctx.root.$set(reservation, "$loading", true);
      try {
        const { data } = await API.details(reservation.id);
        const listing = data.listing;
        const address = ctx.root.$deepGet(listing, "summary.location.address");
        ctx.root.$set(reservation, "listing.$address", address);
        console.log("viewListingAddress > address: ", address);
      } catch (error) {
        console.log("viewListingAddress > error: ", error);
      } finally {
        ctx.root.$set(reservation, "$loading", false);
      }
    }

    onMounted(() => {
      getReservations();
    });

    onBeforeUnmount(() => {
    })

    return {
      controls,
      reservations,
      getReservations,
      viewListingAddress,
      startCase,
      window,
      console
    };
  },
};
</script>
<template>
  <div class="tenant-reservations absolute inset-0 bg-gray-200">
    <!-- Note: h-full is important to activate flex grow! -->
    <div class="flex flex-col h-full">
      <div class="py-3 flex-shrink-0 flex flex-col space-y-4 bg-white border-b">
        <div class="relative flex justify-center items-center px-10 pb-3 border-b">
          <div class="flex items-center space-x-2 text-black font-medium text-lg">
            <a-icon type="calendar" theme="twoTone" />
            <span>Reservations</span>
          </div>
          <a-button type="link" :icon="controls.loading ? 'loading' : 'reload'" class="absolute inset-y-0 pb-1"
            :style="{ position: 'absolute', right: '16px' }" @click="getReservations()"></a-button>
        </div>
        <div class="flex justify-center text-base text-gray-600 font-normal space-x-6">
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'all' }"
            @click="() => (controls.tab = 'all')">
            All
          </a>
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'upcoming' }"
            @click="() => (controls.tab = 'upcoming')">
            Upcoming
          </a>
          <a class=" border-parqay-primary hover:text-gray-700"
            :class="{ 'text-black font-medium pb-1 border-b-4': controls.tab == 'past' }"
            @click="() => (controls.tab = 'past')">
            Past
          </a>
        </div>
      </div>
      <div class="flex-grow overflow-y-auto w-screen mx-auto">
        <div v-if="controls.loading" class="flex items-center justify-center"
          :style="{ height: `${window.innerHeight / 2}px` }">
          <a-button size="large" loading></a-button>
        </div>
        <template v-else>
          <div v-if="reservations.length == 0" class="flex flex-col items-center justify-center"
            :style="{ height: `${window.innerHeight / 2}px` }">
            <span class="text-lg text-gray-500">No reservation</span>
          </div>
          <div v-else class="px-6 pt-5 space-y-5">
            <template v-for="(reservation, i) in reservations">
              <div :key="`reservation-item-${i}`" class="shadow-md overflow-hidden rounded-xl bg-white">
                <div class="flex items-center justify-between py-2 border-b">
                  <span class="text-base text-black font-medium pl-6 py-2">{{ reservation.title }}</span>
                  <span v-if="reservation.interval_mode === 'monthly'"
                    class="text-base text-parqay-primary font-medium pr-6 py-2">{{
            $formatCurrency(reservation.tenant_total
              / reservation.interval_count)
          }}/month</span>
                  <span v-else class="text-base text-parqay-primary font-medium pr-6 py-2">{{
              $formatCurrency(reservation.tenant_total)
            }}</span>
                </div>
                <div class="flex flex-col items-center justify-center space-y-2">
                  <div class="flex items-center space-x-3 pt-3 pb-2">
                    <a-icon type="user" />
                    <span>Hosted by</span>
                    <span class="text-black font-medium">{{ reservation.host_name }}</span>
                  </div>
                  <div class="flex items-center space-x-2 pb-3">
                    <a-tag color="geekblue">
                      {{ startCase($deepGet(reservation, "listing.type", "")) }}
                    </a-tag>
                    <a-tag v-if="reservation.status === 'complete'" color="green">
                      Approved by host
                    </a-tag>
                    <a-tag v-if="reservation.status === 'pending'" color="orange">
                      Pending
                    </a-tag>
                    <a-tag v-if="reservation.status === 'canceled'" color="pink">
                      Canceled
                    </a-tag>
                    <a-tag v-if="reservation.status === 'request'" color="cyan">
                      Request sent
                    </a-tag>
                    <a-tag color="geekblue">
                      {{ startCase(reservation.source || "") }}
                    </a-tag>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex flex-col space-y-2 w-full px-5 pt-2 pb-3">
                    <div v-for="(duration, j) in reservation.durations" :key="`reservation-${i}-duration-${j}`"
                      class="flex space-x-2 items-center justify-between border rounded px-4 py-1">
                      <div class="text-center text-xs font-medium whitespace-nowrap">
                        {{ $parseBackendDate(duration.from, "MMM DD, h:mm a") }}
                      </div>
                      <a-icon type="arrow-right" />
                      <div class="text-center text-xs font-medium whitespace-nowrap">
                        {{ $parseBackendDate(duration.to, "MMM DD, h:mm a") }}
                      </div>
                    </div>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex flex-col items-center space-y-2 w-full pt-1 pb-1">
                    <div class="flex justify-between w-full px-5">
                      <div class="flex space-x-2 items-center w-full">
                        <span>Listing</span>
                        <a-tag color="geekblue" class="truncate" style="max-width: 80%;">{{ $deepGet(reservation,
            "listing.name", "N/A")
                          }}</a-tag>
                      </div>
                      <a-button v-if="!$deepGet(reservation, 'listing.$address')"
                        :loading="$deepGet(reservation, '$loading', false)" size="small" style="font-size:12px;"
                        @click="viewListingAddress(reservation)">View
                        Address</a-button>
                    </div>
                    <template v-if="$deepGet(reservation, 'listing.$address')">
                      <a-divider>Address</a-divider>
                      <div class="rounded bg-white px-4 pb-1">
                        <p class="text-sm text-gray-700 font-medium">{{ $deepGet(reservation, "listing.$address") }}</p>
                      </div>
                    </template>
                  </div>
                  <a-divider v-if="reservation.event"></a-divider>
                  <div v-if="reservation.event" class="flex flex-col items-center space-y-2 w-full pt-1 pb-1">
                    <div class="flex justify-between w-full px-5">
                      <div class="flex space-x-4 items-center w-full">
                        <span>Event</span>
                        <span class="text-xs ant-tag-geekblue border rounded-md px-3 py-1">{{
            reservation.event
          }}</span>
                      </div>
                    </div>
                  </div>
                  <a-divider></a-divider>
                  <div class="flex space-x-3 w-full px-5 pt-1 pb-4">
                    <a-button type="primary" size="large" block
                      @click="$router.push(`/tenant/reservations/${reservation.id}`)">More
                      Details</a-button>
                  </div>
                </div>
              </div>
              <a-divider>Reservation made on: {{ $parseBackendDate(reservation.created_at, "MMM DD, h:mm a") }}
              </a-divider>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!$isLoggedIn()" class="bg-white border-t fixed left-0 bottom-0 right-0 px-4 pt-3 pb-5">
      <a-button type="primary" size="large" block @click="$router.push(`/login/tenant?next=${$route.fullPath}`)">Login
        to
        View More</a-button>
    </div>
  </div>
  <!-- <reservation-drawer-tenant :visible="controls.drawers.reservation.visible" :form="controls.drawers.reservation.form"
      @close="() => (controls.drawers.reservation.visible = false)" /> -->
</template>

<style lang="scss" scoped>
.tenant-reservations::v-deep {
  .ant-btn {
    @apply justify-center;
  }

  .ant-table-row {
    @apply cursor-pointer;
  }

  td {
    @apply py-2;
  }

  .ant-divider-inner-text {
    @apply text-xs text-gray-600 font-light;
  }
}
</style>
