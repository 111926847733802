<script>
import { reactive, onMounted } from "@vue/composition-api";
import { API } from "src/views/settings/api";

export default {
    setup(props, ctx) {
        const controls = reactive({
            loading: true,
            error: null
        })
        const connectStripeCode = async () => {
            try {
                const stripe_code = ctx.root.$route.query.code;
                if (stripe_code) {
                    await API.createStripeConnectAccount(stripe_code);
                } else {
                    controls.error = "No Stripe code found"
                }
            } catch (error) {
                controls.error = error.message;
            } finally {
                setTimeout(() => {
                    controls.loading = false;
                }, 1500);
            }
        }
        onMounted(() => {
            connectStripeCode();
        })
        return {
            controls
        }
    }
}
</script>
<template>
    <div class="h-screen w-screen flex items-center justify-center">
        <a-result v-show="!controls.error" title="All set, you are now ready to receive your payouts!">
            <template #icon>
                <a-icon type="smile" theme="twoTone" />
            </template>
            <template #extra>
                <a-icon v-if="controls.loading" type="loading" style="font-size: 30px;" />
                <a-button v-else type="primary" :loading="controls.loading" size="large"
                    @click="() => {
                        $router.replace({ hash: '#stripe-code-connected' });
                        controls.loading = true;
                    }">
                    Back to Dashboard
                </a-button>
            </template>
        </a-result>
        <a-result v-show="controls.error" status="error" title="Could not connect with Stripe" :sub-title="controls.error" />
    </div>
</template>