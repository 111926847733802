<script>
import { onMounted, reactive, ref } from "@vue/composition-api";
import { API } from "../api";

export default {
    setup(props, ctx) {
        const controls = reactive({
            loading: false
        });
        const table = reactive({
            headers: [
                {
                    title: "Date",
                    align: "center",
                    // width: 150,
                    dataIndex: "created",
                    sorter: (a, b) => {
                        const result = a.dt.localeCompare(b.dt);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "date" },
                },
                {
                    title: "Description",
                    width: 300,
                    align: "center",
                    dataIndex: "description",
                    sorter: (a, b) => {
                        const result = a.reservation.title.localeCompare(b.reservation.title);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "description" },
                },
                {
                    title: "Status",
                    align: "center",
                    dataIndex: "status",
                    sorter: (a, b) => {
                        const result = a.status.localeCompare(b.status);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "status" },
                },
                {
                    title: "Type",
                    align: "center",
                    dataIndex: "type",
                    sorter: (a, b) => {
                        const result = a.type.localeCompare(b.type);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "type" },
                },
                {
                    title: "Amount",
                    align: "center",
                    dataIndex: "final_amount",
                    sorter: (a, b) => {
                        const result = a.final_amount.localeCompare(b.final_amount);
                        console.log("sorting...", result);
                        return result;
                    },
                    sortDirections: ["descend", "ascend"],
                    scopedSlots: { customRender: "amount" },
                },
                {
                    title: "Source",
                    align: "center",
                    dataIndex: "payment_method_details",
                    scopedSlots: { customRender: "payment-method" },
                },
                {
                    title: "Actions",
                    align: "center",
                    scopedSlots: { customRender: "actions" },
                }
            ],
            data: [],
        });
        const fetchData = async () => {
            controls.loading = true;
            ctx.emit("loading-start");
            const { data } = await API.listTransactions({ role: "tenant" });
            table.data = data;
            controls.loading = false;
            ctx.emit("loading-stop");
        };
        const popupWindow = (url, win = window, w = 800, h = 600) => {
            const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
            const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
            return win.open(url, "Parqay Tenant Invoice", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
        };
        const openReceiptLink = async (link) => {
            popupWindow(link).focus();
        };
        const getReservation = async (payload) => {
            ctx.root.$set(payload, "$loading.reservation", true);
            const { data } = await API.getReservation({ source: payload.source.id });
            console.log("getReservation: ", data);
            const { reservation_id } = data;
            ctx.root.$set(payload, "$loading.reservation", false);
            if (reservation_id) {
                ctx.root.$router.push("/tenant/reservations/" + reservation_id);
            }
            else {
                ctx.root.$openNotification("Missing Reservation", "Cannot find associated reservation of this transaction, please contact our customer service if you have questions", 10, "info");
            }
        };
        const initRefund = async (payload) => {
            ctx.root.$set(payload, "$loading.refund", true);
            const { data } = await API.initRefund({ source: payload.source.id });
            console.log("initRefund: ", data);
            const { status } = data;
            ctx.root.$set(payload, "$loading.refund", false);
            if (status === "succeeded") {
                ctx.root.$openNotification("Refund Initiated", "Please note refund normally hit tenant account in a few business days", 10, "success");
                await fetchData();
            }
            else {
                ctx.root.$openNotification("Refund Error", "Cannot refund this transaction, if you have further question, please contact our customer support", 10, "info");
            }
        };
        const getTagColor = (status) => {
            let color;
            switch (status) {
                case "available":
                    color = "geekblue";
                    break;
                case "paid":
                    color = "green";
                    break;
                case "pending":
                    color = "orange";
                    break;
                default:
                    color = "geekblue";
                    break;
            }
            return color;
        };
        const getNestedTableItems = (record) => {
            const refunded = record.source.refunded;
            if (refunded === true && !["adjustment", "payment_refund"].includes(record.type)) {
                const nestedItems = table.data.filter(v => (v.type === "adjustment" && record.source.id === v.source.id) || (v.type === "payment_refund" && record.source.id === v.source.charge));
                console.log("getNestedTableItems -> ", nestedItems);
                ctx.root.$set(record, "expand", nestedItems);
            }
        };
        onMounted(() => {
            fetchData();
        });
        return {
            controls,
            table,
            openReceiptLink,
            getReservation,
            initRefund,
            getTagColor,
            getNestedTableItems
        };
    }
}
</script>
<template>
    <a-table v-if="$isDesktopView()" :loading="controls.loading" :columns="table.headers" :expand-icon-as-cell="false"
        :expand-icon-column-index="-1" expand-row-by-click :data-source="table.data" row-key="id"
        :pagination="{ defaultPageSize: 10 }" :rowClassName="() => 'outer-table'" @expand="(expanded, record) => {
        $set(record, '$expanded', expanded);
        if (expanded && !record.expand) {
            getNestedTableItems(record)
        }
    }
        ">
        <template slot="date" slot-scope="date, record">
            <div class="flex items-center space-x-3">
                <button v-if="$deepGet(record, 'source.refunded') === true"
                    class="w-7 h-7 border border-blue-500 hover:bg-gray-100 flex items-center justify-center rounded-full">
                    <svg-icon icon-class="chevron-down" class="h-5 w-5 mt-0.5 text-blue-500 transform -rotate-90"
                        :class="{ 'rotate-0': record.$expanded }" />
                </button>
                <div v-else class="w-7 h-7"></div>
                <span class="">
                    {{
        $formatDate(date * 1000, "MMM Do YYYY").replace(new Date().getFullYear(), "")
    }}
                </span>
            </div>
        </template>
        <template slot="description" slot-scope="description, record">
            <template v-if="$deepGet(record, 'metadata.title') || $deepGet(record, 'metadata.description')">
                <span class="text-blue-500 font-medium block cursor-pointer py-0.5">
                    {{
        $deepGet(record, 'metadata.title')
    }}
                </span>
                <span class="text-black font-medium block py-0.5">
                    {{
            $deepGet(record, 'metadata.description')
        }}
                </span>
                <div v-if="record.type.includes('subscription')"
                    class="flex items-center justify-center space-x-1 font-medium py-0.5">
                    <span>Installments:</span>
                    <a-tag color="green">Paid: {{ record.metadata.installments_paid }}</a-tag>
                    <span>/</span>
                    <a-tag color="orange">Left: {{ record.metadata.total_installments_purchased }}</a-tag>
                </div>
            </template>
            <template v-else-if="description">
                <span v-for="(desc, i) in description.split('\n')" :key="`${desc}-${i}`"
                    class="text-black font-medium block">
                    {{
        desc
    }}
                </span>
            </template>
        </template>
        <template slot="status" slot-scope="status, record">
            <div class="flex flex-col space-y-2 justify-center">
                <div>
                    <a-tag :color="getTagColor(status)" class="capitalize">
                        {{
        status
    }}
                    </a-tag>
                </div>
                <div class="flex justify-center items-center">
                    <a-tag v-if="$deepGet(record, 'source.refunded') === true" color="green"
                        class="flex items-center space-x-1 px-1.5">
                        <span>Refunded</span>
                        <svg-icon icon-class="check" class="h-4 w-4 text-green-500" />
                    </a-tag>
                </div>
            </div>
        </template>
        <template slot="type" slot-scope="type, record">
            <div class="flex flex-col space-y-1.5 justify-center">
                <div v-for="(t, i) in type" :key="`${record.id}-type-${i}`">
                    <a-tag color="geekblue" class="capitalize">
                        {{
        t
    }}
                    </a-tag>
                </div>
            </div>

        </template>
        <template slot="amount" slot-scope="amount, record">
            <div v-if="amount" class="flex flex-col space-y-1.5 justify-center">
                <template v-if="record.type.includes('refunded')">
                    <span class="font-medium text-gray-500">
                        {{
        $formatCurrency(Math.abs(amount))
    }}
                    </span>
                </template>
                <template v-else>
                    <span class="font-medium" :class="amount > 0 ? 'text-green-400' : 'text-pink-500'">
                        {{ amount > 0 ? "-" : "+" }}{{
        $formatCurrency(Math.abs(amount))
    }}
                    </span>
                    <span class="font-medium" :class="amount > 0 ? 'text-pink-500' : 'text-green-400'">
                        {{ amount > 0 ? "+" : "-" }}{{
        $formatCurrency(Math.abs(amount))
    }}
                    </span>
                </template>
            </div>
            <span v-else-if="record.pending_amount" class="font-medium text-pink-500">
                +{{
        $formatCurrency(Math.abs(record.pending_amount))
    }}
            </span>
            <span v-else-if="record.adjustment_amount" class="font-medium"
                :class="record.adjustment_amount > 0 ? 'text-pink-500' : 'text-green-400'">
                {{ record.adjustment_amount > 0 ? "+" : "-" }}{{
        $formatCurrency(Math.abs(record.adjustment_amount))
    }}
            </span>
        </template>
        <template slot="payment-method" slot-scope="paymentMethod, record">
            <div v-if="$deepGet(paymentMethod, 'card.last4')" class="flex flex-col space-y-1">
                <a-tag color="geekblue" class="capitalize">
                    {{ paymentMethod.card.brand }}
                </a-tag>
                <a-tag color="geekblue">
                    {{ paymentMethod.card.last4 }}
                </a-tag>
            </div>
            <div v-else></div>
        </template>
        <span slot="actions" slot-scope="_, record">
            <div class="flex flex-col items-center justify-center space-y-1.5">
                <a-button v-if="$deepGet(record, 'invoice.subscription')" type="primary" size="small"
                    :loading="$deepGet(record, '$loading.invoice', false)"
                    @click="openReceiptLink($deepGet(record, 'invoice.hosted_invoice_url'))">
                    Invoice
                </a-button>
                <a-button v-else-if="record.receipt_url" type="primary" size="small"
                    :loading="$deepGet(record, '$loading.invoice', false)" @click="openReceiptLink(record.receipt_url)">
                    Receipt
                </a-button>
                <a-button v-if="$deepGet(record, 'metadata.reservation_id')" size="small"
                    @click="$router.push('/tenant/reservations/' + $deepGet(record, 'metadata.reservation_id'))">
                    Reservation
                </a-button>
            </div>
        </span>
    </a-table>
    <div v-else class="flex flex-col overflow-y-auto bg-white">
        <div v-for="(payment, i) in table.data" :key="`past-payment-${i}`"
            class="border-b flex flex-col sm:flex-row sm:justify-between sm:align-start px-6 py-4 hover:bg-blue-100 cursor-pointer">

            <!-- Mobile -->
            <div class="flex sm:hidden flex-col space-y-2">
                <div class="flex items-start justify-between space-x-2">
                    <div>
                        <template
                            v-if="$deepGet(payment, 'metadata.title') || $deepGet(payment, 'metadata.description')">
                            <span v-if="$deepGet(payment, 'metadata.reservation_id')"
                                class="text-blue-500 font-medium block cursor-pointer py-0.5"
                                @click="$router.push('/tenant/reservations/' + $deepGet(payment, 'metadata.reservation_id'))">
                                {{
        $deepGet(payment, 'metadata.title')
    }}
                            </span>
                            <span class="text-black font-medium block py-0.5">
                                {{
            $deepGet(payment, 'metadata.description')
        }}
                            </span>
                            <div v-if="payment.type.includes('subscription')"
                                class="flex items-center justify-center space-x-1 font-medium py-0.5">
                                <span>Installments:</span>
                                <a-tag color="green">Paid: {{ payment.metadata.installments_paid }}</a-tag>
                                <span>/</span>
                                <a-tag color="orange">Left: {{ payment.metadata.total_installments_purchased }}</a-tag>
                            </div>
                        </template>
                        <template v-else-if="payment.description">
                            <span v-for="(desc, i) in payment.description.split('\n')" :key="`${desc}-${i}`"
                                class="text-black font-medium block">
                                {{
        desc
    }}
                            </span>
                        </template>
                    </div>
                    <div v-if="payment.final_amount" class="flex flex-col space-y-1.5 justify-center">
                        <template v-if="payment.type.includes('refunded')">
                            <span class="font-medium text-gray-500">
                                {{
        $formatCurrency(Math.abs(payment.final_amount))
    }}
                            </span>
                        </template>
                        <template v-else>
                            <span class="font-medium"
                                :class="payment.final_amount > 0 ? 'text-green-400' : 'text-pink-500'">
                                {{ payment.final_amount > 0 ? "-" : "+" }}{{
        $formatCurrency(Math.abs(payment.final_amount))
    }}
                            </span>
                            <span class="font-medium"
                                :class="payment.final_amount > 0 ? 'text-pink-500' : 'text-green-400'">
                                {{ payment.final_amount > 0 ? "+" : "-" }}{{
        $formatCurrency(Math.abs(payment.final_amount))
    }}
                            </span>
                        </template>
                        <a-button v-if="$deepGet(payment, 'invoice.subscription')" type="primary" size="small"
                            :loading="$deepGet(payment, '$loading.invoice', false)"
                            @click="openReceiptLink($deepGet(payment, 'invoice.hosted_invoice_url'))">
                            Invoice
                        </a-button>
                        <a-button v-else-if="payment.receipt_url" type="primary" size="small"
                            :loading="$deepGet(payment, '$loading.invoice', false)"
                            @click="openReceiptLink(payment.receipt_url)">
                            Receipt
                        </a-button>
                    </div>
                    <span v-else-if="payment.pending_amount" class="font-medium text-pink-500">
                        +{{
                        $formatCurrency(Math.abs(payment.pending_amount))
                        }}
                    </span>
                    <span v-else-if="payment.adjustment_amount" class="font-medium"
                        :class="payment.adjustment_amount > 0 ? 'text-pink-500' : 'text-green-400'">
                        {{ payment.adjustment_amount > 0 ? "+" : "-" }}{{
                        $formatCurrency(Math.abs(payment.adjustment_amount))
                        }}
                    </span>
                </div>
                <div class="flex items-end justify-between space-x-2">
                    <span class="text-gray-500 text-xs italic">{{
                        $formatDate(payment.created * 1000, "MMM Do YYYY").replace(new Date().getFullYear(), "")
                        }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
::v-deep {

    .ant-table-tbody {
        @apply bg-white;
    }

    .ant-btn {
        @apply text-xs;
    }

    .ant-table-expanded-row {
        td:not(.ant-table-column-has-actions) {
            @apply p-0 border-0;
        }
    }
}
</style>