<script>
import { onMounted, ref } from "@vue/composition-api";
export default {
  name: "towing-org-login",
  setup(props, ctx) {
    const companyId = ref(null);
    onMounted(() => { });
    return {
      companyId
    };
  }
};
</script>
<template>
  <div class="w-screen h-screen flex flex-col items-center space-y-6 px-10 py-24 bg-gray-50"
    :class="$isDesktopView() ? 'justify-center' : 'justify-start'">
    <div v-if="$isDesktopView()" class="flex flex-col items-center space-y-2">
      <div class="flex space-x-4 justify-center items-center cursor-pointer" @click="$router.push('/login')">
        <svg-icon icon-class="PARQAY-logo" class="h-20 w-auto" />
      </div>
      <span class="font-semibold text-lg">For Towing Companies</span>
      <div class="py-4">
        <div class="flex space-x-4 items-center px-4 py-4 border rounded bg-white">
          <span class="text-base font-semibold text-parqay-primary">Enter Company Identifier:</span>
          <a-input placeholder="e.g. JQL85S" size="large" class="py-4" style="width:120px;" :value="companyId" />
          <a-button class="" size="large" type="primary" @click="$router.push(`/towing/${companyId}`)">
            View Requests
          </a-button>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center space-y-8 w-full">
      <span class="text-2xl font-bold text-black">For Towing Companies</span>
      <div class="flex flex-col space-y-4 justify-center px-8 py-8 border rounded-lg bg-white w-full">
        <span class="text-base font-semibold text-parqay-primary">Enter Company Identifier:</span>
        <a-input placeholder="e.g. JQL85S" size="large" :value="companyId" />
        <a-button class="" size="large" type="primary" @click="$router.push(`/towing/${companyId}`)">
          View Requests
        </a-button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {}
</style>
